import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
export type DeleteFormProps = {
  show: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
  type: string;
};

function DeleteForm(props: DeleteFormProps) {
  return (
    <Dialog open={props.show} onClose={props.handleClose} maxWidth="sm" fullWidth>
      <DialogTitle id="alert-dialog-title">Delete {props.type}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">Are you sure you want to delete this {props.type}?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            props.handleClose();
          }}
          style={{ marginTop: "15px" }}
        >
          Cancel
        </Button>
        <Button
          color="error"
          variant="outlined"
          onClick={() => {
            props.handleSubmit();
          }}
          style={{ marginTop: "15px" }}
        >
          YES
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteForm;
