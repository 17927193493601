import { API } from "aws-amplify";
import { v4 as uuid } from "uuid";
import { Api, loginJwtHolder } from "../App";
import { APIMock } from "./awsCallsToLocalMock";
import {
  DbJsonNode,
  DeleteFileRequest,
  DeleteFileResponse,
  DeleteNodeRequest,
  DeleteNodeResponse,
  FilePattern,
  FileTypes,
  GetChildrenRequest,
  GetChildrenResponse,
  GetFileAccessTokensRequest,
  GetFileAccessTokensResponse,
  GetFilePermissionEntry,
  GetFilePermissionsRequest,
  GetFilePermissionsResponse,
  GetFileRequest,
  GetFileResponse,
  GetFileVersionSuggestionRequest,
  GetFileVersionSuggestionResponse,
  GetFullJsonRequest,
  GetFullJsonResponse,
  GetNodesRequest,
  GetNodesResponse,
  GetSharedFilesRequest,
  GetSharedFilesResponse,
  GetUserRequest,
  GetUserResponse,
  PostDuplicateNodeRequest,
  PostDuplicateNodeResponse,
  PostFileRequest,
  PostFileResponse,
  PostFileVersionRequest,
  PostFileVersionResponse,
  PostGenerateFileAccessTokenRequest,
  PostGenerateFileAccessTokenResponse,
  PostNodeRequest,
  PostNodeResponse,
  PostUploadFileRequest,
  PostUploadFileResponse,
  PutFilePermissionEntry,
  PutFilePermissionsRequest,
  PutFilePermissionsResponse,
  PutNodesRequest,
  PutNodesResponse,
  PutRenameFileRequest,
  PutRenameFileResponse,
  QueryNodeChildByNameRequest,
  QueryNodeChildByNameResponse,
  SuggestedVersion,
  VersionChangeType,
} from "common";
import { AxiosHttpClient } from "./axiosHttpClient";

export const TEMPORARY_NODE_PREFIX = "tmp_";
const localServer = "http://localhost:5000";
const prodServer = "https://b1q345wbh0.execute-api.eu-west-1.amazonaws.com/prod";

export class FilesApiClient {
  private httpClient: AxiosHttpClient;

  constructor(private isInit: boolean, private isLocalMock: boolean) {
    this.httpClient = new AxiosHttpClient(isLocalMock ? localServer : prodServer);
  }

  setIsInit(isInit = true) {
    this.isInit = isInit;
  }

  get mock() {
    return this.isLocalMock;
  }

  get api() {
    return this.httpClient;
  }

  async loadUserInfo(): Promise<GetUserResponse | undefined> {
    console.info("FilesApiClient loadUserInfo", this.api);
    if (!this.api) {
      return Promise.resolve(undefined);
    }
    const authorization = await loginJwtHolder.getJWT();
    const body: GetUserRequest = {};
    return this.api
      .get("/files/user/someId", { headers: { authorization } })
      .then((response) => {
        console.info("FilesApiClient got user=", response);
        return Object.assign({} as GetUserResponse, response);
      })
      .catch((err) => {
        console.error("FilesApiClient error getting curr user", err);
        return undefined;
      });
  }

  async allowUserLogin(loginLevel: number = 1) {
    console.info("FilesApiClient allowUserLogin");
    if (!this.api || !this.isLocalMock) {
      return Promise.resolve({});
    }
    return this.api
      .put("/files/user/allowLoginDebug/qqqqqqqq-1111-2222-3333-rrrrrrrrrrrr/dGVzdEBpdHNqc29uLmNvbQ%3D%3D", { data: { loginLevel } })
      .then((response) => {
        console.info("FilesApiClient got result=", response);
        return response;
      })
      .catch((err) => {
        console.error("FilesApiClient error allowing curr user", err);
        return undefined;
      });
  }

  async allowUserLoginByAdmin(email: string) {
    const base64Email = Buffer.from(email).toString("base64");
    const encodedBase64 = encodeURIComponent(base64Email);
    console.info("FilesApiClient allowLoginByAdmin", encodedBase64);
    if (!this.api) {
      return Promise.resolve({});
    }
    return this.api
      .put("/files/user/allowLoginByAdmin/" + encodedBase64, {})
      .then((response) => {
        console.info("FilesApiClient got result=", response);
        return response;
      })
      .catch((err) => {
        console.error("FilesApiClient error allowing curr user", err);
        return undefined;
      });
  }

  async getSharedWithMe(): Promise<GetSharedFilesResponse> {
    console.info("FilesApiClient getSharedWithMe");
    if (!this.api) {
      return Promise.resolve({} as GetSharedFilesResponse);
    }
    const authorization = await loginJwtHolder.getJWT();
    const body: GetSharedFilesRequest = {};
    return this.api
      .get(`/files/file/shared`, { headers: { authorization } })
      .then((res) => {
        console.info("FilesApiClient got shared=", res); // TODO: too verbose
        return Object.assign({} as GetSharedFilesResponse, res);
      })
      .catch((err) => {
        console.error("FilesApiClient error getting shared", err);
        return [];
      });
  }

  async getFolder(folderId: string): Promise<GetFileResponse> {
    console.info("FilesApiClient getFolder", folderId);
    if (!this.api || !folderId?.length) {
      return Promise.resolve({} as GetFileResponse);
    }
    const authorization = await loginJwtHolder.getJWT();
    const body: GetFileRequest = {};
    return this.api
      .get(`/files/file/${FileTypes.FOLDER}/${folderId}`, { headers: { authorization } })
      .then((res) => {
        console.info("FilesApiClient got folder=", folderId, res); // TODO: too verbose
        return Object.assign({} as GetFileResponse, res);
      })
      .catch((err) => {
        console.error("FilesApiClient error getting folderId=", folderId, err);
        return [];
      });
  }

  async getFileInfo(fileId: string): Promise<GetFileResponse> {
    console.info("FilesApiClient getFileInfo", fileId, "api=", this.api);
    if (!this.api || !fileId?.length) {
      return Promise.resolve({ fileInfo: { owner: "mock" } });
    }
    const authorization = await loginJwtHolder.getJWT();
    const body: GetFileRequest = {};
    return this.api
      .get(`/files/file/${FileTypes.FILE}/${fileId}`, { headers: { authorization } })
      .then((res) => {
        console.info("FilesApiClient got file=", fileId, res); // TODO: too verbose
        const result: GetFileResponse = Object.assign({} as GetFileResponse, res);
        return result;
      })
      .catch((err) => {
        console.error("FilesApiClient getFileInfo error getting fileId=", fileId, err);
        return {} as GetFileResponse;
      });
  }

  async postNode(fileId: string, typeId: string, parent: string, initialName?: string, initialValue?: string): Promise<DbJsonNode[]> {
    console.info(`FilesApiClient postNode ${typeId}, ${parent}, ${initialName}, ${initialValue}`);
    if (!this.api) {
      return Promise.resolve([]);
    }

    const authorization = await loginJwtHolder.getJWT();
    const data: PostNodeRequest = { fileId, type: typeId, parent, initialName, initialValue };
    return this.api
      .post("/files/nodes", { headers: { authorization }, data })
      .then((res) => {
        return Object.assign({} as PostNodeResponse, res)?.nodes;
      })
      .catch((err) => {
        console.error(`FilesApiClient postNode failed creating a new node ${typeId} ${parent}`, err);
        return [];
      });
  }

  async updateNodes(nodes: DbJsonNode[], fileId: string): Promise<PutNodesResponse> {
    console.info("FilesApiClient updateNodes", nodes);
    if (!this.api) {
      const fail = nodes.map((node) => {
        return { id: node.id || "", error: "logged off" };
      });
      return Promise.resolve({ success: [], fail });
    }

    const dbNodes = nodes.map((node) => {
      return { ...node, val: "" + node.val };
    });

    const authorization = await loginJwtHolder.getJWT();
    const data: PutNodesRequest = { nodes: [...dbNodes], fileId };
    return this.api
      .put("/files/nodes", { headers: { authorization }, data })
      .then((res) => {
        console.debug("FilesApiClient updated nodes=", res);
        return Object.assign({} as PutNodesResponse, res);
      })
      .catch((err) => {
        console.error("FilesApiClient failed updating nodes=", nodes, err);
        const fail = nodes.map((node) => {
          return { id: node.id, error: "logged off" };
        });
        return { success: [], fail };
      });
  }

  async duplicateNode(fileId: string, nodeId: string): Promise<DbJsonNode[]> {
    console.info(`FilesApiClient duplicateNode ${nodeId}`);
    if (!this.api) {
      return Promise.resolve([]);
    }

    const authorization = await loginJwtHolder.getJWT();
    const data: PostDuplicateNodeRequest = { fileId, nodeId };
    return this.api
      .post("/files/nodes/duplicate", { headers: { authorization }, data })
      .then((res) => {
        return Object.assign({} as PostDuplicateNodeResponse, res)?.nodes;
      })
      .catch((err) => {
        console.error(`FilesApiClient duplicateNode failed duplicating node ${nodeId}`, err);
        return [];
      });
  }

  async getNodes(nodeIds: string[], fileId: string): Promise<GetNodesResponse> {
    console.info("FilesApiClient getNodes nodeIds=", nodeIds, "fileId=", fileId, "open=", this.api);
    if (!nodeIds?.length) {
      return Promise.resolve({ nodes: [] });
    }
    if (!this.api) {
      const nodes: [] = [];
      return Promise.resolve({ nodes });
    }

    const authorization = await loginJwtHolder.getJWT();
    const data: GetNodesRequest = { nodeIds, fileId };
    return this.api
      .post("/files/nodes/get", { headers: { authorization }, data })
      .then((res) => {
        console.info("FilesApiClient got nodes=", res);
        return Object.assign({} as GetNodesResponse, res);
      })
      .catch((err) => {
        console.error("FilesApiClient failed getting nodes=", nodeIds, err);
        const nodes: {
          status: number;
          res: null;
          id: string;
        }[] = nodeIds.map((nodeId) => {
          return { id: nodeId, status: 503, res: null };
        });
        return { nodes };
      });
  }

  async deleteNode(nodeId: string, fileId: string): Promise<boolean> {
    console.info("FilesApiClient delete node", nodeId);
    if (!this.api || !nodeId || !fileId) {
      return Promise.resolve(true);
    }

    const authorization = await loginJwtHolder.getJWT();
    return this.api
      .delete(`/files/nodes/${fileId}/${nodeId}`, { headers: { authorization } })
      .then((res) => {
        console.debug("FilesApiClient removed node=", res);
        return Object.assign({} as DeleteNodeResponse, res)?.result;
      })
      .catch((err) => {
        console.error("FilesApiClient failed removing node=", nodeId, err);
        return false;
      });
  }

  async getFullJson(fileId: string, rootPath: string): Promise<GetFullJsonResponse | undefined> {
    console.info(`FilesApiClient getFullJson fileId=${fileId} path=${rootPath}`);
    if (!this.api || !fileId || !rootPath) {
      return Promise.resolve(undefined);
    }

    const authorization = await loginJwtHolder.getJWT();
    const data: GetFullJsonRequest = { fileId, path: rootPath };
    return this.api
      .post("/files/file/json/get", { headers: { authorization }, data })
      .then((res) => {
        console.debug("getFullJson res=", res);
        return Object.assign({} as GetFullJsonResponse, res);
      })
      .catch((err) => {
        console.error(`getFullJson failed ${fileId} ${rootPath}`, err);
        return undefined;
      });
  }

  async queryChildWithName(fileId: string, path: string, childName: string): Promise<QueryNodeChildByNameResponse | undefined> {
    console.info(`FilesApiClient queryChildWithName fileId=${fileId} path=${path} childName=${childName}`);
    if (!this.api || !fileId || !path || !childName) {
      return Promise.resolve(undefined);
    }

    const authorization = await loginJwtHolder.getJWT();
    const data: QueryNodeChildByNameRequest = { fileId, path, childName };
    return this.api
      .post("/files/nodes/searchByName", { headers: { authorization }, data })
      .then((res) => {
        console.debug("queryChildWithName res=", res);
        return Object.assign({} as QueryNodeChildByNameResponse, res);
      })
      .catch((err) => {
        console.error(`queryChildWithName failed ${fileId} ${path} ${childName}`, err);
        return undefined;
      });
  }

  async loadMoreChildren(fileId: string, path: string, nextToken: string = ""): Promise<GetChildrenResponse | undefined> {
    console.info(`FilesApiClient loadMoreChildren fileId=${fileId} path=${path} nextToken=${nextToken}`);
    if (!this.api || !fileId || !path) {
      return Promise.resolve(undefined);
    }

    const authorization = await loginJwtHolder.getJWT();
    const data: GetChildrenRequest = { fileId, path, nextToken };
    return this.api
      .post("/files/nodes/children/get", { headers: { authorization }, data })
      .then((res) => {
        console.debug("loadMoreChildren res=", res);
        return Object.assign({} as GetChildrenResponse, res);
      })
      .catch((err) => {
        console.error(`loadMoreChildren failed ${fileId} ${path} ${nextToken}`, err);
        return false;
      });
  }

  async addFileToUser(filename: string, parent: string, filePattern: FilePattern, fileType: FileTypes): Promise<string> {
    console.info("FilesApiClient addFileToUser", filename);
    if (!this.api || !filename?.length) {
      return Promise.resolve("");
    }
    const authorization = await loginJwtHolder.getJWT();
    const data: PostFileRequest = { parent, filePattern, fileType };
    return this.api
      .post("/files/file/create/" + filename, { headers: { authorization }, data })
      .then((res) => {
        console.info("FilesApiClient created file=", filename, res); // TODO: too verbose
        return Object.assign({} as PostFileResponse, res).data;
      })
      .catch((err) => {
        console.error("FilesApiClient error adding filename=", filename, "to user", err);
        return "";
      });
  }

  async addUploadFileToUser(filename: string, parent: string, size: number): Promise<string> {
    console.info("FilesApiClient addUploadFileToUser", filename, parent, size);
    if (!this.api || !filename?.length) {
      return Promise.resolve("");
    }
    const authorization = await loginJwtHolder.getJWT();
    const data: PostUploadFileRequest = { parent, name: filename, size };
    return this.api
      .post("/files/file/upload", { headers: { authorization }, data })
      .then((res) => {
        console.info("FilesApiClient addUploadFileToUser created file=", filename, res); // TODO: too verbose
        return Object.assign({} as PostUploadFileResponse, res).uploadName;
      })
      .catch((err) => {
        console.error("FilesApiClient error adding upload filename=", filename, "to user", err);
        return "";
      });
  }

  async renameFile(fileId: string, newFileName: string) {
    console.info("FilesApiClient rename file", fileId, newFileName);
    if (!this.api || !fileId?.length || !newFileName?.length) {
      return Promise.resolve(true);
    }
    const authorization = await loginJwtHolder.getJWT();
    const data: PutRenameFileRequest = { fileName: newFileName };
    return this.api
      .put("/files/file/" + fileId, { headers: { authorization }, data })
      .then((res) => {
        console.info("FilesApiClient updated fileId=", fileId, "name to=", newFileName, res); // TODO: too verbose
        return Object.assign({} as PutRenameFileResponse, res).res?.length > 0;
      })
      .catch((err) => {
        console.error("FilesApiClient error renaming fileId=", fileId, "to name=", newFileName, err);
        return false;
      });
  }

  async getFilePermissions(fileId: string): Promise<GetFilePermissionEntry[]> {
    console.info(`FilesApiClient getFilePermissions ${fileId}`);
    if (!fileId?.length) {
      return Promise.resolve([]);
    }

    const authorization = await loginJwtHolder.getJWT();
    const data: GetFilePermissionsRequest = {};
    return this.api
      .get(`/files/file/permissions/${fileId}`, { headers: { authorization }, data })
      .then((res) => {
        console.info("FilesApiClient getFilePermissions=", res); // TODO: too verbose
        const result: GetFilePermissionsResponse = Object.assign({} as GetFilePermissionsResponse, res);
        return result?.permissions || [];
      })
      .catch((err) => {
        console.error("FilesApiClient getFilePermissions error", err);
        return [];
      });
  }

  async changeFilePermissions(fileId: string, newPermissions: PutFilePermissionEntry[]): Promise<boolean> {
    console.info(`FilesApiClient changeFilePermissions ${fileId} ${JSON.stringify(newPermissions)}`);
    if (!fileId?.length || !newPermissions?.length) {
      return Promise.resolve(true);
    }

    const authorization = await loginJwtHolder.getJWT();
    const data: PutFilePermissionsRequest = { newPermissions };
    return this.api
      .put(`/files/file/permissions/${fileId}`, { headers: { authorization }, data })
      .then((res) => {
        console.info("FilesApiClient changeFilePermissions=", res); // TODO: too verbose
        const result = Object.assign({} as PutFilePermissionsResponse, res);
        return true;
      })
      .catch((err) => {
        console.error("FilesApiClient changeFilePermissions error", err);
        return false;
      });
  }

  async deleteFile(fileId: string) {
    console.info("FilesApiClient delete fileId=", fileId);
    if (!this.api || !fileId?.length) {
      return Promise.resolve(true);
    }

    const authorization = await loginJwtHolder.getJWT();
    return this.api
      .delete("/files/file/" + fileId, { headers: { authorization } })
      .then((res) => {
        const result = Object.assign({} as DeleteFileResponse, res);
        console.info("FilesApiClient deleted fileId=", fileId, result); // TODO: too verbose
        return true;
      })
      .catch((err) => {
        console.error("FilesApiClient error deleting fileId=", fileId, err);
        return false;
      });
  }

  async getFileSuggestedVersions(fileId: string): Promise<GetFileVersionSuggestionResponse | null> {
    console.info(`FilesApiClient getFileSuggestedVersions ${fileId}`);
    if (!fileId?.length) {
      return null;
    }

    const authorization = await loginJwtHolder.getJWT();
    const data: GetFileVersionSuggestionRequest = {};
    return this.api
      .get(`/files/file/version/${fileId}`, { headers: { authorization }, data })
      .then((res) => {
        console.info("FilesApiClient getFileSuggestedVersions=", res); // TODO: too verbose
        const result: GetFileVersionSuggestionResponse = Object.assign({} as GetFilePermissionsResponse, res);
        return result || null;
      })
      .catch((err) => {
        console.error("FilesApiClient getFileSuggestedVersions error", err);
        return null;
      });
  }

  async postPublishFileVersion(fileId: string, version: string, changeType: VersionChangeType): Promise<boolean> {
    console.info("FilesApiClient postPublishFileVersion", fileId, version, changeType);
    if (!this.api || !fileId || !version || !changeType) {
      return false;
    }
    const authorization = await loginJwtHolder.getJWT();
    const newVersion: SuggestedVersion = { version, changeType };
    const data: PostFileVersionRequest = { fileId, newVersion };
    return this.api
      .post("/files/file/version/publish", { headers: { authorization }, data })
      .then((res) => {
        console.info("FilesApiClient postPublishFileVersion published", fileId, version, changeType);
        return true;
      })
      .catch((err) => {
        console.error("FilesApiClient postPublishFileVersion error", fileId, version, changeType, err);
        return false;
      });
  }

  async getFileAccessTokens(fileId: string): Promise<GetFileAccessTokensResponse | null> {
    console.info(`FilesApiClient getFileAccessTokens ${fileId}`);
    if (!fileId?.length) {
      return null;
    }

    const authorization = await loginJwtHolder.getJWT();
    const data: GetFileAccessTokensRequest = {};
    return this.api
      .get(`/files/file/json/${fileId}/accessTokens`, { headers: { authorization }, data })
      .then((res) => {
        console.info("FilesApiClient getFileAccessTokens=", res); // TODO: too verbose
        const result: GetFileAccessTokensResponse = Object.assign({} as GetFileAccessTokensResponse, res);
        return result || null;
      })
      .catch((err) => {
        console.error("FilesApiClient getFileAccessTokens error", err);
        return null;
      });
  }

  async postGenerateFileAccessToken(fileId: string, expiryInHours: number): Promise<string> {
    console.info("FilesApiClient postGenerateFileAccessToken", fileId, expiryInHours);
    if (!this.api || !fileId || !expiryInHours) {
      return "";
    }
    const authorization = await loginJwtHolder.getJWT();
    const expiryEpochMillis = Date.now() + expiryInHours * 60 * 60 * 1000;
    const data: PostGenerateFileAccessTokenRequest = { fileId, expiryEpochMillis };
    return this.api
      .post("/files/file/json/accessToken", { headers: { authorization }, data })
      .then((res) => {
        console.info("FilesApiClient postGenerateFileAccessToken published", fileId, expiryInHours);
        const result: PostGenerateFileAccessTokenResponse = Object.assign({} as PostGenerateFileAccessTokenResponse, res);
        return result.accessToken || "";
      })
      .catch((err) => {
        console.error("FilesApiClient postGenerateFileAccessToken error", fileId, expiryInHours, err);
        return "";
      });
  }
}

const FOLDER_PATH_SEPARATOR = "/";
export function breakFolderPath(path: string) {
  const split = path.trim().split(FOLDER_PATH_SEPARATOR);
  const allVals = ["", ...split];
  return [...new Set(allVals)];
}

export function getPreviousPath(path: string | undefined) {
  if (!path?.length) return FOLDER_PATH_SEPARATOR;
  const broken = breakFolderPath(path);
  if (broken.length < 3) return FOLDER_PATH_SEPARATOR;
  return broken.slice(0, -1).reduce((a, b) => a + FOLDER_PATH_SEPARATOR + b);
}
