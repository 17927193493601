import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import "./footer.css";
export type Props = {};

function Footer(props: Props) {
  const currentYear = new Date().getFullYear();
  // const [width, setWidth] = useState(window.innerWidth);

  // const updateDimensions = () => {
  //   setWidth(window.innerWidth);
  // };
  // useEffect(() => {
  //   window.addEventListener("resize", updateDimensions);
  //   return () => window.removeEventListener("resize", updateDimensions);
  // }, []);

  return (
    <footer className="page-footer">
      <Navbar variant="light" expand="sm" className="flex-column flex-xs-row flex-xl-row">
        <Nav className="m-auto">
          <Nav.Link href="http://itsjson.com">© {currentYear} ItsJson.com</Nav.Link>
          <Nav.Link href="/termsOfUse">Terms of use</Nav.Link>
          <Nav.Link href="/privacyPolicy">Privacy policy</Nav.Link>
          <Nav.Link href="/copyright">© Copyright</Nav.Link>
        </Nav>
      </Navbar>
    </footer>
  );
}

export default Footer;
