import { Box, Button, CircularProgress, Grid, Modal, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { FileToken, FileTokenModification, GetFileAccessTokensResponse } from "common";

export type WebApiManagementFormProps = {
  fileId: string;
  fileName: string;
  show: boolean;
  handleClose: () => void;
  handleGetApiKeys: (fileId: string) => Promise<GetFileAccessTokensResponse | null>;
  handleCreateApiKey: (fileId: string, expiryInHours: number) => Promise<string>;
};

const copyToClipBoard = (copyMe: string) => {
  try {
    navigator.clipboard.writeText(copyMe);
  } catch (err) {}
};

const onClickDownload = (event: any, downloadText: string, fileName: string) => {
  var blob = new Blob([downloadText], {
    type: "application/json;charset=utf8;",
  });
  var element = document.createElement("a");
  document.body.appendChild(element);
  element.setAttribute("href", window.URL.createObjectURL(blob));
  element.setAttribute("download", `${fileName}`);
  element.style.display = "";
  element.click();
  document.body.removeChild(element);
  event.stopPropagation();
};

const getCurrentDateFormatted = (): string => {
  const currentDate = new Date();

  const day = currentDate.getDate().toString().padStart(2, "0");
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
  const year = currentDate.getFullYear().toString();

  return `${year}${month}${day}`;
};

function WebApiManagementForm(props: WebApiManagementFormProps) {
  const [expiryHours, setExpiryHours] = useState(24);
  const [validated, setValidated] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [tokens, setTokens] = useState([] as FileToken[]);
  const [latestCreatedToken, setLatestCreatedToken] = useState("");

  useEffect(() => {
    if (!props.fileId) {
      setTokens([]);
      setExpiryHours(1);
      return;
    }
    props.handleGetApiKeys(props.fileId).then((result) => {
      setTokens(result?.tokens || []);
    });
  }, [props.fileId]);

  function updateExpiryHours(event: any) {
    const newValue = event?.target?.value;
    setExpiryHours(newValue);
  }

  function isExpiryValid(): boolean {
    if (!expiryHours || expiryHours < 1) return false;
    return expiryHours <= 365 * 24;
  }

  function handleCreateNewApiKey() {
    setLatestCreatedToken("");
    setValidated(true);
    if (isExpiryValid()) {
      setIsUpdating(true);
      props.handleCreateApiKey(props.fileId, expiryHours).then((newlyCreatedApiKey) => {
        props.handleGetApiKeys(props.fileId).then((result) => {
          setTokens(result?.tokens || []);
          setValidated(false);
          setIsUpdating(false);
          setLatestCreatedToken(newlyCreatedApiKey);
        });
      });
    }
  }

  function findLatestModification(modifications: FileTokenModification[] | undefined): number {
    if (!modifications?.length) return 0;
    const max = modifications.reduce((prev, current) => {
      return prev.modifiedDate > current.modifiedDate ? prev : current;
    });
    return max.modifiedDate;
  }

  function convertEpochToPrintableDate(epoch: number) {
    return new Date(epoch).toISOString().replace(/T/, " ").replace(/\..+/, "");
  }

  return (
    <>
      <Dialog open={props.show} onClose={props.handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Web Api keys</DialogTitle>
        <DialogContent>
          {!!latestCreatedToken?.length && (
            <Grid key={`token_created`} container flex="1" flexDirection="row" spacing={2} sx={{ paddingTop: "18px" }}>
              <Grid item xs={12} justifyContent="left" sx={{ display: "flex", alignItems: "center" }}>
                <Typography>Your new token:</Typography>
              </Grid>
              <Grid item xs={9} justifyContent="left" sx={{ display: "flex", alignItems: "center" }}>
                <Typography>{`${latestCreatedToken?.substring(0, 30)}..`}</Typography>
              </Grid>
              <Grid item xs={3} justifyContent="right" sx={{ display: "flex", alignItems: "right" }}>
                <IconButton onClick={() => copyToClipBoard(latestCreatedToken)}>
                  <ContentCopyIcon sx={{ color: "black" }} />
                </IconButton>
                <IconButton onClick={(event) => onClickDownload(event, latestCreatedToken, `${props.fileName}_${getCurrentDateFormatted()}.txt`)}>
                  <FileDownloadIcon sx={{ color: "black" }} />
                </IconButton>
              </Grid>
              <Grid item xs={12} justifyContent="left" sx={{ display: "flex", alignItems: "center" }}>
                <Typography>It will not be presented again, make sure you save it</Typography>
              </Grid>
            </Grid>
          )}
          {isUpdating && (
            <Grid container flex="1" flexDirection="row" spacing={2} sx={{ paddingTop: "18px" }}>
              <Grid item xs={12} justifyContent="center" sx={{ padding: "2rem", display: "flex", alignItems: "center" }}>
                <CircularProgress />
              </Grid>
            </Grid>
          )}
          {!isUpdating && !!tokens.length && (
            <Grid container flex="1" flexDirection="row" spacing={2} sx={{ paddingTop: "18px" }}>
              <Grid item xs={1} justifyContent="left" sx={{ display: "flex", alignItems: "left" }}></Grid>
              <Grid item xs={4} justifyContent="left" sx={{ display: "flex", alignItems: "left" }}>
                <Typography>created</Typography>
              </Grid>
              <Grid item xs={4} justifyContent="left" sx={{ display: "flex", alignItems: "left" }}>
                <Typography>updated</Typography>
              </Grid>
              <Grid item xs={3} justifyContent="left" sx={{ display: "flex", alignItems: "left" }}>
                <Typography>active</Typography>
              </Grid>
            </Grid>
          )}
          {!isUpdating &&
            !!tokens.length &&
            tokens.map((token, index) => (
              <Grid key={`token_${index}`} container flex="1" flexDirection="row" spacing={2} sx={{ paddingTop: "18px" }}>
                <Grid item xs={1} justifyContent="left" sx={{ display: "flex", alignItems: "center" }}>
                  <Typography>{index + 1}</Typography>
                </Grid>
                <Grid item xs={4} justifyContent="left" sx={{ display: "flex", alignItems: "center" }}>
                  <Typography>{convertEpochToPrintableDate(token.created)}</Typography>
                </Grid>
                <Grid item xs={4} justifyContent="left" sx={{ display: "flex", alignItems: "center" }}>
                  <Typography>{convertEpochToPrintableDate(findLatestModification(token.modifications) || token.created)}</Typography>
                </Grid>
                <Grid item xs={3} justifyContent="left" sx={{ display: "flex", alignItems: "center" }}>
                  <Typography>{token.active ? "enabled" : "disabled"}</Typography>
                </Grid>
              </Grid>
            ))}
          {!isUpdating && (
            <>
              <DialogContentText sx={{ padding: "1rem", paddingTop: "2rem" }}>Create new token</DialogContentText>
              <TextField
                label={`Token expiry in hours (maximum 365 days)`}
                id="tokenexpiry"
                size="small"
                placeholder={`1`}
                value={expiryHours}
                type="number"
                onChange={updateExpiryHours}
                error={validated && !isExpiryValid()}
                helperText="required field"
                required
                fullWidth
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleCreateNewApiKey();
            }}
            style={{ marginTop: "15px" }}
          >
            Create
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setTokens([]);
              setValidated(false);
              setIsUpdating(false);
              props.handleClose();
            }}
            style={{ marginTop: "15px" }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default WebApiManagementForm;
