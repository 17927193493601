import { TextField } from "@mui/material";
export type Props = {
  value: string;
  style: any;
  updateValue: (event: any) => Promise<void>;
  validationError: string;
  disabled?: boolean;
};
function DefaultValue(props: Props) {
  const onChange = (event: any) => {
    const newVal = event?.target?.value;
    props.updateValue(newVal);
  };
  return (
    <TextField
      disabled={props.disabled}
      id={"basicInput"}
      variant="standard"
      value={props.value}
      onChange={onChange}
      error={!!props.validationError.length}
      helperText={props.validationError}
      placeholder="Value"
      style={{ ...props.style }}
      size="small"
      fullWidth
    />
  );
}
export default DefaultValue;
