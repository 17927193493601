import { Box, Button, Modal, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import ReactQuill from "react-quill";

const htmlBoxStyle = {
  position: "absolute" as "absolute",
  top: "10%",
  left: "10%",
  bgcolor: "background.paper",
  border: "2px #000",
  borderRadius: "6px",
  boxShadow: 24,
  minWidth: "80%",
  p: 1,
};
export type Props = {
  value: string;
  style: any;
  validationError: string;
  handleShowHTML: () => void;
  handleCloseHTML: () => void;
  handleSaveHTML: (newHtml: string) => void;
  showHTML: boolean;
  disabled?: boolean;
};
function HtmlValue(props: Props) {
  const [htmlState, setHtmlState] = useState(props.value);
  return (
    <>
      <TextField
        id={"htmlInput"}
        variant="standard"
        value={props.value}
        onClick={props.handleShowHTML}
        error={!!props.validationError.length}
        helperText={props.validationError}
        placeholder="<p>empty</p>"
        style={{ ...props.style }}
        size="small"
        fullWidth
        InputProps={{
          readOnly: true,
        }}
      />
      <Modal open={props.showHTML} onClose={props.handleCloseHTML}>
        <Box sx={htmlBoxStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            HTML Editor
          </Typography>
          <ReactQuill
            theme="snow"
            defaultValue={props.value}
            onChange={(text: string, delta: any, source: string, editor: any) => {
              if (source == "user") {
                setHtmlState(text);
              }
            }}
            style={{ minHeight: "70vh" }}
            readOnly={props.disabled}
          />
          <Stack spacing={2} direction="row">
            {props.disabled ? (
              <Button variant="contained" onClick={props.handleCloseHTML} style={{ marginTop: "15px" }}>
                Back
              </Button>
            ) : (
              <>
                <Button variant="outlined" onClick={props.handleCloseHTML} style={{ marginTop: "15px" }}>
                  Cancel
                </Button>
                <Button variant="contained" onClick={() => props.handleSaveHTML(htmlState)} style={{ marginTop: "15px" }}>
                  Save
                </Button>
              </>
            )}
          </Stack>
        </Box>
      </Modal>
    </>
  );
}
export default HtmlValue;
