import { DocType } from "../../../db/docType";
import ParserIfc from "../../holder/typesparsers/parserIfc";

export const TYPES_DIVIDER = "|)|\\//||)ER\t";

export function calculatePresentationTypes(types: Map<DocType, ParserIfc>): [string[], Map<string, string>] {
  const idToDisplayName: Map<string, string> = new Map<string, string>();
  const calculatedIds: string[] = [];
  Array.from(types).forEach(([type, value], index) => {
    idToDisplayName.set(type.id, type.display);
    if (calculatedIds.includes(type.id)) {
      return;
    }
    if (index && value?.getSubTypes()?.size && calculatedIds.length && calculatedIds[calculatedIds.length - 1] !== TYPES_DIVIDER) {
      calculatedIds.push(TYPES_DIVIDER);
    }
    calculatedIds.push(type.id);
    if (value?.getSubTypes()?.size) {
      value.getSubTypes().forEach((subtypeId) => {
        calculatedIds.push(subtypeId);
      });
      calculatedIds.push(TYPES_DIVIDER);
    }
  });
  if (calculatedIds.length && calculatedIds[calculatedIds.length - 1] === TYPES_DIVIDER) {
    calculatedIds.pop();
  }
  return [calculatedIds, idToDisplayName];
}

export type TypeIdToDisplayName = {
  typeId: string;
  displayName: string;
  icon: string;
};
export function calculatePresentationTypes2(types: Map<DocType, ParserIfc>): TypeIdToDisplayName[][] {
  const idToDisplayName: Map<string, string> = new Map<string, string>();
  Array.from(types).forEach(([type]) => {
    idToDisplayName.set(type.id, type.display);
  });
  const alreadyAddedTypeIds = new Set<string>();
  const allGroups: TypeIdToDisplayName[][] = [];
  Array.from(types).forEach(([type]) => {
    const currGroup: TypeIdToDisplayName[] = [];
    const currTypeId = type.id;
    handleSingleType(currTypeId, alreadyAddedTypeIds, currGroup, idToDisplayName, types);
    allGroups.push(currGroup);
  });

  return allGroups.filter((group) => group.length).sort((a, b) => b.length - a.length);
}

function handleSingleType(
  currTypeId: string,
  alreadyAddedTypeIds: Set<string>,
  currGroup: TypeIdToDisplayName[],
  idToDisplayName: Map<string, string>,
  types: Map<DocType, ParserIfc>
) {
  if (alreadyAddedTypeIds.has(currTypeId)) {
    return;
  }

  const value = Array.from(types).find(([type, ifc]) => type.id === currTypeId);
  if (!value) return;
  const [docType, ifc] = value;
  if (!docType) return;
  currGroup.push({ typeId: currTypeId, displayName: idToDisplayName.get(currTypeId) || "", icon: docType.icon });
  alreadyAddedTypeIds.add(currTypeId);
  if (!ifc?.getSubTypes()?.size) {
    return;
  }
  ifc.getSubTypes().forEach((subtypeId) => {
    handleSingleType(subtypeId, alreadyAddedTypeIds, currGroup, idToDisplayName, types);
  });
}

export function calculatePresentationTypesForChangeType(types: Map<DocType, ParserIfc>, currentType: DocType): [string[], Map<string, string>] {
  const isCurrentTypeObjectOrArray = currentType.isArray || currentType.isObject;
  const idToDisplayName: Map<string, string> = new Map<string, string>();
  const calculatedIds: string[] = [];
  Array.from(types)
    .filter(([type, parser]) => (type.isArray || type.isObject) === isCurrentTypeObjectOrArray)
    .forEach(([type, value], index) => {
      idToDisplayName.set(type.id, type.display);
      if (calculatedIds.includes(type.id)) {
        return;
      }
      if (index && value?.getSubTypes()?.size && calculatedIds.length && calculatedIds[calculatedIds.length - 1] !== TYPES_DIVIDER) {
        calculatedIds.push(TYPES_DIVIDER);
      }
      calculatedIds.push(type.id);
      if (value?.getSubTypes()?.size) {
        value.getSubTypes().forEach((subtypeId) => {
          calculatedIds.push(subtypeId);
        });
        calculatedIds.push(TYPES_DIVIDER);
      }
    });
  if (calculatedIds.length && calculatedIds[calculatedIds.length - 1] === TYPES_DIVIDER) {
    calculatedIds.pop();
  }
  return [calculatedIds, idToDisplayName];
}
