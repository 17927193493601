import DocTypesHolder from "./docTypesHolder";
import { ValidationResult } from "./typesparsers/parserIfc";
import { JsonNode, JsonValue } from "../../db/jsonNode";
import NodesCache from "../../db/nodeCache";
import { PrimitiveTypesIDS } from "common";
import { DocType } from "../../db/docType";

class DocHolder {
  public constructor(private _typesHolder: DocTypesHolder = new DocTypesHolder(), private _cache: NodesCache, private _rootPath: string) {}

  public clone() {
    console.info("DocHolder cloning, this._cache=", this._cache);
    return new DocHolder(this._typesHolder, this._cache, this._rootPath);
  }

  public get typesHolder() {
    return this._typesHolder;
  }

  public getParentsSize() {
    return this._cache.getParentsSize();
  }

  public async getRootDoc(): Promise<JsonNode> {
    console.info("DocHolder getRootDoc");
    const val = await this._cache.get(this._rootPath);
    return val as JsonNode;
  }

  public getRootPath(): string {
    return this._rootPath;
  }

  public getNodesCache(): NodesCache {
    return this._cache;
  }

  public getPathBeforeMe(path: string): string {
    const parentPath = this._cache.getParentKey(path);
    return parentPath || this._rootPath;
  }

  public getDocTypes() {
    return this._typesHolder;
  }

  public async getDoc(path: string): Promise<JsonNode | null> {
    console.info("DocHolder getDoc", path);
    if (!path || path?.length < 5) {
      throw new Error(`path ${path} is not valid`);
    }
    return await this._cache.get(path);
  }

  public async persist() {
    return this._cache.persist();
  }
  public isModified(path: string): boolean {
    return this._cache.isModified(path);
  }

  public async getUnmodifiedDoc(path: string): Promise<JsonNode | null> {
    console.info("DocHolder getUnmodifiedDoc ", path);
    return this._cache.getOriginalDocFromCache(path);
  }

  public async setDocNameAtPath(path: string, newName: string): Promise<boolean> {
    const docAtPath = await this._cache.get(path);
    if (docAtPath) {
      this._cache.updateNode({ ...docAtPath, nodeName: newName });
      return true;
    }
    return false;
  }

  public async appendDocValueToPath(parentPath: string, type: DocType, initialName?: string, initialValue?: string): Promise<string> {
    const parentPathDoc = await this.getDoc(parentPath);
    console.info("DocHolder appendDocValueToPath parent=", parentPathDoc, "path=", parentPath);
    if (!parentPathDoc) return "";

    return await this._cache.createNode(type.id, parentPath, initialName, initialValue);
  }

  public async duplicateAttribute(nodeId: string): Promise<boolean> {
    console.info(`DocHolder duplicateAttribute nodeId=${nodeId}`);
    const parent = this.getPathBeforeMe(nodeId);
    if (!parent) {
      console.info(`DocHolder duplicateAttribute can't duplicate root doc`);
    }
    const res = await this._cache.duplicateNode(nodeId, parent);
    return !!res;
  }

  public async setDocValueAtPath(path: string, type: DocType, value: JsonValue): Promise<boolean> {
    console.info("DocHolder setDocValAtPath path=", path);
    const parsedValue = this._typesHolder.getParserForType(type)?.validateAndParse(value)?.parsedValue;

    const docAtPath = await this._cache.get(path);
    if (!docAtPath) return false;

    return await this._cache.updateNode({ ...docAtPath, type, value: parsedValue });
  }

  public async hasDocNameUnderDocPath(path: string, desiredName: string = ""): Promise<boolean> {
    const childWithName = await this.getChildWithName(path, desiredName);
    return !!childWithName;
  }

  public getDocTypePerId(typeId: string): DocType {
    const firstOption = this._typesHolder.getDocTypePerId(typeId);
    if (firstOption) return firstOption;
    return this._typesHolder.getDocTypePerId(PrimitiveTypesIDS.TEXT) as DocType;
  }

  public async removeDocPath(path: string): Promise<boolean> {
    if (!path?.length) return false; // can't remove empty path
    if (path === this._rootPath) return false; // can't remove root!

    const docAtPath = await this.getDoc(path);
    if (!docAtPath) return false;

    this._cache.deleteNode(path);

    return true;
  }

  public getDefaultType(): DocType {
    return this._typesHolder.defaultTxtType;
  }

  public getObjectType(): DocType {
    return this._typesHolder.objType;
  }

  public getArrayType(): DocType {
    return this._typesHolder.arrType;
  }

  public validateValue(type: DocType, value: any): ValidationResult {
    const parser = this._typesHolder.getParserForType(type);
    if (!parser) {
      return {
        validationError: "type not found for: " + type,
        parsedValue: undefined,
      };
    }
    return parser.validateAndParse(value);
  }

  async getCachedChildren(path: string): Promise<string[]> {
    return this._cache.getCachedChildren(path);
  }

  async loadMoreChildren(path: string, nextToken: string): Promise<string> {
    return this._cache.loadMoreChildren(path, nextToken);
  }

  async getChildWithName(path: string, childName: string): Promise<{ path: string; doc: JsonNode } | undefined> {
    const childrenIds = this._cache.getCachedChildren(path);
    for (const childId of childrenIds) {
      const currChild = await this.getDoc(childId);
      if (currChild?.nodeName === childName) {
        return { path: childId, doc: currChild };
      }
    }

    return undefined;
  }

  getIdsTillRoot(path: string): string[] {
    if (!path?.length) return [];
    if (path === this._rootPath) return [];

    let results: string[] = [];
    let currPath = path;
    // prevent infinite loop
    for (let i = 0; i < 400; i++) {
      const parent = this.getPathBeforeMe(currPath);
      if (!parent?.length) return results;
      results.push(currPath);
      if (parent === this._rootPath) return results;
      currPath = parent;
    }

    return results;
  }
}

export function getInitDoc(typesHolder: DocTypesHolder) {
  return {
    type: typesHolder.objType,
    value: [
      {
        type: typesHolder.defaultTxtType,
        name: "Untitled",
        value: [
          {
            type: typesHolder.defaultTxtType,
            value: "",
          },
        ],
      },
    ],
  };
}

export function getInitRootDoc(typesHolder: DocTypesHolder, childId: string, rootId: string): JsonNode {
  return {
    id: rootId,
    type: typesHolder.objType,
    value: "",
    parent: "",
  };
}

export function getFirstChildInitDoc(typesHolder: DocTypesHolder, rootPath: string, childId: string) {
  return {
    id: childId,
    type: typesHolder.defaultTxtType,
    name: "Untitled",
    value: "",
    parent: rootPath,
  };
}

export default DocHolder;
