export type Description = {
  check: boolean;
  text: string;
};

export const planDescriptionInfo: Info[] = [
  {
    id: 1,
    border: "secondary",
    price: 0,
    yearPayment: 0,
    title: "Free Trial",
    descriptions: [
      { check: true, text: "Very comfortable UI" },
      { check: true, text: "Edit 2 files" },
      { check: true, text: "Save 2 files" },
      { check: true, text: "Download 2 files" },
      { check: false, text: "Share the files" },
      { check: false, text: "Add links for images" },
      { check: false, text: "Add URLs" },
      { check: false, text: "Can use a premade examples" },
      { check: false, text: "Answer from the Technical support" },
      { check: false, text: "Error mark" },
    ],
  },

  {
    id: 2,
    border: "secondary",
    price: 30,
    title: "Basic",
    yearPayment: 14.99,
    descriptions: [
      { check: true, text: "Very comfortable UI" },
      { check: true, text: "Edit unlimited files" },
      { check: true, text: "Save unlimited files" },
      { check: true, text: "Download unlimited files" },
      { check: true, text: "Share the files - with only one person" },
      { check: true, text: "Add links for images" },
      { check: true, text: "Add URLs" },
      { check: true, text: "Can use Premade examples" },
      { check: true, text: "Answer from the Technical support - up to a week" },
      { check: true, text: "Error mark" },
    ],
  },
  {
    id: 3,
    border: "primary",
    price: 1,
    title: "Premium/Business",
    yearPayment: 1,
    descriptions: [
      { check: true, text: "Very comfortable UI" },
      { check: true, text: "Edit unlimited files" },
      { check: true, text: "Save unlimited files" },
      { check: true, text: "Download unlimited files" },
      { check: true, text: "Share the files - up to 100 or an organization" },
      { check: true, text: "Add links for images" },
      { check: true, text: "Add URLs" },
      { check: true, text: "Can use Premade examples" },
      { check: true, text: "Answer from the Technical support - up to 72 hours" },
      { check: true, text: "Error mark" },
    ],
  },
];
export type Info = {
  id: number;
  border: string;
  price: number;
  yearPayment: number;
  title: string;
  descriptions: Array<Description>;
};
