import AnyParser from "./anyParser";

class ArrayParser extends AnyParser {
  constructor(private params: any, private subTypes: Set<string>) {
    super(params, subTypes);
  }

  parse(value: any) {
    return value;
  }
}

export default ArrayParser;
