import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { OtherTypesIDS, PrimitiveTypesIDS } from "common";
import DocHolder from "../../holder/docHolder";
import { Box, Button, IconButton, Modal, Stack, TextField, Typography } from "@mui/material";

export type Props = {
  show: boolean;
  rootPath: string;
  docHolder: DocHolder;
  onHide: any;
  handleClose: any;
  handleSubmit: any;
};

type Answer = {
  text: string;
  feedback?: string;
  image?: string;
  score?: string;
};

const createEmptyAnswer = (): Answer => {
  return { text: "" };
};

function QuestionsForm(props: Props) {
  const [questionTxt, setQuestionTxt] = useState("");
  const [answers, setAnswers] = useState([createEmptyAnswer()]);
  const [validated, setValidated] = useState(false);
  const imageType = props.docHolder.getDocTypePerId(OtherTypesIDS.IMAGE);

  const handleSubmit = async (event: any) => {
    event?.preventDefault();
    setValidated(true);
    if (!questionTxt?.length || !answers?.length) {
      return;
    }
    // there is unfilled text in one of the answers
    if (answers.filter((answer) => !answer.text?.length)?.length) {
      return;
    }

    await createQuestion();
    if (props.handleSubmit) {
      props.handleSubmit();
    }
    setQuestionTxt("");
    setAnswers([createEmptyAnswer()]);
    setValidated(false);
  };

  const createQuestion = async () => {
    if (!answers.length) {
      return;
    }
    let counter = 1;
    let newName: string = "Question " + counter;

    while ((await props.docHolder.hasDocNameUnderDocPath(props.rootPath, newName)) && counter < 200) {
      counter++;
      newName = "Question " + counter;
    }

    const newObjPath = await props.docHolder.appendDocValueToPath(props.rootPath, props.docHolder.getDocTypePerId(OtherTypesIDS.QUESTION), newName);
    const txtType = props.docHolder.getDocTypePerId(PrimitiveTypesIDS.TEXT);
    const numType = props.docHolder.getDocTypePerId(PrimitiveTypesIDS.NUMBER);
    await props.docHolder.appendDocValueToPath(newObjPath, txtType, "text", questionTxt);
    const newArrPath = await props.docHolder.appendDocValueToPath(newObjPath, props.docHolder.getArrayType(), "answers", "");
    for (let answer of answers) {
      const newAnswerPath = await props.docHolder.appendDocValueToPath(newArrPath, props.docHolder.getObjectType(), "", "");
      await props.docHolder.appendDocValueToPath(newAnswerPath, txtType, "text", answer.text);
      if (answer.feedback) {
        await props.docHolder.appendDocValueToPath(newAnswerPath, txtType, "feedback", answer.feedback);
      }
      if (answer.image) {
        await props.docHolder.appendDocValueToPath(newAnswerPath, imageType, "image", answer.image);
      }
      if (answer.score !== undefined) {
        const score: number = answer.score === "-" ? -1 : +answer.score;
        await props.docHolder.appendDocValueToPath(newAnswerPath, numType, "score", `${score}`);
      }
    }
  };

  function updateQuestionValue(event: any) {
    const newValue = event?.target?.value || "";
    setQuestionTxt(newValue);
  }

  function updateQuestionAnswerText(index: number, event: any) {
    const newValue = event?.target?.value || "";
    if (answers.length > index) {
      setAnswers(
        answers.map((answer, ind) => {
          if (ind === index) {
            return { ...answer, text: newValue } as Answer;
          }
          return answer;
        })
      );
    }
  }

  function updateQuestionAnswerFeedback(index: number, event: any) {
    const newValue = event?.target?.value || "";
    if (answers.length > index) {
      setAnswers(
        answers.map((answer, ind) => {
          if (ind === index) {
            return { ...answer, feedback: newValue } as Answer;
          }
          return answer;
        })
      );
    }
  }

  function updateQuestionAnswerImage(index: number, event: any) {
    const newValue = event?.target?.value || "";
    if (answers.length > index) {
      setAnswers(
        answers.map((answer, ind) => {
          if (ind === index) {
            return { ...answer, image: newValue } as Answer;
          }
          return answer;
        })
      );
    }
  }

  function updateQuestionAnswerScore(index: number, event: any) {
    const newValue: string = event?.target?.value || "";
    if (answers.length > index) {
      setAnswers(
        answers.map((answer, ind) => {
          if (ind === index) {
            return { ...answer, score: newValue } as Answer;
          }
          return answer;
        })
      );
    }
  }

  function addNewAnswer() {
    setAnswers([...answers, createEmptyAnswer()]);
  }

  function removeAnswer(index: number) {
    setAnswers([...answers.filter((answer, ind) => ind !== index)]);
  }

  function validImage(val: string | undefined) {
    if (!val?.length) return "";
    return props.docHolder.validateValue(imageType, val)?.validationError;
  }

  const questionBoxStyle = {
    position: "absolute" as "absolute",
    top: "5%",
    left: "10%",
    bgcolor: "background.paper",
    border: "2px #000",
    borderRadius: "6px",
    boxShadow: 24,
    minWidth: "80%",
    p: 3,
  };

  return (
    <Modal open={props.show} onClose={props.onHide} style={{ overflow: "scroll" }}>
      <Box sx={questionBoxStyle} component="form">
        <Stack spacing={2} direction="column">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Create a new question
          </Typography>
          <TextField
            label="Question Text:"
            id="outlined-size-small"
            size="small"
            placeholder="What should we ask?"
            value={questionTxt}
            onChange={updateQuestionValue}
            error={validated && !questionTxt?.length}
            helperText="required field"
            required
          />

          <Typography id="modal-modal-title" variant="h6" component="h2">
            Answers:
          </Typography>

          {answers.map((answer, index) => {
            return (
              <div key={"answer" + index} style={{ paddingLeft: "1rem", paddingRight: "0.5rem", paddingBottom: "0.5rem", width: "80%" }}>
                <Stack spacing={2} direction="column">
                  <Stack spacing={2} direction="row">
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                      {"Answer #" + (index + 1) + ":"}
                    </Typography>
                    <IconButton style={{ paddingTop: "2px" }} onClick={() => removeAnswer(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                  <TextField
                    label={"Answer #" + (index + 1) + " text:"}
                    id={"answer-text-" + index}
                    size="small"
                    placeholder="What should we ask?"
                    value={answer.text}
                    onChange={(event) => updateQuestionAnswerText(index, event)}
                    fullWidth
                    error={validated && !answer.text?.length}
                    helperText="required field"
                    required
                  />
                  <TextField
                    label={"Answer feedback:"}
                    id={"answer-feedback-" + index}
                    size="small"
                    placeholder="feedback"
                    value={answer.feedback}
                    onChange={(event) => updateQuestionAnswerFeedback(index, event)}
                  />

                  <TextField
                    label={"Image:"}
                    id={"answer-img-" + index}
                    size="small"
                    placeholder="https://"
                    value={answer.image}
                    error={!!validImage(answer.image)?.length}
                    helperText={validImage(answer.image)}
                    onChange={(event) => updateQuestionAnswerImage(index, event)}
                  />
                  {answer.image?.length ? (
                    <>
                      <img src={answer.image} width="100px" height="100px" alt={answer.image?.length ? answer.image : "empty"} />
                      <br />
                    </>
                  ) : null}

                  <TextField
                    label={"Score:"}
                    id={"answer-scr-" + index}
                    type="number"
                    size="small"
                    placeholder="0"
                    value={answer.score}
                    onChange={(event) => updateQuestionAnswerScore(index, event)}
                  />
                </Stack>
              </div>
            );
          })}

          <Button
            className="add-btn"
            variant="contained"
            aria-label="add property"
            onClick={addNewAnswer}
            size="large"
            style={{ marginBottom: "5px", marginLeft: "5px" }}
          >
            <AddIcon sx={{ marginLeft: "0px", marginRight: "0px", padding: "0px", minWidth: "50px" }} />
          </Button>
          <Stack spacing={2} direction="row">
            <Button
              variant="outlined"
              onClick={() => {
                props.handleClose();
                setAnswers([createEmptyAnswer()]);
                setValidated(false);
              }}
              style={{ marginTop: "15px" }}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSubmit} style={{ marginTop: "15px" }}>
              Save
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
}

export default QuestionsForm;
