import { TextField, Tooltip } from "@mui/material";
import { Fragment } from "react";
export type Props = {
  value: string;
  style: any;
  updateValue: (event: any) => Promise<void>;
  validationError: string;
  disabled?: boolean;
};
function ImageValue(props: Props) {
  const onChange = (event: any) => {
    const newVal = event?.target?.value;
    props.updateValue(newVal);
  };

  return (
    <Tooltip
      title={
        props.value?.length ? (
          <Fragment>
            <img src={props.value} width="100px" height="100px" alt={props.value?.length ? props.value : "empty"} />
          </Fragment>
        ) : (
          ""
        )
      }
      placement="top"
      followCursor
      arrow
    >
      <TextField
        id={"imageInput"}
        variant="standard"
        value={props.value}
        disabled={props.disabled}
        onChange={onChange}
        error={!!props.validationError.length}
        helperText={props.validationError}
        placeholder="https://..."
        style={{ ...props.style }}
        size="small"
        fullWidth
      />
    </Tooltip>
  );
}
export default ImageValue;
