import React from "react";
import "./termsOfUse.css";

const TermsOfUse: React.FC = (props: any) => {
  return (
    <div className="terms-page">
      <div className="terms-container">
        <div style={{ textAlign: "center", paddingBottom: "2rem" }}>
          <h2>ITSJSON</h2>
          <h2>GENERAL CUSTOMER TERMS OF SERVICE</h2>
          <h4>Last Modified: December 3rd, 2023</h4>
        </div>
        These General Customer Terms Of Service are a short version of terms of service and is part and summary of the Extended Customer Terms Of Service.
        <br />
        These terms of use ("Terms") govern your access to and use of itsjson.com ("Service" or "Services").
        <br />
        By registering an account or using the Services, you agree to comply with and be bound by these Terms.
        <br />
        <br />
        <ol>
          <li>
            <strong>Account Registration</strong>
            <br />
            You must provide accurate, current, and complete information during the registration process. You are responsible for maintaining the
            confidentiality of your account and are liable for all activities conducted under your account.
          </li>
          <li>
            <strong>Data Storage and GDPR Compliance</strong>
            <br />
            Your data will be stored on servers located in Europe. By using our Services, you explicitly consent to the storage and processing of your
            information in Europe. We are committed to complying with the General Data Protection Regulation (GDPR) and will handle your data in accordance with
            its principles.
          </li>
          <li>
            <strong>User Responsibilities</strong>
            <br />
            You agree not to engage in any activities that violate applicable laws, infringe upon the rights of others, or disrupt the Services.
          </li>
          <li>
            <strong>Termination of Account</strong>
            <br />
            We reserve the right to suspend or terminate your account at our discretion without notice if we suspect a violation of these Terms or for any other
            reason.
          </li>
          <li>
            <strong>Changes to Terms</strong>
            <br />
            We may update these Terms at any time. Your continued use of the Services after modifications signifies your acceptance of the updated Terms.
          </li>
          <li>
            <strong>Intellectual Property</strong>
            <br />
            All content and materials provided on the Services, including but not limited to text, graphics, logos, images, and software, are the intellectual
            property of itsjson.com and are protected by copyright and other laws.
          </li>
          <li>
            <strong>Limitation of Liability</strong>
            <br />
            ItsJson.com and it's owners and employees are not liable for any direct, indirect, incidental, special, or consequential damages arising out of or
            in connection with your use of the Services.
          </li>
          <li>
            <strong>Indemnification</strong>
            <br />
            You agree to indemnify and hold harmless itsjson.com, its affiliates, officers, directors, employees, and agents from any claims, damages,
            liabilities, and expenses arising out of your use of the Services or violation of these Terms.
          </li>
          <li>
            <strong>Governing Law</strong>
            <br />
            These Terms are governed by and construed in accordance with the laws of Amsterdam, Netherlands, without regard to its conflict of law principles.
          </li>
          <li>
            <strong>Contact Information</strong>
            <br />
            For inquiries regarding these Terms or the Services, please contact us at info@itsjson.com.
          </li>
          <strong>By using our Services, you acknowledge that you have read, understood, and agreed to these Terms</strong>
        </ol>
      </div>

      <div className="terms-container">
        <div style={{ textAlign: "center", paddingBottom: "2rem" }}>
          <h2>ITSJSON</h2>
          <h2>EXTENDED CUSTOMER TERMS OF SERVICE</h2>
          <h4>Last Modified: December 3rd, 2023</h4>
        </div>
        These Customer Terms of Service (these “Customer Terms”) describe your rights and responsibilities when using our mobile and/or web-based software
        platform and related services (the “Services”). If you are a Customer (defined below), these Customer Terms govern your access and use of our Services.
        These Customer Terms (or, if applicable, your written agreement with us) and any Order Form(s) (defined below) together form a binding “Agreement”
        between Customer and us. “We”, “our”, and “us” refer to ItsJson, Inc. (or its successors or assigns). Please read these terms carefully to ensure you
        understand each provision. These Customer Terms contain a jury trial waiver provision and a mandatory arbitration provision. If you subscribe to, or
        access or use the Services, create an organization, invite users to that organization, or use or allow use of that organization after being notified of
        a change to these Customer Terms, you acknowledge your understanding of the then-current Agreement and agree to the Agreement on behalf of Customer.
        Please make sure you have the necessary authority to enter into the Agreement on behalf of the Customer before proceeding.
        <br />
        <ol>
          <li>
            <strong>General Provisions</strong>
            <br />
            <ol>
              <li>
                <strong>Customers, Authorized Users, and Customer Content</strong>
                <br />
                “Customer” or “you” is either you, if you are an individual entering into this Agreement on your own behalf, or the organization that you
                represent in agreeing to the Agreement. If your organization is being set up by someone who is not formally affiliated with a business entity or
                other organization, Customer is the individual creating the organization. If you signed up for a subscription plan using your corporate email
                domain or are otherwise entering into a subscription plan on behalf of a business entity or other organization, the business entity or other
                organization on whose behalf you signed up is the Customer. By signing up on behalf of your business entity or other organization, you represent
                and warrant that you have all right, power, and authority to bind such entity or organization to the Agreement. Individuals authorized by
                Customer to access the Services (each an “Authorized User”) may submit content or information to the Services, which includes Personal Data
                (defined below) and User Content (defined below) (“Customer Content”), and Customer will have the sole right and responsibility for managing
                your use of it. Customer will be solely responsible for all of the acts and omissions of its Authorized Users in relation to the Services and
                this Agreement. The Services are not intended for and should not be used by anyone under the age of 18. Customer must ensure that all Authorized
                Users are over 18 years old. Customer will (a) inform Authorized Users of all Customer policies and practices that are relevant to their use of
                the Services and of any settings that may impact the processing of Customer Content; and (b) obtain all rights, permissions or consents from
                Authorized Users and other Customer personnel that are necessary to grant the rights and licenses in the Agreement and for the lawful use and
                transmission of Customer Content and the operation of the Services.
              </li>
              <li>
                <strong>Subscriptions</strong>
                <br />A subscription allows Customer and its Authorized Users to access the Services. A subscription may be procured through the Services
                interface, or in some cases, via an order form entered into between Customer and us (each, an “Order Form”). Subscriptions commence when we make
                them available to Customer and continue for the term specified in the Services or in the Order Form, as applicable.
              </li>
              <li>
                <strong>Feedback</strong>
                <br />
                Customer and its Authorized Users may choose to, or we may invite them to, submit comments or ideas about the Services, including without
                limitation about how to improve the Services or our products (“Ideas”). By submitting any Idea, Customer agrees that its disclosure is
                gratuitous, unsolicited and without restriction and will not place us under any fiduciary or other obligation, and that we are free to use the
                Idea without any additional compensation, whether to Customer, the Authorized User, or anyone else, and/or to disclose the Idea on a
                non-confidential basis or otherwise to anyone. Customer further acknowledges that, by acceptance of the submission, we do not waive any rights
                to use similar or related ideas previously known to us, or developed by our personnel, or obtained from sources other than Customer or its
                Authorized Users.
              </li>
              <li>
                <strong>Privacy Policy and Protection of Personal Data</strong>
                <br />
                Please review our Privacy Policy for more information on how we collect and use data relating to the use and performance of our websites and
                products, including personal information about our Customers and Authorized Users. We will process any information relating to identified or
                identifiable natural persons, which is included in the Customer Content (“Personal Data”) only under the instruction and on behalf of Customer.
                We will not process Personal Data for other purposes. You acknowledge and agree that processing based on the provisions in this Agreement and in
                the product documentation qualify as Customer’s instruction. Generally, Personal Data means, as appropriate to the laws that are applicable to
                the identified or identifiable natural person whose information has been provided to us, “personal data” as that term is defined under the
                European General Data Protection Regulation (GDPR), and/or “personal information”, “personally identifiable information”, or such other similar
                term under applicable data privacy laws that applies to such person. Customer (not us) bears sole responsibility for adequate security,
                protection and backup of Personal Data when in Customer’s or its representatives’ or agents’ possession or control. We are not responsible, and
                Customer is fully responsible, for what Customer’s Authorized Users do with Personal Data. We are custodians of Personal Data. During the term
                of Customer’s subscription, Customer will be permitted to export or share certain Personal Data from the Services; provided, however, that
                Customer acknowledges and agrees that the ability to export or share Personal Data may be limited or unavailable depending on the type of
                Services plan in effect and the data retention settings enabled.
              </li>
              <li>
                <strong>Changes to the Services</strong>
                <br />
                Subject to the terms of this Agreement, you acknowledge and agree that we may modify or discontinue all or any part of the Services, temporarily
                or permanently, including without limitation any programs that we make available, at any time and for any reason.
              </li>
              <li>
                <strong>Beta Products</strong>
                <br />
                Currently the whole site is in beta(pre-release) phase, thus is made available “as is” and “as available”. After release, we will occasionally
                look for beta testers to help us test our new features. These features will be identified as “beta” or “pre-release,” or words or phrases with
                similar meanings (each, a “Beta Product”). Beta Products are made available on an “as is,” and “as available" basis and, to the extent permitted
                under applicable law, without any warranties or contractual commitments we make for other Services.
              </li>
            </ol>
          </li>
          <li>
            <strong>Services Usage and Restrictions</strong>
            <br />
            <ol>
              <li>
                <strong>Our License to Customer</strong>
                <br />
                We own and will continue to own our Services, including all related intellectual property and other proprietary rights related to the Services.
                For the duration of the subscription, we grant the Customer a non-exclusive, non-transferable license to access and use, and to permit
                Authorized Users to access and use the Services, in accordance with this Agreement, for the Customer's own internal business purposes. To the
                extent that we may make software components available, via app stores or other channels, as part of the Services, we grant to Customer a
                non-sublicensable, non-transferable, non-exclusive, limited license for Customer and its Authorized Users to use the object code version of
                these components, but solely as necessary to use the Services and in accordance with the Agreement. All of our rights not expressly granted by
                this license are hereby retained.
              </li>
              <li>
                <strong>Customer’s License to Us</strong>
                <br />
                Ownership of Customer Content. As between us on the one hand, and Customer and any Authorized Users on the other, Customer will own all Customer
                Content, including, for purposes of clarity, Personal Data and User Content. License to Personal Data. Subject to the terms and conditions of
                the Agreement, Customer (for itself and all of its Authorized Users) grants us a worldwide, non-exclusive, limited term license to access, use,
                process, copy, distribute, perform, export and display Personal Data, only as reasonably necessary (a) to provide and maintain the Services; (b)
                to prevent or address service, security, support or technical issues; (c) as required by law; and (d) as expressly permitted in writing by
                Customer. Customer represents and warrants that it has secured all rights in and to Personal Data from its Authorized Users or any third parties
                as may be necessary to grant this license. Notwithstanding the foregoing, Customer agrees that we may collect, analyze, use and disclose, during
                or after the term of this Agreement, data derived from Personal Data, which is anonymized and/or aggregated in a manner, that makes the
                identification of Customer or any Authorized User or third party impossible, for any business purpose, including without limitation, to operate,
                analyze, improve, and market the Services and our other products and services and share such anonymized data with our affiliates and business
                partners; provided that we will not use any of your User Content (defined below) except to the extent expressly permitted in the immediately
                following paragraph. Customer further agrees that we will have the perpetual right to use, store, transmit, distribute, modify, copy, display,
                sublicense, and create derivative works of such derived data. License to User Content. With respect to that portion of Customer Content that
                consists of videos, images, links, music, comments, questions, JSON documents, other documents, spreadsheets, Templates (defined below), and any
                other content submitted, posted, or otherwise made available by Customer and its Authorized Users through the Services (“User Content”), by
                submitting, posting, storing, or otherwise making such User Content available through the Services, Customer grants, and represents and warrants
                that it has all rights necessary to grant (including without limitation any necessary consents and authorizations from individual persons
                identified in the User Content and licenses from third-parties whose content is included in the User Content), to us a royalty-free,
                sublicensable, transferable, perpetual, irrevocable, non-exclusive, worldwide license to use, host, store, reproduce, modify, publish, list
                information regarding, translate, distribute, publicly perform, publicly display, and make derivative works of all such User Content, and the
                names, voice, and/or likeness contained in the User Content, in whole or in part, and in any form, media, or technology, whether now known or
                hereafter developed, solely for use in connection with our provision of the Services as described in this Agreement and our product
                documentation. To the extent permitted under applicable law, we take no responsibility and assume no liability for any User Content that
                Customer or any Authorized User or third-party submits, posts, or otherwise makes available through the Services. As between Customer and us,
                Customer shall be fully responsible for the User Content and the consequences of submitting, posting, or otherwise making it available via the
                Services, and Customer acknowledges and agrees that we are acting only as a passive conduit for Customer’s and its Authorized Users’ online
                distribution of such User Content.
              </li>
              <li>
                <strong>Templates</strong>
                <br />
                We may offer you the ability to create templates, frameworks, or prototypes, JSON types, via the Services (collectively, “Templates”). You may,
                in your sole discretion, allow us to make such Templates available to other users of the Services. You agree that, following our receipt of your
                consent to do so, make such Templates available to all users. You hereby irrevocably grant to us a nonexclusive, fully paid-up, royalty-free,
                assumable, perpetual, worldwide license, with right: (i) to transfer and to sublicense, to practice and exploit, any Templates you publish via
                the Services or for which you select a public setting; and (ii) to make, have made, copy, modify, make derivative works of, use, sell, import,
                and otherwise distribute any such Templates under all applicable intellectual property laws without restriction of any kind and to permit other
                users to do the same.
              </li>
              <li>
                <strong>Responsibilities for Customer Content</strong>
                <br />
                We are not responsible for the content of any Customer Content or the way Customer or its Authorized Users choose to use the Services to store
                or process any Customer Content. Customer represents and agrees that Customer is solely responsible for (i) providing notices and obtaining
                consents as legally required from its Authorized Users for the collection, use, processing and transfer of Customer Content in connection with
                the Services; and (ii) ensuring compliance with all laws in all jurisdictions that may apply to Customer Content provided hereunder, including
                but not limited to all applicable international, federal, state, provincial and local laws, rules, and regulations relating to data privacy and
                security. Unless otherwise agreed to in writing, Customer may not submit any Customer Content that includes a social security number, passport
                number, driver’s license number, or similar identifier, credit card or debit card number, or any other information which may be subject to
                specific data privacy and security laws including, but not limited to, the Gramm-Leach-Bliley Act (GLBA), the Health Insurance Portability and
                Accountability Act (HIPAA), the Health Information Technology for Economic and Clinical Health Act (HiTECH), the Family Educational Rights and
                Privacy Act of 1974 (FERPA), the Children's Online Privacy Protection Act (COPPA),or the GDPR or any other data which is considered to be
                sensitive or which could give rise to notification obligations under data breach notification laws. We do not make any representations as to the
                adequacy of the Service to process your Customer Content or to satisfy any legal or compliance requirements which may apply to your Customer
                Content, other than as described herein.
              </li>
              <li>
                <strong>Use of the Services</strong>
                <br />
                Customer must comply with the Agreement and ensure that its Authorized Users comply with the Agreement. We may review conduct for compliance
                purposes, but we have no obligation to do so. If we believe there is a violation of the Agreement that can be remedied by Customer’s removal of
                certain Customer Content, we will, in most cases, ask Customer to take direct action rather than intervene. However, to the extent legally
                permissible, we reserve the right to take further appropriate action, when we deem it reasonably appropriate if Customer does not take
                appropriate action, or if we believe there is a credible risk of harm to us, the Services, Authorized Users, or any third parties.
              </li>
              <li>
                <strong>Acceptable Use</strong>
                <br />
                <ol>
                  <li>
                    <strong>Publishing Policies</strong>
                    <br />
                    Without limiting the generality of anything in this Agreement, including without limitation anything in this Section 2.6, your use of the
                    Service is subject to our Publishing Policies, as amended from time to time, the terms of which are hereby incorporated by reference.
                  </li>
                  <li>
                    <strong>Technical Restrictions</strong>
                    <br />
                    You agree to not: (i) copy, distribute or modify any part of the Site without our prior written authorization; (ii) use, modify, create
                    derivative works of, transfer (by sale, resale, license, sublicense, download or otherwise), reproduce, distribute, display or disclose
                    Content (defined below), except as expressly authorized herein; (iii) disrupt servers or networks connected to the Site; (iv) use or launch
                    any automated system (including without limitation, “robots” and “spiders”) to access the Site; and/or (v) circumvent, disable or otherwise
                    interfere with security-related features of the Site or features that prevent or restrict use or copying of any Content or that enforce
                    limitations on use of the Site.
                  </li>
                  <li>
                    <strong>Customer Content Restrictions</strong>
                    <br />
                    You are solely responsible for the content of any Customer Content you submit through the Services. You agree not to submit any Customer
                    Content, including but not limited to any User Content, that: (i) may create a risk of harm, loss, physical or mental injury, emotional
                    distress, death, disability, disfigurement, or physical or mental illness to you, to any other person, or to any animal; (ii) may create a
                    risk of any other loss or damage to any person or property; (iii) seeks to harm or exploit children by exposing them to inappropriate
                    content, asking for personally identifiable details or otherwise; (iv) may constitute or contribute to a crime or tort; (v) contains any
                    information or content that we deem to be unlawful, harmful, abusive, racially or ethnically offensive, defamatory, infringing, invasive of
                    personal privacy or publicity rights, harassing, humiliating to other people (publicly or otherwise), libelous, threatening, profane,
                    obscene, or otherwise objectionable; (vi) contains any information or content that is illegal (including, without limitation, the disclosure
                    of insider information under securities law or of another party’s trade secrets); (vii) contains any information or content that you do not
                    have a right to make available under any law or under contractual or fiduciary relationships; (viii) contains any information or content
                    that you know is not correct and current; or (ix) to the extent applicable, violates any school or other applicable policy, including those
                    related to cheating or ethics. You agree that any User Content that you post does not and will not violate third-party rights of any kind,
                    including without limitation any intellectual property rights or rights of privacy. To the extent that your User Content contains music, you
                    hereby represent that you are the owner of all the copyright rights, including without limitation the performance, mechanical, and sound
                    recordings rights, with respect to each and every musical composition (including lyrics) and sound recording contained in such User Content
                    and have the power to grant the license granted below. To the extent legally permissible, we reserve the right, but are not obligated, to
                    reject and/or remove any User Content that we believe, in our sole discretion, violates any of these provisions.
                  </li>
                </ol>
              </li>
              <li>
                <strong>Open Source Software in the Services</strong>
                <br />
                The Services make use of components subject to the terms and conditions of "open source" software licenses; see Open Source Licenses for more
                details.
              </li>
            </ol>
          </li>
          <li>
            <strong>Payment Obligations</strong>
            <br />
            <ol>
              <li>
                <strong>Payment Terms</strong>
                <br />
                For Customers that purchase a subscription to use our Services, fees are specified through the Services interface or in the Order Form(s) — and
                must be paid in advance. Payment obligations are non-cancelable and, except as expressly stated in the Agreement, fees paid are non-refundable.
                For clarity, in the event Customer downgrades any subscriptions from a paid plan to a free plan, Customer will remain responsible for any unpaid
                fees under the paid plan, and Services under the paid plan will be deemed fully performed and delivered upon expiration of the initial paid plan
                subscription term. If we agree to invoice Customer by email, full payment must be received within thirty (30) days from the invoice date. Fees
                are stated exclusive of any taxes, levies, duties, or similar governmental assessments of any nature, including, for example, value-added,
                sales, use or withholding taxes, assessable by any jurisdiction (collectively, “Taxes”). Customer will be responsible for paying all Taxes
                associated with its purchases, except for those taxes based on our net income.
              </li>
              <li>
                <strong>Billing Policies</strong>
                <br />
                If you elect to purchase a subscription to use our Services, or certain other paid aspects of the Services, Customer agrees to the pricing
                communicated to you by us. We may add new services for additional fees and charges, or add or amend fees and charges for existing services, at
                any time in our sole discretion, by giving to the Customer at least 60 days' written notice, such increase to take effect at the beginning of
                the next billing cycle. If the Customer is unhappy with the increase, the Customer may give notice to terminate the Agreement, by giving at
                least 30 days' written notice to us, such notice to expire no later than the end of the then-current billing cycle. The fees will not increase
                during the notice period; otherwise, any change to such pricing shall become effective in the billing cycle following notice of such change as
                provided under the Agreement.
              </li>
              <li>
                <strong>Payment Information</strong>
                <br />
                To the extent we offer any Services for a fee or other payment, you represent and warrant that: (a) any account, order, and payment method
                information you supply to us or our service provider(s), as applicable, is true, correct, and complete; (b) you are duly authorized to use such
                payment method; (c) you will pay any charges that you incur in connection with the Services, including any applicable taxes; (d) charges
                incurred by you will be honored by your payment method company; (e) you will pay all charges incurred by you at the posted prices, including all
                applicable taxes, if any; (f) you will not allow anyone else to use your subscription; (g) you will not transfer your subscription or password
                to anyone else; and (h) you will report to us any unauthorized or prohibited access or use of your subscription or password. If any of your
                account, order, or payment method information changes, you agree to promptly update this information, so that we or our service provider(s) may
                complete your transactions and contact you as needed. We are not liable for any unauthorized use of your credit card, debit card, or other
                payment method by a third-party in connection with your use of the Services or your subscription.
              </li>
            </ol>
          </li>
          <li>
            <strong>Term and Termination</strong>
            <br />
            <ol>
              <li>
                <strong>Agreement Term</strong>
                <br />
                As further described below, a free subscription continues until terminated, while a paid subscription has a term that may expire or be
                terminated. The Agreement remains effective until all subscriptions ordered under the Agreement have expired or been terminated or the Agreement
                itself terminates. Termination of the Agreement will terminate all subscriptions and all Order Forms.
              </li>
              <li>
                <strong>Auto-Renewal</strong>
                <br />
                Unless otherwise set forth in an Order Form, (a) all subscriptions automatically renew for additional periods equal to one (1) year or the
                preceding term, whichever is shorter; and (b) the per-unit pricing during any automatic renewal term will be the then-current price communicated
                to you by us. Either party can give the other notice of non-renewal at least thirty (30) days before the end of a subscription term to stop a
                subscription from automatically renewing.
              </li>
              <li>
                <strong>Termination for Cause</strong>
                <br />
                We or Customer may terminate the Agreement on notice to the other party if the other party materially breaches the Agreement and such breach is
                not cured within thirty (30) days after the non-breaching party provides notice of the breach. Customer is responsible for its Authorized Users,
                including for any breaches of this Agreement caused by its Authorized Users. We may terminate the Agreement immediately on notice to Customer if
                we reasonably believe that the Services are being used by Customer or its Authorized Users in violation of applicable law.
              </li>
              <li>
                <strong>Termination Without Cause</strong>
                <br />
                Customer may terminate its free subscriptions immediately without cause. We may also terminate this Agreement without cause, but we will provide
                Customer with thirty (30) days prior written notice.
              </li>
              <li>
                <strong>Effect of Termination</strong>
                <br />
                Upon any termination for cause by Customer, we will refund Customer any prepaid fees covering the remainder of the term of all subscriptions
                after the effective date of termination. Upon any termination for cause by us, Customer will pay any unpaid fees covering the remainder of the
                term of those subscriptions after the effective date of termination. In no event will any termination relieve Customer of the obligation to pay
                any fees payable to us for the period prior to the effective date of termination.
              </li>
            </ol>
          </li>
          <li>
            <strong>Copyright Policy</strong>
            <br />
            It is our policy to respect the legitimate rights of copyright and other intellectual property owners, and we will respond to clear notices of
            alleged copyright infringement in accordance with our Copyright and Content Policy which may be viewed at: on the Site (“Copyright Notice”).
          </li>
          <li>
            <strong>Representations; Disclaimer of Warranties</strong>
            <br />
            Customer represents and warrants that it has validly entered into the Agreement and has the legal power to do so. Customer further represents and
            warrants that it is responsible for the conduct of its Authorized Users and their compliance with the terms of this Agreement. EXCEPT AS EXPRESSLY
            PROVIDED FOR HEREIN, THE SERVICES AND ALL RELATED COMPONENTS AND INFORMATION ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT ANY
            WARRANTIES OF ANY KIND, AND WE EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF
            MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. CUSTOMER ACKNOWLEDGES THAT WE DO NOT WARRANT THAT THE SERVICES WILL
            BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE. SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OF CERTAIN TYPES OF WARRANTIES, SO THE ABOVE
            DISCLAIMERS MAY NOT APPLY TO YOU. THE AGREEMENT GRANTS SPECIFIC LEGAL RIGHTS, AND CUSTOMER AND AUTHORIZED USERS MAY ALSO HAVE OTHER RIGHTS THAT VARY
            FROM JURISDICTION TO JURISDICTION. THE FOREGOING DISCLAIMERS WILL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.
          </li>
          <li>
            <strong>Limitation of Liability</strong>
            <br />
            OTHER THAN IN CONNECTION WITH CUSTOMER’S INDEMNIFICATION OBLIGATIONS HEREUNDER, IN NO EVENT WILL EITHER OUR AGGREGATE LIABILITY ARISING OUT OF OR
            RELATED TO THE AGREEMENT (WHETHER IN CONTRACT OR TORT OR UNDER ANY OTHER THEORY OF LIABILITY) EXCEED THE TOTAL AMOUNT PAID BY CUSTOMER HEREUNDER IN
            THE TWELVE (12) MONTHS PRECEDING THE LAST EVENT GIVING RISE TO LIABILITY. THE FOREGOING WILL NOT LIMIT CUSTOMER’S PAYMENT OBLIGATIONS UNDER THE
            “PAYMENT TERMS” SECTION ABOVE. IN NO EVENT WILL EITHER CUSTOMER OR US HAVE ANY LIABILITY TO THE OTHER PARTY OR TO ANY THIRD PARTY FOR ANY LOST
            PROFITS OR REVENUES OR FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, COVER OR PUNITIVE DAMAGES HOWEVER CAUSED, WHETHER IN CONTRACT, TORT OR
            UNDER ANY OTHER THEORY OF LIABILITY, AND WHETHER OR NOT THE PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. Customer is responsible for
            all login credentials, including usernames and passwords, for administrator accounts as well the accounts of your Authorized Users. We will not be
            responsible for any damages, losses or liability to Customer, Authorized Users, or anyone else, if such information is not kept confidential by
            Customer or its Authorized Users, or if such information is correctly provided by an unauthorized third party logging into and accessing the
            Services. The limitations under this “Limitation of Liability” section apply with respect to all legal theories, whether in contract, tort or
            otherwise, and to the extent permitted by law. The provisions of this “Limitation of Liability” section allocate the risks under this Agreement
            between the parties, and the parties have relied on these limitations in determining whether to enter into this Agreement and the pricing for the
            Services. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN TYPES OF DAMAGES, SUCH AS INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO
            THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. THE AGREEMENT GRANTS SPECIFIC LEGAL RIGHTS, AND CUSTOMER AND AUTHORIZED USERS MAY ALSO HAVE OTHER RIGHTS
            THAT VARY FROM JURISDICTION TO JURISDICTION. THE FOREGOING DISCLAIMERS AND LIMITATIONS WILL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.
          </li>
          <li>
            <strong>Indemnification</strong>
            <br />
            Customer agrees to defend, indemnify and hold harmless us and our affiliates, licensors, and suppliers, and our and their respective employees,
            contractors, agents, officers and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and
            expenses (including but not limited to attorney’s fees) arising from: (a) Customer’s and any Authorized User’s use of and access to the Services,
            including any Customer Content or other content transmitted or received by Customer or any Authorized User; (b) your violation of any term of the
            Agreement, including without limitation any breach of Customer’s representations and warranties above; (c) Customer’s or any Authorized User’s
            violation of any third-party right, including without limitation any right of privacy or intellectual property rights; (d) Customer’s or any
            Authorized User’s violation of any applicable law, rule or regulation; (e) Customer Content or any content that is submitted via Customer’s or any
            Authorized User’s account, including without limitation misleading, false, or inaccurate information; (f) Customer’s or any Authorized User’s gross
            negligence, fraud, or willful misconduct; or (g) any other party’s access and use of the Services with Customer’s or any Authorized User’s unique
            username, password or other appropriate security code (provided that such access and use was not our fault).
          </li>
          <li>
            <strong>Miscellaneous</strong>
            <br />
            <ol>
              <li>
                <strong>Publicity</strong>
                <br />
                Neither party may publicly use the other party’s company name, logo, or other trademarks for any purpose without the other party’s prior written
                consent.
              </li>
              <li>
                <strong>Third Party Products, Links, and Information</strong>
                <br />
                The Services may integrate with, or contain, third party products, services, materials, or information, or links thereto that are not owned or
                controlled by us (“Third Party Materials”). We do not endorse or assume any responsibility for any such Third Party Materials. If Customer or
                any Authorized User accesses any third party website or service, it does so at its own risk, and Customer acknowledges and agrees that the
                Agreement and our Privacy Policy do not apply to Customer or any Authorized User’s use of such sites or services. Customer expressly relieves us
                from any and all liability arising from its or its Authorized User’s use of any Third Party Materials.
              </li>
              <li>
                <strong>Force Majeure</strong>
                <br />
                Neither us nor Customer will be liable by reason of any failure or delay in the performance of its obligations on account of events beyond the
                reasonable control of a party, which may include denial-of-service attacks, a failure by a third party hosting provider or utility provider,
                strikes, shortages, riots, fires, acts of God, war, terrorism, and governmental action.
              </li>
              <li>
                <strong>Relationship of the Parties; No Third Party Beneficiaries</strong>
                <br />
                The parties are independent contractors. The Agreement does not create a partnership, franchise, joint venture, agency, fiduciary, or employment
                relationship between the parties. There are no third party beneficiaries to the Agreement; a person who is not a party to this Agreement may not
                enforce any of its terms under any applicable law.
              </li>
              <li>
                <strong>Email Communications</strong>
                <br />
                Except as otherwise set forth herein, all notices under the Agreement will be by email, although we may instead choose to provide notice to
                Customer through the Services. Notices to us must be sent to help@itsjson.com. Notices will be deemed to have been duly given (a) the business
                day after it is sent, in the case of notices through email; and (b) the same day, in the case of notices through the Services.
              </li>
              <li>
                <strong>Modifications</strong>
                <br />
                We may change these Customer Terms and the other components of the Agreement (except any Order Forms). If we make a material change to the
                Agreement, we will provide Customer with reasonable notice prior to the change taking effect, either by emailing the email address associated
                with Customer’s account or by messaging Customer through the Services. Customer can review the most current version of the Customer Terms at any
                time by visiting this page and by visiting the most current versions of the other pages that are referenced in the Agreement. The materially
                revised Agreement will become effective on the date set forth in our notice, and all other changes will become effective upon posting of the
                change. If Customer (or any Authorized User) accesses or uses the Services after the effective date, that use will constitute Customer’s
                acceptance of any revised terms and conditions.
              </li>
              <li>
                <strong>Waivers</strong>
                <br />
                No failure or delay by either party in exercising any right under the Agreement will constitute a waiver of that right. No waiver under the
                Agreement will be effective unless made in writing and signed by an authorized representative of the party being deemed to have granted the
                waiver.
              </li>
              <li>
                <strong>Severability</strong>
                <br />
                The Agreement will be enforced to the fullest extent permitted under applicable law. If any provision of the Agreement is held by a court of
                competent jurisdiction to be contrary to law, the provision will be modified by the court and interpreted so as best to accomplish the
                objectives of the original provision to the fullest extent permitted by law, and the remaining provisions of the Agreement will remain in
                effect.
              </li>
              <li>
                <strong>Assignment</strong>
                <br />
                Neither party may assign or delegate any of its rights or obligations hereunder, whether by operation of law or otherwise, without the prior
                written consent of the other party (not to be unreasonably withheld). Notwithstanding the foregoing, we may assign the Agreement in its entirety
                (including all Order Forms), without consent of Customer, to a corporate affiliate or in connection with a merger, acquisition, corporate
                reorganization, or sale of all or substantially all of its assets. Any purported assignment in violation of this section is void. Subject to the
                foregoing, the Agreement will bind and inure to the benefit of the parties, their respective successors and permitted assigns.
              </li>
              <li>
                <strong>Governing Law</strong>
                <br />
                The Agreement, and any disputes arising out of or related hereto, will be governed exclusively by the internal laws of the courts located in
                Amsterdam and waive any jurisdictional, venue, or inconvenient forum objections to such courts, provided that ItsJson may seek injunctive relief
                in any court of competent jurisdiction..
              </li>
              <li>
                <strong>Arbitration</strong>
                <br />
                ItsJson reserves the right to discontinue or modify any aspect of the Site at any time. These Terms and the relationship between you and ItsJson
                shall be governed by and construed in accordance with the laws of the Netherlands, without regard to its principles of conflict of laws. You
                agree to submit to the personal and exclusive jurisdiction of the courts located in Amsterdam and waive any jurisdictional, venue, or
                inconvenient forum objections to such courts, provided that ItsJson may seek injunctive relief in any court of competent jurisdiction. These
                Terms shall constitute the entire agreement between you and ItsJson concerning the Site. If any provision of these Terms is deemed invalid by a
                court of competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of these Terms,
                which shall remain in full force and effect.
              </li>
              <li>
                <strong>Entire Agreement</strong>
                <br />
                The Agreement, including these Customer Terms and all referenced pages and Order Forms, if applicable, constitutes the entire agreement between
                the parties and supersedes all prior and contemporaneous agreements, proposals or representations, written or oral, concerning its subject
                matter. Without limiting the foregoing, the Agreement supersedes the terms of any online agreement electronically accepted by Customer or any
                Authorized Users. However, to the extent of any conflict or inconsistency between the provisions in these Customer Terms and any other documents
                or pages referenced in these Customer Terms, the following order of precedence will apply: (1) the terms of any Order Form (if any), (2) the
                Customer Terms and (3) any other documents or pages referenced in the Terms. Notwithstanding any language to the contrary therein, no terms or
                conditions stated in a Customer purchase order, vendor onboarding process or web portal, or any other Customer order documentation (excluding
                Order Forms) will be incorporated into or form any part of the Agreement, and all such terms or conditions will be null and void.
              </li>
              <li>
                <strong>Contacting Us</strong>
                <br />
                Please also feel free to contact us if you have any questions about the Customer Terms or any other part of the Agreement. You may contact us at
                help@itsjson.com.
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default TermsOfUse;
