import { DbJsonNode } from "common";
import DocTypesHolder from "../components/holder/docTypesHolder";
import { DocType } from "./docType";

export type JsonValue = string | number | boolean | null;

export type JsonNode = {
  id: string;
  nodeName?: string;
  type: DocType;
  value: JsonValue;
  parent: string;
};

export function dbJsonNodeToJsonNode(dbNode: DbJsonNode | null, typesHolder: DocTypesHolder): JsonNode | null {
  if (!dbNode || !typesHolder) return null;
  let newType = typesHolder.getDocTypePerId(dbNode.type || "");
  if (!newType) newType = typesHolder.defaultTxtType;
  return {
    id: dbNode.id || "",
    nodeName: dbNode.nodeName || "",
    type: newType,
    value: dbNode.val || "",
    parent: dbNode.parent || "",
  };
}

export function jsonNodeToDbJsonNode(jsonNode: JsonNode | null): DbJsonNode | null {
  if (!jsonNode) return null;
  let newType = jsonNode.type.id;
  return {
    id: jsonNode.id || "",
    nodeName: jsonNode.nodeName || "",
    type: newType,
    val: "" + jsonNode.value,
    parent: jsonNode.parent || "",
  };
}

export function compareNodes(node: JsonNode, existingNode: JsonNode): boolean {
  return (
    node.id === existingNode?.id &&
    node.nodeName === existingNode.nodeName &&
    node.parent === existingNode.parent &&
    node.type === existingNode.type &&
    `${node.value}` === `${existingNode.value}`
  );
}
