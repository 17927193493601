import AnyParser from "./anyParser";
import { ValidationResult } from "./parserIfc";

const re = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]{0,18}$/;

class PhoneParser extends AnyParser {
  constructor(params: any, subTypes: Set<string>) {
    super(params, subTypes);
  }
  parse(value: any): string {
    return "" + value;
  }
  validateAndParse(value: any): ValidationResult {
    if (value === null || !re.test(value)) {
      return {
        parsedValue: "",
        validationError: "Phone number isn't valid",
      };
    }
    return { parsedValue: this.parse(value), validationError: "" };
  }
}

export default PhoneParser;
