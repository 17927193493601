import * as React from "react";
import { Link, Redirect } from "react-router-dom";
import passwordValidator from "password-validator";
import { useState } from "react";
import { Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from "@mui/material";
import * as EmailValidator from "email-validator";
import { signIn } from "../../auth/authenticatedComponent";
import VisibilityOff from "@mui/icons-material/HiveOutlined";
import Visibility from "@mui/icons-material/Hive";
import StarSharpIcon from "@mui/icons-material/StarSharp";
import { ConnectedProps, connect } from "react-redux";
import { setLastLogin } from "../../../redux/actions";
import { RootState } from "../../../redux/reducers";
// import { GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from "react-google-login";
type Props = PropsFromRedux & { history?: any };

// create a password schema
const schema = new passwordValidator();

schema.is().min(12).has().uppercase().has().lowercase().has().digits().has().symbols();

function LoginForm(props: Props) {
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const boxStyle = {
    top: "5%",
    left: "10%",
    border: "2px #000",
    borderRadius: "6px",
    minWidth: "80%",
    p: 3,
  };

  const isValidEmail = (): boolean => {
    return !!email?.length && EmailValidator.validate(email);
  };

  function updateEmail(event: any) {
    const newValue = event?.target?.value || "";
    setEmail(newValue);
  }

  const isValidPassword = (): boolean => {
    if (!password?.length) return false;
    return !!schema.validate(password);
  };

  function updatePassword(event: any) {
    const newValue = event?.target?.value || "";
    setPassword(newValue);
  }
  const handleShowNewPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleLogin = (googleData: any) => {
    console.log(googleData);
  };
  const handleFailure = (result: any) => {
    alert(result);
  };
  const handleSubmit = async (event: any) => {
    event?.preventDefault();
    setValidated(true);
    if (!isValidEmail()) {
      return;
    }
    if (!isValidPassword()) {
      return;
    }

    signIn(email, password)
      .then(() => props.history?.push("/filesexplorer"))
      .then(() => props.setLastLogin(Date.now()));
  };

  const handleSignUp = async (event: any) => {
    return props.history?.push("/signup");
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  return (
    <Grid container sx={boxStyle}>
      <Grid item xs={0} md={1} />
      <Grid item xs={12} md={6}>
        <Typography id="modal-modal-title" variant="h4" component="h4" paddingLeft={1} paddingBottom={1}>
          <strong>Login to create JSONs</strong>
        </Typography>
        <TextField
          fullWidth
          sx={{ m: 1 }}
          label={<strong>Email:</strong>}
          id="outlined-size-small"
          size="medium"
          placeholder=""
          value={email}
          onChange={updateEmail}
          error={validated && !isValidEmail()}
          helperText={validated && !isValidEmail() ? "Not valid Email" : ""}
        />
        <FormControl sx={{ m: 1 }} variant="outlined" fullWidth>
          <InputLabel htmlFor="outlined-adornment-newPassword">
            <strong>password:</strong>
          </InputLabel>
          <OutlinedInput
            required
            id="outlined-adornment-newPassword"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={updatePassword}
            error={validated && !isValidPassword()}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={handleShowNewPassword} onMouseDown={handleMouseDownPassword} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="newPassword"
          />
        </FormControl>
        &ensp;<Link to="/forgotpassword">forgot your password?</Link>
      </Grid>
      <Grid item xs={12} md={12}></Grid>
      {/* <Grid item xs={0} md={1} /> */}
      {/* <Grid item md={4} sx={{ display: { xs: "none", md: "block" } }}>
        <img src={process.env.PUBLIC_URL + "/mainPage/simple_types.png"} loading="lazy" />
      </Grid> */}
      <Grid item xs={0} md={1} />
      <Grid item xs={12} md={5} style={{ marginTop: "20px", marginLeft: "7px" }}>
        <Button size="large" variant="contained" onClick={handleSubmit} style={{ marginRight: "20px" }}>
          Login
        </Button>
        {/* <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              buttonText="Log in with Google"
              onSuccess={handleLogin}
              onFailure={handleFailure}
              cookiePolicy={"single_host_origin"}
            /> */}
        <Button size="large" variant="outlined" onClick={handleSignUp}>
          Sign up
        </Button>
      </Grid>
    </Grid>
  );
}

const mapState = (state: RootState) => ({});

const mapDispatch = { setLastLogin };

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedLogin = connector(LoginForm);
export default ConnectedLogin;
