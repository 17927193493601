import AnyParser from "./anyParser";

class BooleanParser extends AnyParser {
  constructor(private params: any, private subTypes: Set<string>) {
    super(params, subTypes);
  }
  parse(value: any): boolean {
    return value === "true" || value === true;
  }
}

export default BooleanParser;
