import { ToggleAction } from "../actions/toggleActions";
import { Types } from "../actionTypes";

export interface State {
  isOpen: boolean;
}

const initialState: State = {
  isOpen: true,
};

export default function reduce(
  state: State = initialState,
  action: ToggleAction
): State {
  switch (action.type) {
    case Types.TOGGLE:
      return { ...state, isOpen: !state.isOpen };
    default:
      return state || initialState;
  }
}
