import { Input, TextField, TextareaAutosize } from "@mui/material";
import { useEffect, useState } from "react";
import { propTypes } from "react-bootstrap/esm/Image";

export type Props = {
  value: string;
  style: any;
  updateValue: (event: any) => Promise<void>;
  isSelected: boolean;
  asyncValue?: Promise<string>; // can put instead of value
  disabled?: boolean;
};

function TextValue(props: Props) {
  const [previewAsyncValue, setPreviewAsyncValue] = useState("");

  useEffect(() => {
    if (props.asyncValue) {
      props.asyncValue.then((newVal) => setPreviewAsyncValue(newVal));
    }
  }, [props.asyncValue]);

  const onChange = (event: any) => {
    const newVal = event?.target?.value;
    props.updateValue(newVal);
  };

  return props.disabled ? (
    <TextField
      size="small"
      className={props.isSelected || props.disabled ? "textarea-active" : "textarea-inactive"}
      disabled
      variant="standard"
      fullWidth
      value={props.value || previewAsyncValue}
      style={{ ...props.style, marginTop: 6, marginBottom: 0, marginLeft: 3 }}
      inputProps={{
        disableUnderline: true,
      }}
    />
  ) : (
    <TextareaAutosize
      className={props.isSelected || props.disabled ? "textarea-active" : "textarea-inactive"}
      key={"textInput"}
      maxRows={3}
      aria-label="value textarea"
      placeholder=" Text value"
      value={props.value || previewAsyncValue}
      style={{ ...props.style }}
      onChange={onChange}
    />
  );
}
export default TextValue;
