import React, { useEffect, useState } from "react";
import Value from "./value";
import Type from "./type";
import Name from "./name";
import { ValidationResult } from "../../holder/typesparsers/parserIfc";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../redux/reducers";
import ConnectedJsonVisualElement from "../jsonVisualElement";
import { JsonNode } from "../../../db/jsonNode";
import { setHighlight, unsetOpenNode, setOpenNode } from "../../../redux/actions/";
import "./attribute.css";
import { DocType } from "../../../db/docType";
import { Grid, IconButton, Tooltip } from "@mui/material";
import AttributeHistoryForm from "./isModifyAttributeButton";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import BadgeUnstyled, { badgeUnstyledClasses } from "@mui/base/BadgeUnstyled";
import { styled } from "@mui/system";
import DocHolder from "../../holder/docHolder";
import { NodeState, NodeStateEnum } from "../../../redux/reducers/openNodesReducer";
import ConnectedAttributeCommands from "./attributeCommands";
export type Props = PropsFromRedux & {
  indexSoFar: string;
  path: string;
};

function Attribute(props: Props) {
  const determineInitialOpen = (currDoc: JsonNode | null, nodeStates: NodeState[], path: string): boolean => {
    if (!currDoc) return false;
    if (!currDoc.type.isArray && !currDoc.type.isObject) return true;
    if (nodeStates?.length) {
      const maybeCurrState = nodeStates.find((node) => node.id === path);
      if (maybeCurrState) {
        return maybeCurrState.state === NodeStateEnum.OPEN;
      }
    }
    const idsTillRoot = props.holder?.getIdsTillRoot(path)?.length || 0;
    if (idsTillRoot < 3) return true;
    return false;
  };

  const indexSoFar: string = props.indexSoFar || "";
  const [currDoc, setCurrDoc] = useState(null as JsonNode | null);
  const [currParent, setCurrParent] = useState(null as JsonNode | null);
  const [nodeStates, setNodeStates] = useState(props.openNodes);
  const [selectedHighlight, setSelectedHighlight] = useState(props.selectedHighlight);
  const [isSelected, setIsSelected] = useState(props.path === selectedHighlight);
  const [isOpen, setIsOpen] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [showPreviousValue, setShowPreviousValue] = useState(false);
  const [error, setError] = useState("" as string | undefined);

  const isParentArr = () => {
    return props.path.length > 0 && (currParent?.type?.isArray || false);
  };

  const observeFunction = (err: string | undefined) => {
    setIsModified(!!props?.holder?.isModified(props?.path));
    props.holder?.getDoc(props.path).then((doc) => setCurrDoc(doc));
    setError(err);
  };

  useEffect(() => {
    if (!props.holder) return;
    props.holder.getDoc(props.path).then((initCurrDoc) => {
      setCurrDoc(initCurrDoc);
      setIsModified(!!props?.holder?.isModified(props?.path));
    });
    props.holder?.getNodesCache().observe(props.path || "", observeFunction);
  }, [props.holder, props.path]);

  useEffect(() => {
    if (!currDoc) return;
    props.holder?.getDoc(currDoc?.parent || "").then((parent) => {
      setCurrParent(parent);
    });
    setIsOpen(determineInitialOpen(currDoc, props.openNodes, props.path));
  }, [currDoc]);

  useEffect(() => {
    // TODO: maybe remove the if and saving the selectedHighlight
    if (selectedHighlight !== props.selectedHighlight) {
      setIsSelected(props.path === props.selectedHighlight);
      setSelectedHighlight(props.selectedHighlight);
    }
  }, [props.selectedHighlight]);

  useEffect(() => {
    if (nodeStates !== props.openNodes) {
      const isOpen = determineIsOpen(nodeStates, props.openNodes, props.path);
      setIsOpen(isOpen);
      setNodeStates(props.openNodes);
    }
  }, [props.selectedHighlight, props.openNodes]);

  const determineIsOpen = (prevOpenNodes: NodeState[], nodeStates: NodeState[], path: string): boolean => {
    if (prevOpenNodes === nodeStates) {
      return isOpen;
    }
    const newOpen = nodeStates.find((currNode) => currNode.id === path)?.state;
    if (newOpen === undefined) return isOpen;
    return newOpen === NodeStateEnum.OPEN;
  };

  const getForObjOrArr = () => {
    return <ConnectedJsonVisualElement indexSoFar={indexSoFar} path={props.path}></ConnectedJsonVisualElement>;
  };

  const toggleOpenClosed = () => {
    if (isOpen) {
      props.unsetOpenNode(props.path);
    } else {
      props.setOpenNode(props.path);
    }
  };

  const closePreviewValue = () => {
    setShowPreviousValue(false);
  };

  const undoChanges = async (name: string, value: any) => {
    // setDocName(name);
    // setDocValue(value);
  };

  const stylish: any = {
    display: "flex",
    float: "left",
    flexDirection: "row",
    width: "100%",
  };

  const getDisplayName = (): string => {
    return isParentArr() ? `Element #${indexSoFar}` : currDoc?.nodeName || "Untitled";
  };

  const isComposite = (): boolean => {
    return currDoc?.type?.isArray || currDoc?.type?.isObject || false;
  };

  const StyledBadge = styled(BadgeUnstyled)`
    font-family: IBM Plex Sans, sans-serif;
    position: relative;
    display: inline-block;
    line-height: 1;
    width: 100%;
    & .${badgeUnstyledClasses.badge} {
      z-index: auto;
      min-width: 20px;
      height: 20px;
      padding: 0 6px;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      white-space: nowrap;
      position: absolute;
      radius: 50%;
      top: -10px;
      right: -5px;
      transform: translate(50%, -50%);
      transform-origin: 100% 0;
      opacity: 1;
      transition: opacity 0.2s ease-in-out;
    }

    & .${badgeUnstyledClasses.invisible} {
      opacity: 0;
      pointer-events: none;
    }
  `;

  return !currDoc || !currDoc?.type ? null : (
    <>
      <Grid container direction="row" pl={0} pr={0} pt={0} pb={0.2} className={isSelected ? "atr-container-selected" : "atr-container"}>
        <>
          <StyledBadge
            badgeContent={
              <Tooltip title="Show changes">
                <IconButton onClick={() => setShowPreviousValue(true)}>
                  <WarningAmberIcon color="warning" fontSize="medium" />
                </IconButton>
              </Tooltip>
            }
            invisible={!isModified}
          />

          <Grid item style={{ width: "100%" }} onClick={() => props.setHighlight(props.path)}>
            <Grid item xs={12} md={4} style={stylish}>
              {isComposite() && (
                <IconButton
                  onClick={(event) => {
                    toggleOpenClosed();
                    event.stopPropagation();
                    event.nativeEvent.stopImmediatePropagation();
                  }}
                >
                  {isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                </IconButton>
              )}
              <Type currentType={currDoc?.type} />
              {!isParentArr() && (
                <Name style={{ display: "inline", paddingRight: "6px", paddingLeft: "6px" }} holder={props.holder!} path={props.path} isSelected={isSelected} />
              )}
              {isParentArr() && (
                <Name
                  style={{ display: "inline", paddingRight: "6px", paddingLeft: "6px" }}
                  holder={props.holder!}
                  path={props.path}
                  disabled
                  overrideName={getDisplayName()}
                  isSelected={isSelected}
                />
              )}
            </Grid>
            <Grid item xs={12} md={8} style={stylish}>
              {isOpen && !isComposite() && (
                <Value style={{ display: "inline" }} key={"val_" + indexSoFar} holder={props.holder!} path={props.path} isSelected={isSelected} />
              )}
              {!isOpen && (
                <Value
                  style={{ display: "inline" }}
                  key={"val_" + indexSoFar}
                  holder={props.holder!}
                  path={props.path}
                  isSelected={isSelected}
                  preview={true}
                  disabled={true}
                />
              )}
              {isOpen && isSelected && isComposite() && <ConnectedAttributeCommands displayName={getDisplayName()} />}
            </Grid>

            {isOpen && isSelected && !isComposite() && (
              <Grid item xs={12} md={12} style={stylish}>
                <ConnectedAttributeCommands displayName={getDisplayName()} />
              </Grid>
            )}
          </Grid>

          {isOpen && isComposite() && (
            <Grid item columns={12} xs={12} style={stylish}>
              {getForObjOrArr()}
            </Grid>
          )}
        </>
      </Grid>
      {showPreviousValue && (
        <AttributeHistoryForm
          path={props.path}
          holder={props.holder!}
          dialogOpen={showPreviousValue}
          dialogOnClose={closePreviewValue}
          valueKey={"val_" + indexSoFar}
          closeDialog={closePreviewValue}
          undoChanges={undoChanges}
        />
      )}
    </>
  );
}

const mapState = (state: RootState) => ({
  selectedHighlight: state.highlight.selectedHighlight,
  holder: state.jsonholder.holder,
  openNodes: state.opennodes.openNodes,
});

const mapDispatch = { setHighlight, unsetOpenNode, setOpenNode };

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedAttribute = connector(Attribute);
export default ConnectedAttribute;

export const setDocName = async (holder: DocHolder, path: string, newName: string): Promise<string> => {
  // prettier-ignore
  console.info(`Attribute setDocName path=${path} newName=${newName}`);

  let resultStr = "";
  const currParentPath = holder.getPathBeforeMe(path);

  // TODO: do debounce
  if (await holder.getChildWithName(currParentPath, newName)) {
    resultStr = "name: " + newName + " already exists";
  }
  await holder.setDocNameAtPath(path, newName);
  return resultStr;
};

export const setDocValue = async (holder: DocHolder, type: DocType, path: string, value: any): Promise<string> => {
  // prettier-ignore
  console.info(`Attribute setDocValue path=${path} value=${value}`);

  const validationResult: ValidationResult = validateValue(holder, type, value);

  if (validationResult.validationError?.length) {
    console.error("Attribute error found in validation: " + validationResult.validationError);
    return validationResult.validationError;
  }

  await holder.setDocValueAtPath(path, type, validationResult.parsedValue);
  return "";
};

export const validateValue = (holder: DocHolder, type: DocType, value: any): ValidationResult => {
  return holder.validateValue(type, value);
};
