import { encryptPassword } from "../security/encryption";
import { AxiosHttpClient } from "./axiosHttpClient";

const localServer = "http://localhost:5001/";
const prodServer = "https://auxlm55ya4.execute-api.eu-west-1.amazonaws.com/prod/";

export class RegistrationApiClient {
  private httpClient: AxiosHttpClient;
  constructor(private isInit: boolean, private isLocalMock: boolean) {
    this.httpClient = new AxiosHttpClient(isLocalMock ? localServer : prodServer);
  }

  setIsInit(isInit = true) {
    this.isInit = isInit;
  }

  get mock() {
    return this.isLocalMock;
  }

  get api() {
    return this.httpClient;
  }

  register(email: string, password: string, firstName: string, lastName: string, country: string) {
    console.info("RegistrationApiClient register");
    if (!this.api) {
      return Promise.resolve({});
    }
    const encryptedPassword = encryptPassword(password);
    const data = { password: encryptedPassword, firstName, lastName, country };
    return this.api
      .post(`/users/register/${encodeURIComponent(email)}`, { data })
      .then((response) => {
        console.info("RegistrationApiClient got register result=", response);
        return response;
      })
      .catch((err) => {
        console.error("RegistrationApiClient error register", err);
        return undefined;
      });
  }

  resetPassword(email: string) {
    console.info("RegistrationApiClient resetPassword");
    if (!this.api) {
      return Promise.resolve({});
    }
    return this.api
      .put(`/users/passwordReset/${encodeURIComponent(email)}`, {})
      .then((response) => {
        console.info("RegistrationApiClient got resetPassword result=", response);
        return response;
      })
      .catch((err) => {
        console.error("RegistrationApiClient error resetPassword", err);
        return undefined;
      });
  }

  resendVerificationEmail(email: string) {
    console.info("RegistrationApiClient resendVerificationEmail");
    if (!this.api) {
      return Promise.resolve({});
    }
    return this.api
      .get(`/users/verificationEmail/${encodeURIComponent(email)}`)
      .then((response) => {
        console.info("RegistrationApiClient got resendVerificationEmail result=", response);
        return response;
      })
      .catch((err) => {
        console.error("RegistrationApiClient error resendVerificationEmail", err);
        return undefined;
      });
  }

  subscribtion(email: string, subscribtionLevel: number) {
    console.info("RegistrationApiClient subscribtion");
    if (!this.api) {
      return Promise.resolve({});
    }

    const data = { subscribtionLevel };
    return this.api
      .put(`/users/subscribtion/${encodeURIComponent(email)}`, { data })
      .then((response) => {
        console.info("RegistrationApiClient got subscribtion result=", response);
        return response;
      })
      .catch((err) => {
        console.error("RegistrationApiClient error subscribtion", err);
        return undefined;
      });
  }

  // extras: change password, get user email+firstname+lastname
}
