import { Button, CircularProgress, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from "@mui/material";
import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { GetFileVersionSuggestionResponse, SuggestedVersion, VersionChangeType } from "common";
import { authenticationClient } from "../../App";

export type PublishVersionFormProps = {
  show: boolean;
  fileId: string;
  handleClose: any;
};

function PublishVersionForm(props: PublishVersionFormProps) {
  const [selectedVersion, setSelectedVersion] = useState(null as SuggestedVersion | null);
  const [availableVersions, setAvailableVersions] = useState(null as GetFileVersionSuggestionResponse | null);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (!props.fileId) {
      setSelectedVersion(null);
      setAvailableVersions(null);
      setIsUpdating(false);
      return;
    }
    authenticationClient.getFileSuggestedVersions(props.fileId).then((response) => {
      setAvailableVersions(response);
    });
  }, [props.show]);

  function updateSelectedVersion(event: any) {
    if (isUpdating) return;
    const selectedVersion = event?.target?.value;
    const newVersion = availableVersions?.suggestedVersions.find((version) => version.version === selectedVersion) || null;
    setSelectedVersion(newVersion);
  }

  function calculateLabel(version: SuggestedVersion) {
    let type = "";
    if (version.changeType === VersionChangeType.MAJOR) {
      type = "Major";
    } else if (version.changeType === VersionChangeType.MINOR) {
      type = "Minor";
    } else if (version.changeType === VersionChangeType.PATCH) {
      type = "Patch";
    }
    if (availableVersions?.suggestedChange && version.changeType === availableVersions?.suggestedChange) {
      type += " - recommended";
    }

    return `${version.version} (${type})`;
  }

  function handleSubmit() {
    if (!selectedVersion || !props.fileId) {
      return;
    }
    setIsUpdating(true);
    authenticationClient.postPublishFileVersion(props.fileId, selectedVersion.version, selectedVersion.changeType).then((res) => {
      setIsUpdating(false);
      if (res) {
        handleClose();
        props.handleClose();
      }
    });
  }

  function handleClose() {
    if (!isUpdating) {
      setSelectedVersion(null);
      setAvailableVersions(null);
      props.handleClose();
    }
  }

  return (
    <Dialog open={props.show} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Publish version</DialogTitle>
      <DialogContent>
        {/* <DialogContentText></DialogContentText> */}
        <FormControl component="fieldset">
          <FormLabel component="legend">Select next version</FormLabel>
          <RadioGroup aria-label="gender" name="controlled-radio-buttons-group" value={selectedVersion?.version ?? " "} onChange={updateSelectedVersion}>
            {availableVersions?.suggestedVersions?.map((version) => (
              <FormControlLabel key={`radioForVersion${version.version}`} value={version.version} control={<Radio />} label={calculateLabel(version)} />
            ))}
          </RadioGroup>
        </FormControl>
        {isUpdating && (
          <Grid container flex="1" flexDirection="row" spacing={2} sx={{ paddingTop: "18px" }}>
            <Grid item xs={12} justifyContent="center" sx={{ padding: "2rem", display: "flex", alignItems: "center" }}>
              <CircularProgress />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={isUpdating} variant="outlined" onClick={() => handleClose()} style={{ marginTop: "15px" }}>
          Cancel
        </Button>
        <Button disabled={isUpdating} variant="contained" onClick={handleSubmit} style={{ marginTop: "15px" }}>
          Publish
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PublishVersionForm;
