import { FormControlLabel, Switch } from "@mui/material";
import { useEffect, useState } from "react";
export type Props = {
  value: string;
  updateValue: (event: any) => Promise<void>;
  disabled?: boolean;
};
function BooleanValue(props: Props) {
  const onChange = (event: any) => {
    const newVal = event?.target?.checked ? "true" : "false";
    props.updateValue(newVal);
  };

  return (
    <FormControlLabel
      disabled={props.disabled}
      value={props.value}
      control={<Switch color="primary" checked={props.value === "true"} />}
      label={props.value}
      labelPlacement="end"
      onChange={onChange}
    />
  );
}
export default BooleanValue;
