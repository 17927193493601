import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

export class AxiosHttpClient {
  private axiosInstance: AxiosInstance;
  constructor(private serverUrl: string, shouldAllowCrossOrigin: boolean = true) {
    this.axiosInstance = axios.create({
      baseURL: this.serverUrl,
      withCredentials: false,
      headers: shouldAllowCrossOrigin
        ? {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          }
        : {},
    });
  }
  get(path: any, config?: AxiosRequestConfig): Promise<any> {
    return this.axiosInstance.get(path, config).then((res: any) => {
      console.info("axiosHttpClient GET result=", res);
      return res?.data;
    });
  }
  post(path: any, config?: AxiosRequestConfig): Promise<any> {
    const data = config?.data;
    if (data) {
      delete config.data;
    }
    return this.axiosInstance.post(path, data, config).then((res: any) => {
      console.info("axiosHttpClient POST result=", res);
      return res?.data;
    });
  }
  put(path: any, config?: AxiosRequestConfig): Promise<any> {
    const data = config?.data;
    if (data) {
      delete config.data;
    }
    return this.axiosInstance.put(path, data, config).then((res: any) => {
      console.info("axiosHttpClient PUT result=", res);
      return res?.data;
    });
  }
  patch(path: any): Promise<any> {
    throw new Error("patch is not implemented by AxiosHttpClient");
  }
  delete(path: any, config?: AxiosRequestConfig): Promise<any> {
    return this.axiosInstance.delete(path, config).then((res: any) => {
      console.info("axiosHttpClient DELETE result=", res);
      return res?.data;
    });
  }
}
