import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Aboutus from "./components/aboutus";
import TermsOfUse from "./components/termsOfUse";
import PrivacyPolicy from "./components/privacyPolicy";
import MainForm from "./components/json/mainJsonVisualForm";
import Footer from "./components/footer/footer";
import Amplify, { API, Auth } from "aws-amplify";
import awsconfig from "./aws-exports";
import { FilesApiClient } from "./aws/filesApiClient";
import Cookie from "./components/cookie/cookie";
import MainPage from "./components/main";
import ContactUs from "./components/contactus/contactus";
import FilesExplorer from "./components/filesexplorer/filesexplorer";
import AdminForm from "./components/admin/adminForm";
import { withAuthenticator } from "./components/auth/authenticatedComponent";
import { RegistrationApiClient } from "./aws/registrationApiClient";
import ChangePasswordForm from "./components/login/changepassword/changePasswordForm";
import ProfileForm from "./components/user/profile/profileForm";
import SignUp from "./components/login/register/signup";
import SubscriptionForm from "./components/user/subscription/subscriptionForm";
import LoginForm from "./components/login/login/loginForm";
import Header from "./components/header";
import PrimarySearchAppBar from "./components/filesexplorer/header";
import ForgotPasswordForm from "./components/login/forgotpassword/forgotPasswordForm";
import { LoginJWTHolder } from "./helpers/loginJWTHolder";
import SwaggerComponent from "./components/swagger/swaggerComponent";
import Copyright from "./components/copyright/copyright";

Amplify.configure(awsconfig);
export const Api = API;

const isLocalMock = process.env.NODE_ENV === "development" && false; // add && false if you want to run with prod
const region = process.env.REGION || "eu-west-1";

console.info("starting env ", process.env.NODE_ENV, isLocalMock, Api);
export const loginJwtHolder = new LoginJWTHolder(isLocalMock, region);
export const authenticationClient = new FilesApiClient(true, isLocalMock);
export const registerClient = new RegistrationApiClient(true, isLocalMock);

if (isLocalMock) {
  authenticationClient.allowUserLogin(777);
}

function App() {
  const withAuth = (component: any) => {
    return withAuthenticator(component);
  };
  return (
    <div
      className="no-margin editor-page-entry"
      id="page-container"
      style={
        {
          //background: `url('${process.env.PUBLIC_URL}/background_blue.png')`,
          //backgroundSize: "cover",
        }
      }
    >
      <Router>
        <Switch>
          <Route path={["/", "/index.html"]} exact component={MainPage} />
          <Route path="/auth" exact component={withAuth(FilesExplorer as any)} />
          <Route path="/ziv" exact component={LoginForm} />
          <Route
            path={[
              "/filesexplorer",
              "/subscription",
              "/aboutus",
              "/contactus",
              "/copyright",
              "/termsOfUse",
              "/privacyPolicy",
              "/editor",
              "/signup",
              "/changepassword",
              "/profile",
              "/login",
              "/forgotpassword",
              "/swagger",
            ]}
          >
            <Header />
            <div id="content-wrap">
              <Switch>
                <Route path={["/filesexplorer"]} component={withAuth(FilesExplorer as any)} />
                <Route path="/login" component={LoginForm} />
                <Route path="/forgotpassword" component={ForgotPasswordForm} />
                <Route path="/subscription" component={SubscriptionForm} />
                <Route path="/aboutus" component={Aboutus} />
                <Route path="/signup" component={SignUp} />
                <Route path="/changepassword" component={ChangePasswordForm} />
                <Route path="/profile" component={withAuth(ProfileForm as any)} />
                <Route path="/contactus" component={ContactUs} />
                <Route path="/copyright" component={Copyright} />
                <Route path="/termsOfUse" component={TermsOfUse} />
                <Route path="/privacyPolicy" component={PrivacyPolicy} />
                {<Route path="/editor/:id" component={withAuth(MainForm as any)} />}
                <Route path={["/swagger"]} exact component={SwaggerComponent} />
              </Switch>
            </div>
            <Footer />
          </Route>
          <Route path={["/howdidyougethere"]} exact component={withAuth(AdminForm as any)} />
        </Switch>
      </Router>
      <Cookie />
    </div>
  );
}

export default App;
