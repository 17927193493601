import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteAttributeForm from "./deleteAttributeForm";
import { IconButton, Tooltip } from "@mui/material";
import DocHolder from "../../holder/docHolder";

export type Props = {
  propertyId: string;
  propertyName: string;
  holder: DocHolder;
  removeFunction: any;
};

function RemovePropertyButton(props: Props) {
  const removeFunction = props.removeFunction;
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  return (
    <>
      <Tooltip title="Delete">
        <IconButton
          onClick={() => {
            props.propertyId?.length > 3 && props.propertyName && setShowDeleteDialog(true);
          }}
        >
          <DeleteIcon sx={{ color: "black" }} />
        </IconButton>
      </Tooltip>
      {showDeleteDialog && (
        <DeleteAttributeForm
          show={showDeleteDialog}
          propertyName={props.propertyName}
          onHide={() => {
            setShowDeleteDialog(false);
          }}
          handleClose={() => {
            setShowDeleteDialog(false);
          }}
          handleSubmit={async () => {
            await removeFunction();
            setShowDeleteDialog(false);
          }}
        />
      )}
    </>
  );
}

export default RemovePropertyButton;
