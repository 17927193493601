import AnyParser from "./anyParser";
import { ValidationResult } from "./parserIfc";

class UrlParser extends AnyParser {
  constructor(private params: any, private subTypes: Set<string>) {
    super(params, subTypes);
  }
  parse(value: any): string {
    return value + "";
  }
  validateAndParse(value: any): ValidationResult {
    if (!isValidHttpUrl(value)) {
      return {
        parsedValue: "",
        validationError: ("" + value).startsWith("http") ? "not a valid URL" : "a link should start with http",
      };
    }
    return { parsedValue: this.parse(value), validationError: "" };
  }
}

export function isValidHttpUrl(urlStr: string) {
  if (!urlStr?.length) {
    return true;
  }
  try {
    new URL(urlStr);
    return true;
  } catch (_) {
    return false;
  }
}

export default UrlParser;
