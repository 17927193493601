import * as React from "react";
import CookieConsent from "react-cookie-consent";

export type Props = {};

function Cookie(props: Props) {
  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        style={{
          backgroundColor: "gray",
          borderRadius: "12px",
          border: "1px solid rgba(255, 255, 255, 0.125)",
          minHeight: "10vh",
          fontSize: "1.2rem",
          zIndex: 1200,
        }}
        debug={process.env.NODE_ENV === "development"}
      >
        We use necessary functional cookies. By accepting, you agree to our use of such cookies. <a href="/privacyPolicy">Privacy policy</a>
      </CookieConsent>
    </>
  );
}

export default Cookie;
