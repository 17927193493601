import { authenticationClient } from "../../App";
import { FileDescription } from "common";
import UserRecord from "../../db/userRecord";
import { Types } from "../actionTypes";

export type UserActionsType = {
  type: Types.SET_USER_INFO | Types.SET_ACTIVE_FILE | Types.SET_USER_LAST_LOGIN;
};

export type SetUserInfoAction = UserActionsType & {
  userInfo: UserRecord;
};

export type SetActiveFileAction = UserActionsType & {
  fileInfo: FileDescription;
};

export type SetUserLastLoginAction = UserActionsType & {
  newLastLogin: number;
};

export function setActiveFile(fileInfo: FileDescription): SetActiveFileAction {
  return { type: Types.SET_ACTIVE_FILE, fileInfo };
}

export function setUserInfo(userInfo: UserRecord): SetUserInfoAction {
  return { type: Types.SET_USER_INFO, userInfo };
}

export function setLastLogin(newLastLogin: number): SetUserLastLoginAction {
  return { type: Types.SET_USER_LAST_LOGIN, newLastLogin };
}

export function loadUserInfo() {
  return function (dispatch: any) {
    authenticationClient.loadUserInfo().then((response) => dispatch(loadUserInfoSuccess(response)));
  };
}

export function loadUserInfoSuccess(response: any) {
  const res: UserRecord = response?.user ? { ...response?.user } : {};
  console.info("loadUserInfoSuccess=", res);
  if (res?.id?.length) {
    return setUserInfo(res);
  }
  return setUserInfo({ id: "", rootFolder: "", firstName: "", lastName: "", email: "", countryCode: "USA", permissions: [] });
}
