import { FilePattern } from "common";

export const fileTypesInfo: Info[] = [
  {
    id: FilePattern.BLANK,
    premium: false,
    title: "Blank",
    description: "Create a new json from scratch",
  },

  {
    id: FilePattern.WEB_PAGE,
    premium: false,
    title: "Web Page",
    description: "Better than nothing",
  },
  {
    id: FilePattern.TRANSLATION,
    premium: false,
    title: "Translation",
    description: "Perfect For multi-languages platform",
  },
  {
    id: FilePattern.QUESTIONNAIRE,
    premium: false,
    title: "Questionnaire",
    description: "Perfect For test with question",
  },
  {
    id: FilePattern.PHONE_BOOK,
    premium: false,
    title: "Phone Book",
    description: "There isn't a better format to create your own phone book",
  },
  {
    id: FilePattern.CALENDAR,
    premium: false,
    title: "Calendar",
    description: "Create your own calendar with few steps",
  },
  {
    id: FilePattern.IMAGE_LIST,
    premium: false,
    title: "Image List",
    description: "Quick and easy format for saving your pictures",
  },
  {
    id: FilePattern.TABLE,
    premium: false,
    title: "Basic Table",
    description: "Prebuild table format -> id, name, phone, image, date",
  },
];
export type Info = {
  id: FilePattern;
  premium: boolean;
  title: string;
  description: string;
};
export const getNameById = (formatId: string): string => {
  return fileTypesInfo.find((fileTypeformat) => fileTypeformat.id === formatId)?.title || "";
};
