import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import JsonVisualElement from "./jsonVisualElement";
import JsonFormInput from "./jsonFormInput";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../redux/reducers";
import { addOpenNodes, loadUserInfo, setActiveFile, setHighlight, setJsonHolder } from "../../redux/actions";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Alert, CircularProgress, Grid, Button } from "@mui/material";
import { getRemoteDocHolder } from "../../redux/reducers/jsonHolderReducer";
import { authenticationClient } from "../../App";
import { FileInfo, FileTypes } from "common";
import AttributeHeader from "./header";
import PublishVersionForm from "./publishVersionForm";

export type Props = PropsFromRedux & {};

function MainForm(props: Props) {
  const [loading, setLoading] = useState(true);
  const [showJsonForm, setShowJsonForm] = useState(false);
  const [showPublishForm, setShowPublishForm] = useState(false);
  const [fileName, setFileName] = useState("");
  const params: any = useParams();
  const fileId: string = params.id;
  const handleCloseJsonForm = () => {
    setShowJsonForm(false);
  };
  useEffect(() => {
    authenticationClient.getFileInfo(fileId).then((filesResponse) => {
      if (FileTypes.FILE !== filesResponse.fileInfo.fileType) {
        console.info("MainForm got some weird file for fileId=", fileId, filesResponse);
        return;
      }
      const fileInfo = filesResponse.fileInfo as FileInfo;
      setActiveFile(filesResponse.fileInfo);
      setFileName(filesResponse.fileInfo.name || "");
      getRemoteDocHolder(fileInfo.id!, fileInfo.rootNode)
        .then((newDocHolder) => props.setJsonHolder(newDocHolder))
        .then((res) => setLoading(false));
    });
  }, [fileId]);

  useEffect(() => {
    if (!props.holder) {
      return;
    }
    const newRequiredOpenNodes = props.holder?.getIdsTillRoot(props.selectedHighlight);
    if (newRequiredOpenNodes?.length) {
      props.addOpenNodes(newRequiredOpenNodes);
    }
  }, [props.selectedHighlight]);

  if (loading || !props.holder) {
    return (
      <Grid container flex="1" flexDirection="row" spacing={2} sx={{ paddingTop: "18px" }}>
        <Grid item xs={12} justifyContent="center" sx={{ padding: "2rem", display: "flex", alignItems: "center" }}>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <AttributeHeader
            fileName={fileName}
            fileId={fileId}
            rootPath={props.holder.getRootPath()}
            setShowJsonForm={setShowJsonForm}
            setShowPublishForm={setShowPublishForm}
          />
        </Grid>
      </Grid>
      <Grid className="editor-page-main-after-header" container direction="row" sx={{ minHeight: "70vh" }}>
        {/* the Edible Tree*/}
        <Grid item xs={12}>
          <Alert
            severity="info"
            style={{
              marginLeft: "16px",
              marginRight: "14px",
              marginTop: "0px",
              marginBottom: "4px",
            }}
          >
            itsjson.com is still in a beta phase, therefore you may experience some issues and major changes.
          </Alert>
          <JsonVisualElement path={props.holder.getRootPath()} indexSoFar="" />
        </Grid>
      </Grid>
      {jsonPreviewDialog(showJsonForm, handleCloseJsonForm, fileId, props.holder.getRootPath())}
      {publishVersionDialog(showPublishForm, fileId, () => setShowPublishForm(false))}
    </>
  );
}

const jsonPreviewDialog = (showJsonForm: boolean, handleCloseJsonForm: any, fileId: string, rootPath: string) => {
  return (
    <Dialog open={showJsonForm} onClose={handleCloseJsonForm} scroll="paper" fullWidth maxWidth={"xl"}>
      <DialogTitle id="scroll-dialog-title">Json Preview</DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText>
          <JsonFormInput fileId={fileId} rootPath={rootPath} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseJsonForm}>Finish</Button>
      </DialogActions>
    </Dialog>
  );
};

const publishVersionDialog = (showPublishForm: boolean, fileId: string, handleClosePublishForm: any) => {
  return <PublishVersionForm show={showPublishForm} fileId={fileId} handleClose={handleClosePublishForm} />;
};

const mapState = (state: RootState) => ({
  holder: state.jsonholder.holder,
  selectedHighlight: state.highlight.selectedHighlight,
  user: state.user,
});

const mapDispatch = {
  addOpenNodes,
  setHighlight,
  setJsonHolder,
  setActiveFile,
  loadUserInfo,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedMainForm = connector(MainForm);
export default ConnectedMainForm;
