import DocTypesHolder from "../components/holder/docTypesHolder";
import { FilesApiClient, TEMPORARY_NODE_PREFIX } from "../aws/filesApiClient";
import { ApiModifications } from "./apiModifications";
import { compareNodes, dbJsonNodeToJsonNode, JsonNode, jsonNodeToDbJsonNode } from "./jsonNode";
import { debounce, DebouncedFunc } from "lodash";
import { DbJsonNode, GetNodesResponse } from "common";

type CallbackFunc = (error: string | undefined) => void;
class NodesCache {
  _modifiedNodesCache: Map<string, JsonNode | null> = new Map<string, JsonNode | null>(); // null is deleted!
  _debouncingGeneralChanges: DebouncedFunc<() => void> | null = null;
  constructor(
    private _fileId: string,
    private _typesHolder: DocTypesHolder,
    private _nodesCache: Map<string, JsonNode>,
    private _childToParent: Map<string, string>,
    private _api: FilesApiClient,
    private _mods = new ApiModifications(),
    private _getNodesCache = new Map<string, { p: Promise<GetNodesResponse>; res?: GetNodesResponse }>(),
    private _observers = new Map<string, CallbackFunc[]>(),
    private _generalChangesObservers: CallbackFunc[] = []
  ) {
    console.debug("NodesCache ctor", _fileId, _nodesCache, _childToParent, _api);
  }

  getApi() {
    return this._api;
  }

  // indicates that the key is an individual DB object
  hasDbKey(key: string): boolean {
    return this._nodesCache.has(key);
  }

  isModified(key: string): boolean {
    return this._modifiedNodesCache.has(key);
  }

  async get(key: string): Promise<JsonNode | null> {
    console.info("NodesCache get", key);
    if (this._modifiedNodesCache.has(key)) {
      const res = this._modifiedNodesCache.get(key);
      console.info("NodesCache from modified, res=", res);
      return res || null;
    }
    if (this._nodesCache.has(key)) {
      const foundRes = (this._nodesCache.get(key) as JsonNode) || null;
      console.info("NodesCache return from nodesCache", foundRes);
      return foundRes;
    }
    const currNode = await this.getNodeFromDB(key);
    if (currNode) {
      this.setNodeInCache(currNode);
    }
    return currNode;
  }

  async getNodeFromDB(nodeId: string): Promise<JsonNode | null> {
    const getNodeResult = await this.getNodeWithMultipleCaching(nodeId);
    if (!getNodeResult?.nodes?.length) return null;
    return dbJsonNodeToJsonNode(getNodeResult?.nodes?.[0] || null, this._typesHolder);
  }

  getCachedChildren(parentPath: string): string[] {
    return Array.from(this._childToParent.entries())
      .filter(([key, val]) => val === parentPath)
      .map(([key, val]) => key);
  }

  async loadMoreChildren(path: string, nextToken: string): Promise<string> {
    const result = await this._api.loadMoreChildren(this._fileId, path, nextToken);

    if (!result) return "";
    result.children.map((child) => {
      const jsonNode = dbJsonNodeToJsonNode(child, this._typesHolder);
      if (jsonNode) {
        this.setNodeInCache(jsonNode);
      }
    });

    return result.nextToken || "";
  }

  async queryChildWithName(path: string, childName: string): Promise<{ path: string; doc: JsonNode } | undefined> {
    const result = await this._api.queryChildWithName(this._fileId, path, childName);
    if (!result?.result) {
      return undefined;
    }
    const doc = dbJsonNodeToJsonNode(result.result, this._typesHolder)!;
    return { path, doc };
  }

  getOriginalDocFromCache(path: string): JsonNode | null {
    return (this._nodesCache.get(path) as JsonNode) || null;
  }

  observe(key: string, fn: CallbackFunc) {
    if (!this._observers.has(key)) {
      this._observers.set(key, []);
    }
    this._observers.get(key)?.push(fn);
  }

  generalObserve(fn: CallbackFunc) {
    this._generalChangesObservers.push(fn);
    console.debug("NodesCache generalObserve", this._generalChangesObservers.length);
  }

  private notifySpecificNode(key: string, error: string | undefined) {
    this._observers.get(key)?.forEach((observeFunc) => observeFunc(error));
  }

  private notifyNode(key: string, error: string | undefined) {
    this.notifySpecificNode(key, error);
    this.generalNotify();
  }

  notifyAllGeneralFunc = () => this._generalChangesObservers?.forEach((observeFunc) => observeFunc(undefined));

  private generalNotify() {
    console.info("NodesCache generalNotify");
    this._debouncingGeneralChanges?.cancel();
    this._debouncingGeneralChanges = debounce(this.notifyAllGeneralFunc, 1500);
    this._debouncingGeneralChanges();
  }

  getNodeWithMultipleCaching(key: string): Promise<GetNodesResponse> | undefined {
    console.info("NodesCache getNodeWithMultipleCaching key=", key);
    if (!this._getNodesCache.has(key)) {
      const resPromise = this._api.getNodes([key], this._fileId);
      this._getNodesCache.set(key, { p: resPromise });
      resPromise.then((res) => {
        this._getNodesCache.set(key, { p: resPromise, res });
      });
      setTimeout(() => {
        this._getNodesCache.delete(key);
      }, 5000);
      return resPromise;
    }
    const saved = this._getNodesCache.get(key);
    if (!saved?.res) return saved?.p;
    return Promise.resolve(saved.res);
  }

  private setNodeInCache(node: JsonNode) {
    console.info("NodesCache setNodeInCache id=", node?.id);
    if (!node?.id?.length) {
      return;
    }
    const newNode: JsonNode = { ...node };
    this._nodesCache.set(newNode.id, newNode);
    this._childToParent.set(newNode.id, newNode.parent);
    this.generalNotify();
  }

  private setUpdateNodeInCache(node: JsonNode): boolean {
    console.info("NodesCache setUpdateNodeInCache id=", node?.id);
    if (!node?.id?.length) {
      return false;
    }
    const existingNode = this._nodesCache.get(node.id);
    if (existingNode && compareNodes(node, existingNode)) {
      this._modifiedNodesCache.delete(node.id);
      this._mods.removeModification(node.id);
      this.notifyNode(node.id, undefined);
      return false;
    }
    this._modifiedNodesCache.set(node.id, node);
    this.notifyNode(node.id, undefined);
    return true;
  }

  getParentKey(key: string): string | undefined {
    console.info("NodesCache getParentKey key=", key);
    return this._childToParent.get(key);
  }

  async createNode(typeId: string, parent: string, initialName?: string, initialValue?: string): Promise<string> {
    console.info(`NodesCache createNode ${typeId} ${parent}`);
    const createdNodes = await this._api.postNode(this._fileId, typeId, parent, initialName, initialValue);
    if (!createdNodes) {
      return "";
    }
    createdNodes.forEach((node) => {
      const jsonNode = dbJsonNodeToJsonNode(node, this._typesHolder);
      if (!jsonNode) return;
      this._nodesCache.set(jsonNode.id, jsonNode);
    });
    this.notifyNode(parent, undefined);
    return createdNodes[0].id || "";
  }

  updateNode(node: JsonNode): boolean {
    console.info("NodesCache updateNode id:", node?.id);
    if (!this._nodesCache.has(node.id)) {
      return false;
    }

    const isUpdated = this.setUpdateNodeInCache(node);
    if (isUpdated) {
      this._mods.update(node.id);
    } else {
      this._mods.removeModification(node.id);
    }
    return true;
  }

  async duplicateNode(nodeId: string, parent: string): Promise<string> {
    console.info(`NodesCache duplicateNode ${nodeId} ${parent}`);
    const createdNodes = await this._api.duplicateNode(this._fileId, nodeId);
    if (!createdNodes) {
      return "";
    }
    createdNodes.forEach((node) => {
      const jsonNode = dbJsonNodeToJsonNode(node, this._typesHolder);
      if (!jsonNode) return;
      this._nodesCache.set(jsonNode.id, jsonNode);
    });
    this.notifyNode(parent, undefined);
    return createdNodes[0].id || "";
  }

  deleteNode(key: string): boolean {
    console.info("NodesCache deleteNode key:", key);
    if (!this._nodesCache.has(key)) {
      return false;
    }
    this._modifiedNodesCache.set(key, null);
    this._mods.delete(key);
    this.notifyNode(key, undefined);
    const parent = this.getParentKey(key);
    if (parent) {
      this.notifyNode(parent, undefined);
    }
    return true;
  }

  private async removePersistNodes(keys: string[]): Promise<boolean> {
    const delRes = await Promise.all(
      keys.map(async (key) => {
        const deleteRes = await this._api.deleteNode(key, this._fileId);
        if (deleteRes) {
          this._nodesCache.delete(key);
          this._modifiedNodesCache.delete(key);
          this._mods.removeModification(key);
        }
        return deleteRes;
      })
    );
    return !!delRes.length;
  }

  private async savePersistNodes(nodeIds: string[]): Promise<
    | {
        id: string;
        error: string;
      }[]
    | undefined
  > {
    const nodes = await Promise.all(nodeIds.map((id) => this.get(id)));
    const filteredNodes = nodes.filter((node) => node && !node?.id.startsWith(TEMPORARY_NODE_PREFIX)).map((node) => jsonNodeToDbJsonNode(node) as DbJsonNode);

    const res = await this._api.updateNodes(filteredNodes, this._fileId);
    console.info("savePersistNodes res=", res);
    filteredNodes
      .filter((node) => res.success?.find((currId) => node.id === currId))
      .forEach((node) => {
        let currJsonNode = nodes.find((nd) => nd?.id === node.id);
        if (!currJsonNode) {
          currJsonNode = dbJsonNodeToJsonNode(node, this._typesHolder);
        }
        this._modifiedNodesCache.delete(node.id || "");
        this._mods.removeModification(node.id || "");
        if (currJsonNode) {
          this._nodesCache.set(node.id || "", currJsonNode);
        }
      });
    return res?.fail;
  }

  getLevelFromRoot(id: string): number {
    // TODO: make more efficient with memory array
    console.info("NodesCache getLevelFromRoot id=", id);
    if (!id?.length) return 0;
    const parentKey = this.getParentKey(id);
    if (!parentKey || parentKey === id) return 0;
    return 1 + this.getLevelFromRoot(parentKey);
  }

  hasUnsavedChanges(): boolean {
    return this._mods.deleteModifications.length > 0 || this._mods.updateModifications.length > 0;
  }

  async persist() {
    const deleteMods = [...this._mods.deleteModifications];
    console.info("NodesCache persisting! deleteMods=", deleteMods);
    if (deleteMods?.length) {
      await this.removePersistNodes(deleteMods);
    }
    const updatedNodes = this._mods.updateModifications;
    console.info("NodesCache persisting! updatedNodes=", updatedNodes);

    const updatedParentNodes = this._mods.updateModifications;
    if (updatedParentNodes?.length) {
      const persistRes = await this.savePersistNodes(updatedNodes);
      updatedNodes.forEach(async (updatedNodeId) => {
        const currNode = await this.getNodeFromDB(updatedNodeId);
        if (!currNode) {
          this._nodesCache.delete(updatedNodeId);
        } else {
          this._nodesCache.set(updatedNodeId, currNode);
        }
        this.notifySpecificNode(updatedNodeId, persistRes?.find((res) => res.id === updatedNodeId)?.error);
      });
      return persistRes;
    }

    return undefined;
  }

  getParentsSize() {
    return this._childToParent.size;
  }
}

export default NodesCache;
