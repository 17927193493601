import { Types } from "../actionTypes";
import UserRecord from "../../db/userRecord";
import { SetActiveFileAction, SetUserLastLoginAction, SetUserInfoAction, UserActionsType } from "../actions/userActions";
import { FileDescription } from "common";

export interface State {
  userInfo: UserRecord;
  activeFile: FileDescription | undefined;
  lastLogin: number;
}

const initialState: State = {
  userInfo: { id: "", rootFolder: "", firstName: "", lastName: "", email: "", countryCode: "", permissions: [] },
  activeFile: undefined,
  lastLogin: 0,
};

export default function reduce(state: State = initialState, action: UserActionsType): State {
  switch (action.type) {
    case Types.SET_USER_INFO:
      return { ...state, userInfo: (action as SetUserInfoAction).userInfo };
    case Types.SET_ACTIVE_FILE:
      const fileInfo = (action as SetActiveFileAction).fileInfo;
      if (state.activeFile === fileInfo) {
        return state;
      }
      return { ...state, activeFile: fileInfo };
    case Types.SET_USER_LAST_LOGIN:
      const newLastLogin = (action as SetUserLastLoginAction).newLastLogin;
      if (state.lastLogin === newLastLogin) {
        return state;
      }
      return { ...state, lastLogin: newLastLogin };
    default:
      return state || initialState;
  }
}
