import { AxiosHttpClient } from "./axiosHttpClient";
import { sign, SigningConfig, SigningRequest } from "./signV4Client";

const localServer = "http://localhost:5001";
const prodServer = "https://6v6b86ais4.execute-api.eu-west-1.amazonaws.com";
export const LOCAL_JWT = "Bearer IamJustAnUnknownJWTSortOfTokenForMockOperations";

export class LoginApiClient {
  private httpClient: AxiosHttpClient;
  private baseUrl: string = localServer;

  constructor(private isLocalMock: boolean, private region: string) {
    this.baseUrl = isLocalMock ? localServer : prodServer;
    this.httpClient = new AxiosHttpClient(this.baseUrl, false);
  }

  get mock() {
    return this.isLocalMock;
  }

  get api() {
    return this.httpClient;
  }

  async refreshJwt(accessKey: string, secretKey: string, sessionToken: string): Promise<string> {
    console.info("LoginApiClient::refreshJwt register");
    if (this.mock || !this.api) {
      return Promise.resolve(LOCAL_JWT);
    }
    const signingConfig: SigningConfig = {
      accessKey,
      secretKey,
      sessionToken,
      region: this.region,
      endpointUrl: "https://6v6b86ais4.execute-api.eu-west-1.amazonaws.com",
    };
    const request: SigningRequest = {
      method: "GET",
      path: "/prod/login/jwt",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    };
    try {
      const signedRequest = sign(signingConfig, request);
      const authorizationHeader = await this.httpClient.get(request.path, { headers: signedRequest.headers }).then((res: any) => res?.authorization);
      console.info(`refreshJwt received the following result from /login/jwt= ${authorizationHeader}`);
      return authorizationHeader;
    } catch (err) {
      console.error(`refreshJwt failed signing request`, err);
    }
    return "";
  }
}
