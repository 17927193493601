import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import "./swagger-overrides.css";

type Props = { history?: any };

function SwaggerComponent(props: Props) {
  return (
    <>
      <SwaggerUI url={`${process.env.PUBLIC_URL}/generated/swagger_output.json`} />
    </>
  );
}

export default SwaggerComponent;
