import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Card from "react-bootstrap/Card";
import { Info } from "./aboutUsInfo";
import { ImageListItem, Link, Typography } from "@mui/material";
function OneCard(props: any) {
  const infoTerm: Info = props.info;
  return (
    <>
      <Card className="aboutus-card" border="0">
        <ImageListItem>
          <img src={infoTerm.img} className="aboutus-card-img" aria-label="Name" />
        </ImageListItem>
        <Card.Body>
          <Typography variant="h1" className="aboutus-card-name">
            {infoTerm.name}
          </Typography>
          <Typography variant="h2" className="aboutus-card-role">
            {infoTerm.role}
          </Typography>
          <Card.Text>{infoTerm.aboutMe}</Card.Text>
          <Link href={infoTerm.linkedin}>
            <LinkedInIcon />
          </Link>
        </Card.Body>
      </Card>
    </>
  );
}

export default OneCard;
