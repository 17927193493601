export const publicKey =
  "-----BEGIN RSA PUBLIC KEY-----\
MIICCgKCAgEA2BVSC5wSmv0+X/eflGdixNW9nnhwQsS/b3p4wvQ3zDjUGgGAgxTk\
VfJT3cfYwMLVnyLYQGMFxWYB8/nDrfZNdFVxdLAd2QJvMnmf8SfLbe8KNw7QH1wL\
VLN23d1S+FC5k383ymlJ2Y3frj23fe8hNWisGOjJKlLA3HgxczrZinQLih12cQGS\
t5sXLEMYop5VDRuRSXAaZQkoUSX4Ipv4gVNb8ohRxYWGPn0BKi26e3POEV9EVM0e\
VXmf7J/gTF/Ho+NYYdXBTvkxw1re1uFkejBgVaiFiCkLHKPnUyPg8lXGZbfR3zgV\
x9DSbV653qhbluQiZx1l94vFy3iBMr+oHJNJdhap9vBu4V7gJbCLH0darQ3ajXnk\
PZ0T2jAHUHaOYQMptiSx2fBs5CSem1Oll2L6RBbbUnG6KkakJdZYqVydq6oJlQH0\
8HU5gF4KJ1pgjpKg6EstRSTlYfSOeHhyo/eJxhRVljvyXALdGgqKSZS2hPgnxBMT\
suRZaq27o2QVB9FwJEtI7TulvSxgNWiCBh75ngagvoiYbXc3z8tMtxeSrxyAaBvy\
dy2y/ESCZqfGr21oOmSD/cLCO0rDJLtR+c7vFNsH8a1KHWbHfxMGZ5keuOXgrgTq\
1k6ijjKgSrBgQBT8QbucXLcnjbyzNSNsulekOFSTjM6j9nPYQAZcS6cCAwEAAQ==\
-----END RSA PUBLIC KEY-----";
