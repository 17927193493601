import React, { useEffect, useState } from "react";
import AddPropertyButton from "./attributes/addAttributeButton";
import { RootState } from "../../redux/reducers";
import { connect, ConnectedProps } from "react-redux";
import ConnectedAttribute from "./attributes/attribute";
import { JsonNode } from "../../db/jsonNode";
import { setHighlight } from "../../redux/actions";
import QuestionsForm from "./attributes/questionForm";
import AddAttributeForm from "./attributes/addAttributeForm";
import { Button, Grid } from "@mui/material";
import { OtherTypesIDS } from "common";

export type Props = PropsFromRedux & {
  indexSoFar: string;
  path: string;
};

function JsonVisualElement(props: Props) {
  const [currNode, setCurrNode] = useState(null as JsonNode | null);
  const [children, setChildren] = useState([] as string[]);
  const [nextChildrenToken, setNextChildrenToken] = useState("");
  const [doneLoadingNextChildren, setDoneLoadingNextChildren] = useState(true);
  const [calculatedList, setCalculatedList] = useState(null as JSX.Element[] | null);
  const [showQuestionsForm, setShowQuestionsForm] = useState(false);
  const [showNewAttributeForm, setShowNewAttributeForm] = useState(false);

  useEffect(() => {
    if (!props.holder) {
      setCurrNode(null);
      return;
    }
    props.holder.getDoc(props.path).then((doc) => {
      setCurrNode(doc);
      if (!doc) return;
      loadMore(doc, "");
      props.holder?.getNodesCache().observe(doc?.id || "", observeFunction);
    });
  }, [props.holder]);

  const loadMore = (doc: JsonNode | null, nextToken: string) => {
    if (!doc) return;
    props.holder?.loadMoreChildren(doc.id, nextToken).then((nextToken) => {
      if (!nextToken.length) {
        setDoneLoadingNextChildren(true);
      } else {
        setNextChildrenToken(nextToken);
        setDoneLoadingNextChildren(false);
      }
      props.holder?.getCachedChildren(doc.id).then((childrenIds) => {
        setChildren(childrenIds);
      });
    });
  };

  const observeFunction = () => {
    props.holder?.getDoc(props.path).then((doc2) => {
      setCurrNode(doc2);
      loadMore(doc2, nextChildrenToken);
    });
  };

  useEffect(() => {
    setCalculatedList(calculateList(currNode));
  }, [children?.length]);

  const addNewDocPath = async (typeId: string, path: string) => {
    const type = props.holder?.getDocTypePerId(typeId);
    if (!type) {
      return;
    }
    if (typeId === OtherTypesIDS.QUESTION) {
      setShowQuestionsForm(true);
      return;
    }

    const latestName = "Untitled";
    let counter = 0;
    let newName: string = latestName;

    let result = await props.holder!.hasDocNameUnderDocPath(path, newName);
    while (result && counter < 10) {
      counter++;
      newName = latestName + " (" + counter + ")";
      result = await props.holder!.hasDocNameUnderDocPath(path, newName);
    }

    const newPath = await props.holder!.appendDocValueToPath(path, type);
    props.setHighlight(newPath);
  };

  const addEmptyValueToArray = async (typeId: string, path: string) => {
    const type = props.holder?.getDocTypePerId(typeId);
    if (!type) {
      return;
    }
    if (typeId === OtherTypesIDS.QUESTION) {
      setShowQuestionsForm(true);
      return;
    }

    const newPath = await props.holder!.appendDocValueToPath(path, type);
    props.setHighlight(newPath);
  };

  const calculateList = (currNode: JsonNode | null): JSX.Element[] => {
    if (!currNode?.type) return [];
    if (!currNode.type.isArray && !currNode.type.isObject) return [];
    if (!children?.length) return [];

    return children.map((child, index) => {
      const newIndexSoFar = index + 1 + "";
      return <ConnectedAttribute indexSoFar={newIndexSoFar} key={newIndexSoFar + child} path={child} />;
    });
  };

  const addType = async (typeId: string, path: string) => {
    return currNode?.type.isArray ? await addEmptyValueToArray(typeId, path) : await addNewDocPath(typeId, path);
  };

  const addAttributeForm = () => {
    return (
      <AddAttributeForm
        key={"attribute_" + currNode?.id}
        show={showNewAttributeForm}
        rootPath={props.path}
        parentNode={currNode as JsonNode}
        docHolder={props.holder!}
        onHide={() => setShowNewAttributeForm(false)}
        handleClose={() => setShowNewAttributeForm(false)}
        handleSubmit={async (typeId: string, path: string) => {
          await addType(typeId, path);
          setShowNewAttributeForm(false);
        }}
      ></AddAttributeForm>
    );
  };

  return (
    <>
      <Grid
        container
        className="jsonvisual-container"
        sx={{
          paddingLeft: {
            xs: 0,
            sm: 0,
            md: 2,
            lg: 2,
            xl: 2,
          },
          paddingRight: {
            xs: 0,
            sm: 0,
            md: 2,
            lg: 1,
            xl: 1,
          },
          width: "100%",
        }}
      >
        {calculatedList}
        {!doneLoadingNextChildren && <Button onClick={() => loadMore(currNode, nextChildrenToken)}>Load more</Button>}
        <AddPropertyButton key={"addButton_" + props.indexSoFar} holder={props.holder!} clicked={() => setShowNewAttributeForm(true)}></AddPropertyButton>
        <QuestionsForm
          show={showQuestionsForm}
          rootPath={props.path}
          docHolder={props.holder!}
          onHide={() => {}}
          handleClose={() => {
            setShowQuestionsForm(false);
          }}
          handleSubmit={() => {
            setShowQuestionsForm(false);
          }}
        ></QuestionsForm>
      </Grid>
      {currNode && addAttributeForm()}
    </>
  );
}

const mapState = (state: RootState) => ({
  holder: state.jsonholder.holder,
});

const mapDispatch = {
  setHighlight,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedJsonVisualElement = connector(JsonVisualElement);

export default ConnectedJsonVisualElement;
