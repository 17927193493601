import { TextField } from "@mui/material";

export type Props = {
  value: string;
  style: any;
  updateValue: (event: any) => Promise<void>;
  validationError: string;
  disabled?: boolean;
};
function PhoneValue(props: Props) {
  const onChange = (event: any) => {
    const newVal = event?.target?.value;
    props.updateValue(newVal);
  };

  return (
    <TextField
      disabled={props.disabled}
      id="phone"
      variant="standard"
      fullWidth
      style={{ ...props.style }}
      value={props.value}
      error={!!props.validationError.length}
      helperText={props.validationError}
      onChange={onChange}
      placeholder="+"
    />
  );
}
export default PhoneValue;
