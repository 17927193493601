import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import "./attribute.css";

function AddPropertyButton(props: any) {
  const clicked = props.clicked;

  return (
    <>
      <Button className="add-btn" variant="contained" aria-label="add property" onClick={clicked} size="large" style={{ marginBottom: "5px" }}>
        <AddIcon sx={{ marginLeft: "0px", marginRight: "0px", padding: "0px", minWidth: "50px" }} />
      </Button>
    </>
  );
}

export default AddPropertyButton;
