import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
//import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import { store } from "./redux/store";
import { Provider as ReduxProvider } from "react-redux";

if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
  console.info = () => {};
  console.warn = () => {};
}

ReactDOM.render(
  <ReduxProvider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ReduxProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
