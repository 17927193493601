import { ParserIds } from "../components/holder/typesparsers/parserIds";
import { DocType } from "./docType";
import { OtherTypesIDS, PrimitiveTypesIDS } from "common";

/* 
  When you update this list, please also update the list here:
  amplify/backend/function/userslambda/src/converters/typesMap.ts
 */
const ALL_TYPES: DocType[] = [
  {
    id: PrimitiveTypesIDS.TEXT,
    display: "Text",
    icon: "text",
    parent: "",
    parser: ParserIds.STRING,
    autoSplit: false,
    isPrimitive: true,
    isArray: false,
    isObject: false,
    params: {},
    subTypes: [],
  },
  {
    id: PrimitiveTypesIDS.BOOL,
    display: "True/False",
    icon: "boolean",
    parent: "",
    parser: ParserIds.BOOLEAN,
    autoSplit: false,
    isPrimitive: true,
    isArray: false,
    isObject: false,
    params: {},
    subTypes: [],
  },
  {
    id: PrimitiveTypesIDS.NUMBER,
    display: "Number",
    icon: "numbers",
    parent: "",
    parser: ParserIds.NUMBER,
    autoSplit: false,
    isPrimitive: true,
    isArray: false,
    isObject: false,
    params: {},
    subTypes: [],
  },
  {
    id: PrimitiveTypesIDS.ARRAY,
    display: "Collection",
    icon: "array",
    parent: "",
    parser: ParserIds.ARRAY,
    autoSplit: true,
    isPrimitive: true,
    isArray: true,
    isObject: false,
    params: {},
    subTypes: [],
  },
  {
    id: PrimitiveTypesIDS.OBJECT,
    display: "Object",
    icon: "object",
    parent: "",
    parser: ParserIds.OBJECT,
    autoSplit: true,
    isPrimitive: true,
    isArray: false,
    isObject: true,
    params: {},
    subTypes: [],
  },
  {
    id: OtherTypesIDS.POSITIVE_NUM,
    display: "Positive Number",
    icon: "numbersPos",
    parent: PrimitiveTypesIDS.NUMBER,
    parser: ParserIds.NUMBER,
    autoSplit: false,
    isPrimitive: false,
    isArray: false,
    isObject: false,
    params: { min: 0 },
    subTypes: [],
  },
  {
    id: OtherTypesIDS.URL,
    display: "Link",
    icon: "url",
    parent: PrimitiveTypesIDS.TEXT,
    parser: ParserIds.URL,
    autoSplit: false,
    isPrimitive: false,
    isArray: false,
    isObject: false,
    params: {},
    subTypes: [],
  },
  {
    id: OtherTypesIDS.IMAGE,
    display: "Image",
    icon: "image",
    parent: PrimitiveTypesIDS.TEXT,
    parser: ParserIds.URL,
    autoSplit: false,
    isPrimitive: false,
    isArray: false,
    isObject: false,
    params: {},
    subTypes: [],
  },
  {
    id: OtherTypesIDS.COLOR,
    display: "Color",
    icon: "color",
    parent: PrimitiveTypesIDS.TEXT,
    parser: ParserIds.COLOR,
    autoSplit: false,
    isPrimitive: false,
    isArray: false,
    isObject: false,
    params: {},
    subTypes: [],
  },
  {
    id: OtherTypesIDS.HTML,
    display: "HTML",
    icon: "html",
    parent: PrimitiveTypesIDS.TEXT,
    parser: ParserIds.STRING,
    autoSplit: true,
    isPrimitive: false,
    isArray: false,
    isObject: false,
    params: {},
    subTypes: [],
  },
  {
    id: OtherTypesIDS.QUESTION,
    display: "Question",
    icon: "question",
    parent: PrimitiveTypesIDS.OBJECT,
    parser: ParserIds.OBJECT,
    autoSplit: true,
    isPrimitive: false,
    isArray: false,
    isObject: true,
    params: {},
    subTypes: [],
  },
  {
    id: OtherTypesIDS.PHONENUMBER,
    display: "Phone",
    icon: "phone",
    parent: PrimitiveTypesIDS.TEXT,
    parser: ParserIds.PHONE,
    autoSplit: false,
    isPrimitive: false,
    isArray: false,
    isObject: false,
    params: {},
    subTypes: [],
  },
  {
    id: OtherTypesIDS.DATE,
    display: "Date",
    icon: "date",
    parent: PrimitiveTypesIDS.TEXT,
    parser: ParserIds.DATE,
    autoSplit: false,
    isPrimitive: false,
    isArray: false,
    isObject: false,
    params: {},
    subTypes: [],
  },
  {
    id: OtherTypesIDS.DATEANDTIME,
    display: "Date & Time",
    icon: "time",
    parent: OtherTypesIDS.DATE,
    parser: ParserIds.DATE,
    autoSplit: false,
    isPrimitive: false,
    isArray: false,
    isObject: false,
    params: {},
    subTypes: [],
  },
];

const TYPES_WITH_SUBTYPES = ALL_TYPES.map((type) => {
  const currTypeChildren = ALL_TYPES.filter((currType) => currType.parent === type.id).map((currType) => currType.id);
  if (currTypeChildren?.length) {
    return { ...type, subTypes: currTypeChildren };
  }
  return type;
});

export default TYPES_WITH_SUBTYPES;
