import { ConnectedProps, connect } from "react-redux";
import { setHighlight, setOpenNode, unsetOpenNode } from "../../../redux/actions";
import { RootState } from "../../../redux/reducers";
import { IconButton, Tooltip } from "@mui/material";
import RemovePropertyButton from "./removePropertyButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useEffect, useState } from "react";
import { JsonNode } from "../../../db/jsonNode";

export type Props = {
  displayName: string;
} & PropsFromRedux;

function AttributeCommands(props: Props) {
  const [updating, setUpdating] = useState(false);
  const [currDoc, setCurrDoc] = useState(null as JsonNode | null);

  useEffect(() => {
    props?.holder?.getDoc(props.selectedHighlight).then((doc) => {
      setCurrDoc(doc);
    });
  }, [props.selectedHighlight]);

  const removeAttribute = async () => {
    if (updating) return;
    if (!props.selectedHighlight || props.selectedHighlight?.length < 3) return;
    if (!currDoc) return;
    await props.holder!.removeDocPath(props.selectedHighlight);
    props.setHighlight("");
  };
  const undoChanges = async (name: string, value: any) => {
    if (updating) return;
    if (!props.selectedHighlight || props.selectedHighlight?.length < 3) return;
    if (!currDoc) return;
    await props.holder!.setDocValueAtPath(props.selectedHighlight, currDoc.type, value);
    await props.holder!.setDocNameAtPath(props.selectedHighlight, name);

    props.setHighlight(props.selectedHighlight);
    return;
  };

  const duplicateAttribute = async () => {
    if (updating) return;
    if (!props.selectedHighlight || props.selectedHighlight?.length < 3) return;
    setUpdating(true);
    await props?.holder?.duplicateAttribute(props.selectedHighlight).then(() => setUpdating(false));
  };

  return (
    <>
      <Tooltip title="Duplicate" sx={{ marginLeft: "auto" }}>
        <IconButton onClick={duplicateAttribute}>
          <ContentCopyIcon sx={{ color: updating ? "gray" : "black" }} />
        </IconButton>
      </Tooltip>
      <RemovePropertyButton holder={props.holder!} propertyId={props.selectedHighlight} propertyName={props.displayName} removeFunction={removeAttribute} />
    </>
  );
}

const mapState = (state: RootState) => ({
  selectedHighlight: state.highlight.selectedHighlight,
  holder: state.jsonholder.holder,
  openNodes: state.opennodes.openNodes,
});

const mapDispatch = { setHighlight, unsetOpenNode, setOpenNode };

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedAttributeCommands = connector(AttributeCommands);
export default ConnectedAttributeCommands;
