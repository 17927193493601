import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import { SketchPicker } from "react-color";
import { isValidColorStr } from "../../../holder/typesparsers/colorParser";

const colorBoxStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px #000",
  borderRadius: "6px",
  boxShadow: 24,
  p: 1,
};
export type Props = {
  value: string;
  updateValue: (color: any) => void;
  toggleShowColorPicker: () => void;
  displayColorPicker: boolean;
  tempColor: string;
  closeColorPicker: () => void;
  disabled?: boolean;
};

function ColorValue(props: Props) {
  const currColor = isValidColorStr(props.value) ? props.value : "#FFFFFF";

  return (
    <div className="d-inline-flex">
      <div className="d-column-flex">
        <div className="atr-val-color-picker-text" onClick={props.disabled ? () => {} : props.toggleShowColorPicker}>
          {currColor}
        </div>
        <Modal open={props.disabled ? false : props.displayColorPicker} onClose={props.toggleShowColorPicker}>
          <Box sx={colorBoxStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Select color
            </Typography>
            <SketchPicker color={props.tempColor} onChange={props.updateValue} />
            <Stack spacing={2} direction="row">
              <Button variant="outlined" onClick={props.toggleShowColorPicker} style={{ marginTop: "15px" }}>
                Cancel
              </Button>
              <Button variant="contained" onClick={props.closeColorPicker} style={{ marginTop: "15px" }}>
                Select
              </Button>
            </Stack>
          </Box>
        </Modal>
      </div>
      <div
        onClick={props.toggleShowColorPicker}
        className="atr-val-color-picker-box"
        style={{
          background: currColor,
        }}
      ></div>
    </div>
  );
}
export default ColorValue;
