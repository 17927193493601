import { combineReducers } from "redux";
import highlight from "./highlightReducer";
import jsonholder from "./jsonHolderReducer";
import toggle from "./toggleReducer";
import opennodes from "./openNodesReducer";
import user from "./userReducer";

const rootReducer = combineReducers({
  highlight,
  jsonholder,
  toggle,
  opennodes,
  user,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
