import { Button, CircularProgress, Grid, TextField } from "@mui/material";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
export type AddFolderFormProps = {
  show: boolean;
  onHide: any;
  handleClose: any;
  handleSubmit: any;
  isInvalid: any;
};

function AddFolderForm(props: AddFolderFormProps) {
  const [newName, setNewName] = useState("");
  const [validated, setValidated] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  function updateNewFolderName(event: any) {
    const newValue = event?.target?.value;
    setNewName(newValue);
  }

  function handleSubmit() {
    setValidated(true);
    if (!props.isInvalid(newName)) {
      setIsUpdating(true);
      props.handleSubmit(newName).then(() => {
        setNewName("");
        setValidated(false);
        setIsUpdating(false);
      });
    }
  }

  return (
    <Dialog open={props.show} onClose={props.handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>New folder</DialogTitle>
      <DialogContent>
        {isUpdating ? (
          <Grid container flex="1" flexDirection="row" spacing={2} sx={{ paddingTop: "18px" }}>
            <Grid item xs={12} justifyContent="center" sx={{ padding: "2rem", display: "flex", alignItems: "center" }}>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <>
            <DialogContentText>Create new folder</DialogContentText>
            <TextField
              label="Folder name:"
              id="foldername"
              size="small"
              placeholder=""
              value={newName}
              onChange={updateNewFolderName}
              error={validated && props.isInvalid(newName)}
              helperText="required field"
              required
              fullWidth
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            props.handleClose();
            setNewName("");
            setValidated(false);
          }}
          style={{ marginTop: "15px" }}
        >
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit} style={{ marginTop: "15px" }}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddFolderForm;
