import { Box, Button, CircularProgress, Grid, Modal, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export type RenameFileFormProps = {
  fileId: string;
  show: boolean;
  handleClose: () => void;
  handleSubmit: (fileId: string, newFileName: string) => Promise<void>;
  isInvalid: (newfileName: string) => boolean;
  type: string;
};

function RenameForm(props: RenameFileFormProps) {
  const [newFileName, setNewFileName] = useState("");
  const [validated, setValidated] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const questionBoxStyle = {
    position: "absolute" as "absolute",
    top: "40%",
    left: "40%",
    bgcolor: "background.paper",
    border: "2px #000",
    borderRadius: "6px",
    boxShadow: 24,
    minWidth: "20%",
    p: 3,
  };

  function updateNewFileName(event: any) {
    const newValue = event?.target?.value;
    setNewFileName(newValue);
  }

  function handleSubmit() {
    setValidated(true);
    if (!props.isInvalid(newFileName)) {
      setIsUpdating(true);
      props.handleSubmit(props.fileId, newFileName).then(() => {
        setNewFileName("");
        setValidated(false);
        setIsUpdating(false);
      });
    }
  }

  return (
    <>
      <Dialog open={props.show} onClose={props.handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Rename {props.type}</DialogTitle>
        <DialogContent>
          {isUpdating ? (
            <Grid container flex="1" flexDirection="row" spacing={2} sx={{ paddingTop: "18px" }}>
              <Grid item xs={12} justifyContent="center" sx={{ padding: "2rem", display: "flex", alignItems: "center" }}>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            <>
              <DialogContentText>Please enter the new name:</DialogContentText>
              <TextField
                label={`${props.type} name`}
                id="filename"
                size="small"
                placeholder={`${props.type}name.json`}
                value={newFileName}
                onChange={updateNewFileName}
                error={validated && props.isInvalid(newFileName)}
                helperText="required field"
                required
                fullWidth
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              props.handleClose();
              setNewFileName("");
              setValidated(false);
            }}
            style={{ marginTop: "15px" }}
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit} style={{ marginTop: "15px" }}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default RenameForm;
