import React from "react";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";

export type Props = {
  propertyName: string;
  show: boolean;
  onHide: any;
  handleClose: any;
  handleSubmit: any;
};

function DeleteAttributeForm(props: Props) {
  return (
    <Dialog open={props.show} onClose={props.onHide} aria-labelledby="delete-attribute-dialog" aria-describedby="delete-attribute-dialog">
      <DialogTitle id="delete-attribute-dialog-title">Are you sure you want to remove {props.propertyName}?</DialogTitle>
      <DialogActions>
        <Button variant="outlined" onClick={props.handleClose}>
          Cancel
        </Button>
        <Button autoFocus variant="contained" onClick={async () => await props.handleSubmit()}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteAttributeForm;
