export enum Types {
  SET_HIGHLIGHT = "SET_HIGHLIGHT",
  SET_TREE_MAP = "SET_TREE_MAP",
  SET_JSON_HOLDER = "SET_JSON_HOLDER",
  TOGGLE = "TOGGLE",
  SET_OPEN_NODE = "SET_OPEN_NODE",
  ADD_OPEN_NODES = "ADD_OPEN_NODES",
  UNSET_OPEN_NODE = "UNSET_OPEN_NODE",
  SET_USER_INFO = "SET_USER_INFO",
  SET_ACTIVE_FILE = "SET_ACTIVE_FILE",
  SET_USER_LAST_LOGIN = "SET_USER_LAST_LOGIN",
}
