import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PublicIcon from "@mui/icons-material/Public";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../redux/reducers";
import { Alert, Snackbar, Tooltip, Typography } from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import { useEffect, useState } from "react";
import { setHighlight } from "../../redux/actions/";
import RemovePropertyButton from "./attributes/removePropertyButton";
import AttributeHistoryForm from "./attributes/isModifyAttributeButton";
import PreviewIcon from "@mui/icons-material/Preview";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SaveIcon from "@mui/icons-material/Save";
import { authenticationClient } from "../../App";
export type HeaderProps = {
  setShowJsonForm: (open: boolean) => void;
  setShowPublishForm: (open: boolean) => void;
  fileId: string;
  rootPath: string;
  fileName: string;
} & PropsFromRedux;

function AttributeHeader(props: HeaderProps) {
  const [isModified, setIsModified] = useState(false);
  const [saving, setSaving] = useState(false);
  const [showSaveSnackbar, setShowSaveSnackbar] = useState(false);
  const [saveError, setSaveError] = useState("");

  useEffect(() => {
    if (props.holder) {
      props.holder.getNodesCache().generalObserve(() => {
        setIsModified(props.holder?.getNodesCache().hasUnsavedChanges() || false);
      });
    }
  }, [props.holder]);

  const onClickSave = (event: any) => {
    setSaving(true);

    props.holder?.persist().then((res) => {
      if (!res?.length) {
        setShowSaveSnackbar(true);
        setSaving(false);
        setIsModified(false);
        return;
      }
      setSaveError(res[0].error);
      setShowSaveSnackbar(true);
      setSaving(false);

      return;
    });
  };

  const onClickDownload = (event: any) => {
    if (saving) return;
    authenticationClient.getFullJson(props.fileId, props.rootPath).then((res) => {
      if (res) {
        var blob = new Blob([JSON.stringify(res?.json, null, 2)], {
          type: "application/json;charset=utf8;",
        });
        var element = document.createElement("a");
        document.body.appendChild(element);
        element.setAttribute("href", window.URL.createObjectURL(blob));
        element.setAttribute("download", `${props.fileName}.json`);
        element.style.display = "";
        element.click();
        document.body.removeChild(element);
        event.stopPropagation();
      }
    });
  };

  const handleCloseSnackbar = () => {
    setShowSaveSnackbar(false);
    setSaveError("");
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" style={{ background: "#DFDFDF", color: "black" }}>
          <Snackbar
            open={showSaveSnackbar}
            autoHideDuration={saveError?.length ? 60000 : 3000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          >
            <Alert onClose={handleCloseSnackbar} variant="filled" severity={saveError?.length ? "error" : "success"} sx={{ width: "100%" }}>
              {saveError?.length ? saveError : "Saved successfully"}
            </Alert>
          </Snackbar>
          <Toolbar sx={{ justifyContent: "left" }}>
            <Typography sx={{ paddingRight: "5px" }}>
              <strong>{props.fileName} </strong>
            </Typography>
            <Tooltip title="Sync changes">
              <IconButton onClick={onClickSave}>
                <SaveIcon sx={{ color: isModified ? "red" : "black" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Download Json">
              <IconButton onClick={onClickDownload}>
                <FileDownloadIcon sx={{ color: saving ? "gray" : "black" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Show Preview Json">
              <IconButton
                onClick={() => {
                  if (saving) return;
                  props.setShowJsonForm(true);
                }}
              >
                <PreviewIcon sx={{ color: saving ? "gray" : "black" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Publish version">
              <IconButton
                onClick={() => {
                  if (saving) return;
                  props.setShowPublishForm(true);
                }}
              >
                <PublicIcon sx={{ color: saving ? "gray" : "black" }} />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
}

const mapState = (state: RootState) => ({
  selectedHighlight: state.highlight.selectedHighlight,
  holder: state.jsonholder.holder,
});

const mapDispatch = { setHighlight };

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedAttributeHeader = connector(AttributeHeader);
export default ConnectedAttributeHeader;
