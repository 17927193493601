import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import MoreIcon from "@mui/icons-material/MoreVert";
import TextRotateVerticalIcon from "@mui/icons-material/TextRotateVertical";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Button, Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.4),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.8),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export type HeaderProps = {
  sortByCreated: (order: boolean) => void;
  sortByName: (order: boolean) => void;
  handleBack: () => void;
  currentFolderName: string;
  typeOfFolder: string;
};

export default function FilesHeader(props: HeaderProps) {
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const iconsForTypeOfFolder = [
    {
      id: "1",
      name: "Shared Files",
      button: (
        <Button sx={{ color: "black" }}>
          <FolderSharedIcon fontSize="large" />
        </Button>
      ),
    },
    {
      id: "2",
      name: "Home Page",
      button: (
        <Button sx={{ color: "black" }}>
          <HomeIcon fontSize="large" />
        </Button>
      ),
    },
    {
      id: "3",
      name: props.currentFolderName,
      button: (
        <Button sx={{ color: "black" }} onClick={props.handleBack}>
          <ArrowBackIcon fontSize="large" />
        </Button>
      ),
    },
    {
      id: "4",
      name: "Trash",
      button: (
        <Button sx={{ color: "black" }}>
          <DeleteIcon fontSize="large" />
        </Button>
      ),
    },
  ];
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={() => props.sortByName(true)}>
        <TextRotateVerticalIcon />
        Sort by name
        <ArrowDownwardIcon />
      </MenuItem>
      <MenuItem onClick={() => props.sortByName(false)}>
        <TextRotateVerticalIcon />
        Sort by name
        <ArrowUpwardIcon />
      </MenuItem>
      <MenuItem onClick={() => props.sortByCreated(true)}>
        <RestoreRoundedIcon />
        Sort by time created
        <ArrowDownwardIcon />
      </MenuItem>
      <MenuItem onClick={() => props.sortByCreated(false)}>
        <RestoreRoundedIcon />
        Sort by time created
        <ArrowUpwardIcon />
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={{ background: "#DFDFDF", color: "black" }}>
        <Toolbar>
          {iconsForTypeOfFolder.find((typeOfFolder: any) => props.typeOfFolder === typeOfFolder.id)!.button}

          <Typography fontSize="20px">
            <strong>{iconsForTypeOfFolder.find((typeOfFolder: any) => props.typeOfFolder === typeOfFolder.id)!.name}</strong>
          </Typography>
          <Box sx={{ marginLeft: "15px", flexGrow: 7.85 }}>
            {/* <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase placeholder="Search…" inputProps={{ "aria-label": "search" }} />
            </Search> */}
          </Box>
          <Box sx={{ flexGrow: 1 }} />

          <IconButton size="large" aria-label="show more" aria-controls={mobileMenuId} aria-haspopup="true" onClick={handleMobileMenuOpen} color="inherit">
            <MoreIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </Box>
  );
}
