import React, { useEffect, useState } from "react";
import "codemirror/lib/codemirror.css";
import "./codemirror/itsjson.css";
import "codemirror/keymap/sublime";
import "codemirror/mode/javascript/javascript";
import styled from "styled-components";
import { Controlled as CodeMirror } from "react-codemirror2";
import { authenticationClient } from "../../App";
import { setJsonHolder } from "../../redux/actions";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../redux/reducers";
const Wrapper: any = styled.div`
  width: 100%;
  .CodeMirror {
    min-height: 60vh;
    height: auto;
  }
  .CodeMirror-lines {
    @media only screen and (max-width: 100%) {
      font-size: 11px;
      height: auto;
    }
  }
  .CodeMirror pre.CodeMirror-line {
    text-align: left;
    height: auto;
  }
  .CodeMirror-scroll {
    min-height: 60vh;
    height: auto;
  }
`;

export type Props = PropsFromRedux & {
  fileId: string;
  rootPath: string;
};

function JsonFormInput(props: Props) {
  const [jsonValue, setJsonValue] = useState("{}");

  useEffect(() => {
    handleLoadJson(props.fileId, props.rootPath);
  }, []);

  function handleLoadJson(fileId: string, rootPath: string) {
    authenticationClient.getFullJson(fileId, rootPath).then((res) => {
      if (res) {
        setJsonValue(JSON.stringify(res?.json, null, 2));
      }
    });
  }
  return (
    <div className="jsonforminput-container">
      <Wrapper style={{ height: "100%" }}>
        <CodeMirror
          value={jsonValue}
          options={{
            mode: "javascript",
            lineWrapping: true,
            smartIndent: true,
            lineNumbers: true,
            foldGutter: true,
            keyMap: "sublime",
            tabSize: 2,
            theme: "itsjson",
          }}
          onBeforeChange={(editor, data, value) => {
            const newJson = value;
            setJsonValue(newJson);
          }}
          onChange={(editor, data, value) => {}}
        />
      </Wrapper>
    </div>
  );
}

const mapState = (state: RootState) => ({
  holder: state.jsonholder.holder,
});

const mapDispatch = {
  setJsonHolder,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedJsonFormInput = connector(JsonFormInput);

export default ConnectedJsonFormInput;
