import { TextField } from "@mui/material";
export type Props = {
  value: string;
  updateValue: (event: any) => void;
  disabled?: boolean;
  style: any;
};
function DateValue(props: Props) {
  return (
    <TextField
      style={{ ...props.style }}
      disabled={props.disabled}
      id="date"
      type="date"
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        style: {
          ...props.style,
          padding: 5,
        },
      }}
      value={props.value}
      onChange={props.updateValue}
    />
  );
}
export default DateValue;
