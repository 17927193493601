import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";

import { getLabelByCountryCode } from "../../login/register/countries";
import Tooltip from "@mui/material/Tooltip";
import UserRecord from "../../../db/userRecord";

export type ProfileFormProps = {
  user?: UserRecord;
};
function ProfileForm(props: ProfileFormProps) {
  return (
    <>
      {!props?.user ? (
        <></>
      ) : (
        <>
          <Grid container direction="row" paddingTop="18px" marginBottom="18px" justifyContent="center">
            <Grid item md={6} xs={12}>
              <Typography id="modal-modal-title" variant="h4" component="h4" paddingLeft="15px">
                <strong>Profile info</strong>
              </Typography>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableBody>
                    <TableRow key={"Name"} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell component="th" scope="row" sx={{ fontSize: "20px" }}>
                        <strong>Name:</strong>
                      </TableCell>
                      <TableCell align="left" sx={{ fontSize: "20px" }}>
                        {`${props.user.firstName} ${props.user.lastName}`}
                      </TableCell>
                    </TableRow>
                    <TableRow key={"Email"} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell component="th" scope="row" sx={{ fontSize: "20px" }}>
                        <strong>Email:</strong>
                      </TableCell>
                      <TableCell align="left" sx={{ fontSize: "20px" }}>
                        {props.user.email}
                      </TableCell>
                    </TableRow>
                    <TableRow key={"Password"} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell component="th" scope="row" sx={{ fontSize: "20px" }}>
                        <strong>Password:</strong>
                      </TableCell>
                      <TableCell align="left" sx={{ fontSize: "20px" }}>
                        <Link to="/changepassword">change password</Link>
                      </TableCell>
                    </TableRow>
                    <TableRow key={"Country"} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell component="th" scope="row" sx={{ fontSize: "20px" }}>
                        <strong>Country:</strong>
                      </TableCell>
                      <TableCell align="left" sx={{ fontSize: "20px" }}>
                        {getLabelByCountryCode(props.user.countryCode || "")}&nbsp;
                        <img
                          loading="lazy"
                          width="30"
                          src={`https://flagcdn.com/w20/${props.user.countryCode!.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${props.user.countryCode!.toLowerCase()}.png 2x`}
                          alt=""
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow key={"Subscription"} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell component="th" scope="row" sx={{ fontSize: "20px" }}>
                        <strong>Subscription:</strong>
                      </TableCell>
                      <TableCell align="left" sx={{ fontSize: "20px" }}>
                        Free&nbsp;
                        <Tooltip title="Switching between the subscription profiles">
                          <Link to="/subscription">upgrade</Link>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Tooltip title="Erase all your data and files permanently">
                <Button variant="outlined" color="error" style={{ marginTop: "25px", width: "25ch" }}>
                  DELETE ACCOUNT
                </Button>
              </Tooltip>
            </Grid>
            <Grid item md={4} paddingTop="18px" marginLeft="50px" sx={{ display: { xs: "none", lg: "block" } }}>
              <img src={process.env.PUBLIC_URL + "/mainPage/simple_types.png"} loading="lazy" style={{ width: "80%", height: "auto" }} />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

export default ProfileForm;
