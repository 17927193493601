import AnyParser from "./anyParser";
import { ValidationResult } from "./parserIfc";

class StringParser extends AnyParser {
  constructor(private params: any, private subTypes: Set<string>) {
    super(params, subTypes);
  }
  parse(value: any): string {
    return "" + value;
  }
  validateAndParse(value: any): ValidationResult {
    if (value === null) {
      return {
        parsedValue: "",
        validationError: "null is not an acceptable value",
      };
    }
    return { parsedValue: this.parse(value), validationError: "" };
  }
}

export default StringParser;
