import SHA256 from "crypto-js/sha256";
import encHex from "crypto-js/enc-hex";
import HmacSHA256 from "crypto-js/hmac-sha256";

const AWS_SHA_256 = "AWS4-HMAC-SHA256";
const AWS4_REQUEST = "aws4_request";
const AWS4 = "AWS4";
const X_AMZ_DATE = "x-amz-date";
const X_AMZ_SECURITY_TOKEN = "x-amz-security-token";
const HOST = "host";
const AUTHORIZATION = "Authorization";

export type SignV4Config = {
  accessKey: string;
  secretKey: string;
  sessionToken: string;
  region: string;
  serviceName: string;
  defaultAcceptType: string;
  defaultContentType: string;
  endpoint: string;
  pathComponent: string;
};

class SignV4Client {
  constructor(private config: any) {}

  hash(value: any) {
    return SHA256(value); // eslint-disable-line
  }

  hexEncode(value: any) {
    return value.toString(encHex);
  }

  hmac(secret: string | CryptoJS.lib.WordArray, value: string | CryptoJS.lib.WordArray) {
    return HmacSHA256(value, secret); // eslint-disable-line
  }

  buildCanonicalRequest(method: string, path: string, queryParams: string[], headers: string[], payload: any) {
    return (
      method +
      "\n" +
      this.buildCanonicalUri(path) +
      "\n" +
      this.buildCanonicalQueryString(queryParams) +
      "\n" +
      this.buildCanonicalHeaders(headers) +
      "\n" +
      this.buildCanonicalSignedHeaders(headers) +
      "\n" +
      this.hexEncode(this.hash(payload))
    );
  }

  hashCanonicalRequest(request: any) {
    return this.hexEncode(this.hash(request));
  }

  buildCanonicalUri(uri: string) {
    return encodeURI(uri);
  }

  buildCanonicalQueryString(queryParams: string[]) {
    if (Object.keys(queryParams).length < 1) {
      return "";
    }

    let sortedQueryParams: any[] = [];
    for (let property in queryParams) {
      if (queryParams.hasOwnProperty(property)) {
        sortedQueryParams.push(property);
      }
    }
    sortedQueryParams.sort();

    let canonicalQueryString = "";
    for (let i = 0; i < sortedQueryParams.length; i++) {
      canonicalQueryString += sortedQueryParams[i] + "=" + encodeURIComponent(queryParams[sortedQueryParams[i]]) + "&";
    }
    return canonicalQueryString.substr(0, canonicalQueryString.length - 1);
  }

  buildCanonicalHeaders(headers: any) {
    let canonicalHeaders = "";
    let sortedKeys: any[] = [];
    for (let property in headers) {
      if (headers.hasOwnProperty(property)) {
        sortedKeys.push(property);
      }
    }
    sortedKeys.sort();

    for (let i = 0; i < sortedKeys.length; i++) {
      canonicalHeaders += sortedKeys[i].toLowerCase() + ":" + headers[sortedKeys[i]] + "\n";
    }
    return canonicalHeaders;
  }

  buildCanonicalSignedHeaders(headers: any) {
    let sortedKeys: any[] = [];
    for (let property in headers) {
      if (headers.hasOwnProperty(property)) {
        sortedKeys.push(property.toLowerCase());
      }
    }
    sortedKeys.sort();

    return sortedKeys.join(";");
  }

  buildStringToSign(datetime: string, credentialScope: string, hashedCanonicalRequest: string) {
    return AWS_SHA_256 + "\n" + datetime + "\n" + credentialScope + "\n" + hashedCanonicalRequest;
  }

  buildCredentialScope(datetime: string, region: string, service: string) {
    return datetime.substr(0, 8) + "/" + region + "/" + service + "/" + AWS4_REQUEST;
  }

  calculateSigningKey(secretKey: string, datetime: string, region: string, service: string) {
    return this.hmac(this.hmac(this.hmac(this.hmac(AWS4 + secretKey, datetime.substr(0, 8)), region), service), AWS4_REQUEST);
  }

  calculateSignature(key: string | CryptoJS.lib.WordArray, stringToSign: string | CryptoJS.lib.WordArray) {
    return this.hexEncode(this.hmac(key, stringToSign));
  }

  extractHostname(url: string) {
    var hostname;

    if (url.indexOf("://") > -1) {
      hostname = url.split("/")[2];
    } else {
      hostname = url.split("/")[0];
    }

    hostname = hostname.split(":")[0];
    hostname = hostname.split("?")[0];

    return hostname;
  }

  buildAuthorizationHeader(accessKey: string, credentialScope: string, headers: any, signature: string) {
    return (
      AWS_SHA_256 +
      " Credential=" +
      accessKey +
      "/" +
      credentialScope +
      ", SignedHeaders=" +
      this.buildCanonicalSignedHeaders(headers) +
      ", Signature=" +
      signature
    );
  }

  signRequest(request: SigningRequest) {
    const verb = request.method.toUpperCase();
    const path = this.config.pathComponent + request.path;
    const queryParams = { ...request.queryParams };
    const headers = { ...request.headers };

    // If the user has not specified an override for Content type the use default
    if (headers["Content-Type"] === undefined) {
      headers["Content-Type"] = this.config.defaultContentType;
    }

    // If the user has not specified an override for Accept type the use default
    if (headers["Accept"] === undefined) {
      headers["Accept"] = this.config.defaultAcceptType;
    }

    let body = { ...request.body };
    // override request body and set to empty when signing GET requests
    if (request.body === undefined || verb === "GET") {
      body = "";
    } else {
      body = JSON.stringify(body);
    }

    // If there is no body remove the content-type header so it is not
    // included in SigV4 calculation
    if (body === "" || body === undefined || body === null) {
      delete headers["Content-Type"];
    }

    let datetime = new Date()
      .toISOString()
      .replace(/\.\d{3}Z$/, "Z")
      .replace(/[:-]|\.\d{3}/g, "");
    headers[X_AMZ_DATE] = datetime;
    headers[HOST] = this.extractHostname(this.config.endpoint);

    let canonicalRequest = this.buildCanonicalRequest(verb, path, queryParams, headers, body);
    let hashedCanonicalRequest = this.hashCanonicalRequest(canonicalRequest);
    let credentialScope = this.buildCredentialScope(datetime, this.config.region, this.config.serviceName);
    let stringToSign = this.buildStringToSign(datetime, credentialScope, hashedCanonicalRequest);
    let signingKey = this.calculateSigningKey(this.config.secretKey, datetime, this.config.region, this.config.serviceName);
    let signature = this.calculateSignature(signingKey, stringToSign);
    headers[AUTHORIZATION] = this.buildAuthorizationHeader(this.config.accessKey, credentialScope, headers, signature);
    if (this.config.sessionToken?.length) {
      headers[X_AMZ_SECURITY_TOKEN] = this.config.sessionToken;
    }
    delete headers[HOST];

    let url = this.config.endpoint + path;
    let queryString = this.buildCanonicalQueryString(queryParams);
    if (queryString !== "") {
      url += "?" + queryString;
    }

    // Need to re-attach Content-Type if it is not specified at this point
    if (headers["Content-Type"] === undefined) {
      headers["Content-Type"] = this.config.defaultContentType;
    }

    return {
      headers: headers,
      url: url,
    };
  }
}

export type SigningConfig = {
  accessKey: string;
  secretKey: string;
  sessionToken: string;
  region: string;
  endpointUrl: string;
  serviceName?: string;
};

export type SigningRequest = {
  method: string;
  path: string;
  headers?: any;
  queryParams?: any;
  body?: any;
};

export function sign(config: SigningConfig, request: SigningRequest) {
  const invokeUrl = config.endpointUrl;
  const endpoint = /(^https?:\/\/[^/]+)/g.exec(invokeUrl)?.[1];
  if (!endpoint?.length) {
    throw new Error("invokeUrl didn't match the pattern, endpoint couldn't be found in it");
  }
  const pathComponent = invokeUrl.substring(endpoint.length);

  let newConfig: SignV4Config = {
    accessKey: config.accessKey,
    secretKey: config.secretKey,
    sessionToken: config.sessionToken,
    region: config.region,
    serviceName: config.serviceName || "execute-api",
    defaultAcceptType: "application/json",
    defaultContentType: "application/json",
    endpoint,
    pathComponent,
  };

  const signingClient = new SignV4Client(newConfig);
  return signingClient.signRequest(request);
}

export default sign;
