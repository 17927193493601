import { Button, CircularProgress, FormControl, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { authenticationClient } from "../../../App";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FilePermissionModificationType, FilePermissionType, GetFilePermissionEntry, GetFileResponse, PutFilePermissionEntry } from "common";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import * as EmailValidator from "email-validator";
export type AddFileFormProps = {
  show: boolean;
  handleClose: () => void;
  handleSubmit: (updateUsersPermission: PutFilePermissionEntry[]) => Promise<void>;
  isInvalid: any;
  type: string;
  fileId: string;
};

function ShareForm(props: AddFileFormProps) {
  const [newEmailToShareFile, setNewEmailToShareFile] = useState("");
  const [validatedEmail, setValidatedEmail] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [fileInfo, setFileInfo] = useState(null as GetFileResponse | null);
  const [receivedPermissions, setReceivedPermissions] = useState([] as GetFilePermissionEntry[]);
  const [modifications, setModifications] = useState([] as PutFilePermissionEntry[]);

  useEffect(() => {
    if (!props.fileId) {
      return;
    }

    authenticationClient.getFileInfo(props.fileId).then((currFileInfo) => {
      setFileInfo(currFileInfo);
    });
    authenticationClient.getFilePermissions(props.fileId).then((permissions) => {
      setReceivedPermissions(permissions || []);
    });
  }, [props.fileId]);

  function addModification(email64: string, newPermission: FilePermissionModificationType) {
    const newModification: PutFilePermissionEntry = { email64, newPermission };
    const oldPermission = receivedPermissions.find((currPermission) => currPermission.email64 === email64);

    // filtering out undos and empty changes
    if (oldPermission && newPermission === convertEnums(oldPermission.permission)) {
      const newModifications = modifications.filter((modification) => modification.email64 === email64);
      setModifications(newModifications);
      return;
    }
    const alreadyExistingModification = modifications.find((modification) => modification.email64 === email64);
    if (alreadyExistingModification) {
      alreadyExistingModification.newPermission = newPermission;
      setModifications([...modifications]);
      return;
    }
    const newModifications = [...modifications, newModification];
    setModifications(newModifications);
  }

  function addNewUser(email: string) {
    if (!!newEmailToShareFile.length && EmailValidator.validate(newEmailToShareFile)) {
      const email64 = Buffer.from(email).toString("base64");
      if (receivedPermissions.findIndex((currPermission) => currPermission.email64 === email64) > -1) {
        setValidatedEmail(true);
        return;
      }
      addModification(email64, FilePermissionModificationType.READ);
      setValidatedEmail(false);
    } else {
      setValidatedEmail(true);
    }
    return;
  }
  function updateNewEmail(event: any) {
    const newValue = event?.target?.value;
    setNewEmailToShareFile(newValue);
    setValidatedEmail(false);
  }

  function handleSubmit() {
    setIsUpdating(true);
    props.handleSubmit(modifications).then(() => {
      setNewEmailToShareFile("");
      setIsUpdating(false);
    });
  }

  function getOwner() {
    return receivedPermissions.find((permission) => permission.permission === FilePermissionType.OWNER);
  }

  function convertEnums(type: FilePermissionType) {
    if (type === FilePermissionType.EDITOR) return FilePermissionModificationType.EDITOR;
    if (type === FilePermissionType.READ) return FilePermissionModificationType.READ;
    return null;
  }

  function getUsersWithoutOwner(): {
    email64: string;
    permission: FilePermissionModificationType;
  }[] {
    const users = [
      ...modifications.map((permission) => {
        return { email64: permission.email64, permission: permission.newPermission };
      }),
    ];
    for (const permission of receivedPermissions) {
      if (users.find((userPermission) => userPermission.email64 === permission.email64)) {
        continue;
      }
      if (permission.permission === FilePermissionType.OWNER) {
        continue;
      }
      users.push({ email64: permission.email64, permission: convertEnums(permission.permission)! });
    }
    return users;
  }

  return (
    <Dialog open={props.show} onClose={props.handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <GroupAddIcon /> Share with people
      </DialogTitle>
      <DialogContent>
        {isUpdating ? (
          <Grid container flex="1" flexDirection="row" spacing={2} sx={{ paddingTop: "18px" }}>
            <Grid item xs={12} justifyContent="center" sx={{ padding: "2rem", display: "flex", alignItems: "center" }}>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <>
            <DialogContentText sx={{ marginBottom: "15px" }}>Please enter the email of the people that you want to share the file with:</DialogContentText>
            <Grid container>
              <Grid item xs={11}>
                <TextField
                  fullWidth
                  label="Email:"
                  id="userEmail"
                  size="small"
                  placeholder="EmailOfUser@gmail.com"
                  value={newEmailToShareFile}
                  onChange={updateNewEmail}
                  error={validatedEmail}
                  helperText={validatedEmail && "Please enter a valid Email"}
                  required
                />
              </Grid>
              <Grid item xs={1}>
                <Button
                  onClick={() => {
                    addNewUser(newEmailToShareFile);
                  }}
                >
                  ADD
                </Button>
              </Grid>
            </Grid>
          </>
        )}
        {fileInfo && (
          <List sx={{ marginBottom: "15px" }}>
            <ListItem secondaryAction={<Chip label="Owner" />}>
              <Typography>{Buffer.from(getOwner()?.email64 || "", "base64").toString("utf-8")}</Typography>
            </ListItem>
            {Array.from(getUsersWithoutOwner()).map((currUser) => (
              <div key={"key" + currUser.email64}>
                <Divider style={{ marginBottom: "1px" }} />
                <ListItem
                  key={"key" + currUser.email64}
                  secondaryAction={
                    <FormControl sx={{ minWidth: 100 }} size="small">
                      <Select labelId="demo-select-small" id="demo-select-small" value={currUser.permission}>
                        <MenuItem
                          onMouseDown={() => addModification(currUser.email64, FilePermissionModificationType.READ)}
                          value={FilePermissionModificationType.READ}
                        >
                          View
                        </MenuItem>
                        <MenuItem
                          onMouseDown={() => addModification(currUser.email64, FilePermissionModificationType.EDITOR)}
                          value={FilePermissionModificationType.EDITOR}
                        >
                          Edit
                        </MenuItem>
                        <Divider />
                        <MenuItem
                          onMouseDown={() => addModification(currUser.email64, FilePermissionModificationType.REMOVE)}
                          value={FilePermissionModificationType.REMOVE}
                        >
                          Remove access
                        </MenuItem>
                      </Select>
                    </FormControl>
                  }
                >
                  <Typography>{Buffer.from(currUser.email64, "base64").toString("utf-8")}</Typography>
                </ListItem>
              </div>
            ))}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            setNewEmailToShareFile("");
            props.handleClose();
          }}
          style={{ marginTop: "15px" }}
        >
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit} style={{ marginTop: "15px" }}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShareForm;
