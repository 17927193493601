import * as React from "react";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import DescriptionIcon from "@mui/icons-material/Description";
import FolderIcon from "@mui/icons-material/Folder";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloudUploadicon from "@mui/icons-material/CloudUpload";

export type AddFileButtonProps = {
  handleFile: () => void;
  handleFolder: () => void;
  handleUpload: () => void;
};

export default function AddButtonFolderAndFile(props: AddFileButtonProps) {
  const [open, setOpen] = React.useState(false);
  const [closeTimeout, setCloseTimeout] = React.useState(undefined as NodeJS.Timeout | undefined);
  const handleOpen = () => {
    if (closeTimeout) {
      clearTimeout(closeTimeout);
    }
    setOpen(true);
  };
  const handleClose = () => {
    if (closeTimeout) {
      clearTimeout(closeTimeout);
    }
    const timeout = setTimeout(() => setOpen(false), 1000);
    setCloseTimeout(timeout);
  };

  const actions = [
    { icon: <DescriptionIcon />, name: "File", onClick: props.handleFile },
    { icon: <FolderIcon />, name: "Folder", onClick: props.handleFolder },
    { icon: <UploadFileIcon />, name: "Upload", onClick: props.handleUpload },
  ];
  return (
    <Box
      sx={{
        position: "absolute",
        bottom: 5,
        right: 16,
        zIndex: 2,
        transform: "translateZ(0px)",
        flexGrow: 1,
      }}
    >
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        sx={{ position: "absolute", bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        onMouseEnter={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={`New ${action.name}`} onClick={() => action.onClick()} />
        ))}
      </SpeedDial>
    </Box>
  );
}
