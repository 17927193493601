import AnyParser from "./anyParser";
import { ValidationResult } from "./parserIfc";

class NumberParser extends AnyParser {
  constructor(private params: any, private subTypes: Set<string>) {
    super(params, subTypes);
  }
  parse(value: any): number {
    const val = parseFloat(value);
    return isNaN(val) ? 0 : val;
  }
  validateAndParse(value: any): ValidationResult {
    const val = parseFloat(value);
    if (isNaN(val)) {
      return { parsedValue: 0, validationError: value + " is not a valid number" };
    }
    if (this?.params?.min || this?.params?.min === 0) {
      if (val < this.params.min) {
        return { parsedValue: 0, validationError: value + " is lower than " + this.params.min };
      }
    }
    if (this?.params?.max || this?.params?.max === 0) {
      if (val > this.params.max) {
        return { parsedValue: this.params.max, validationError: value + " is higher than " + this.params.min };
      }
    }
    return { parsedValue: val, validationError: "" };
  }
}

export default NumberParser;
