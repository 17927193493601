import AnyParser from "./anyParser";
import { ValidationResult } from "./parserIfc";

class ColorParser extends AnyParser {
  constructor(private params: any, private subTypes: Set<string>) {
    super(params, subTypes);
  }
  parse(value: any): string {
    if (!value?.length) {
      return "#FFFFFF";
    }
    return ("" + value).toUpperCase();
  }
  validateAndParse(value: any): ValidationResult {
    if (isValidColorStr(value)) {
      return { parsedValue: this.parse(value), validationError: "" };
    }
    return {
      parsedValue: "#FFFFFF",
      validationError: "not a valid color, should start with # and have 6 letter or digits",
    };
  }
}

export function isValidColorStr(color: string) {
  return /^#[0-9A-Fa-f]{6}$/i.test("" + color);
}

export default ColorParser;
