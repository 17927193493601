import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Dialog, Grid, Typography } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Name from "./name";
import Value from "./value";
import { JsonNode } from "../../../db/jsonNode";
import DocHolder from "../../holder/docHolder";

export type IsModifyAttributeProps = {
  path: string;
  holder: DocHolder;
  dialogOpen: boolean;
  dialogOnClose: () => void;
  valueKey: string;
  closeDialog: () => void;
  undoChanges: (name: string, value: any) => Promise<void>;
};

function AttributeHistoryForm(props: IsModifyAttributeProps) {
  const [currNode, setCurrNode] = useState(null as JsonNode | null);
  const [oldNode, setOldNode] = useState(null as JsonNode | null);
  useEffect(() => {
    props.holder
      .getDoc(props.path)
      .then((currDoc) => setCurrNode(currDoc))
      .then(() => {
        props.holder.getUnmodifiedDoc(props.path).then((currOldDoc) => setOldNode(currOldDoc));
      });
  }, []);

  return (
    currNode && (
      <>
        <Dialog open={props.dialogOpen} onClose={props.dialogOnClose}>
          <DialogTitle id="alert-dialog-title">Compare between the old and new values</DialogTitle>
          <DialogContent>
            <Grid container>
              {!oldNode?.nodeName ? (
                <Grid item xs={12}>
                  <Typography>
                    <strong>This attribute is new</strong>
                  </Typography>
                </Grid>
              ) : (
                <>
                  <Grid item xs={12}>
                    <Typography>The old Value (Saved):</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Name
                      isOldName={true}
                      disabled={true}
                      style={{ display: "inline", paddingRight: "6px", paddingLeft: "6px" }}
                      holder={props.holder}
                      path={props.path}
                      isSelected={true}
                    />
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={6}>
                    {oldNode && !oldNode.type?.isArray && !oldNode.type?.isObject && (
                      <Value
                        isOldValue={true}
                        disabled={true}
                        style={{ display: "inline" }}
                        key={props.valueKey}
                        holder={props.holder}
                        path={props.path}
                        isSelected={true}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>the new Value (unSaved):</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Name
                      disabled={true}
                      style={{ display: "inline", paddingRight: "6px", paddingLeft: "6px" }}
                      holder={props.holder}
                      path={props.path}
                      isSelected={true}
                    />
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={6}>
                    {!currNode.type?.isArray && !currNode.type?.isObject && (
                      <Value disabled={true} style={{ display: "inline" }} key={props.valueKey} holder={props.holder} path={props.path} isSelected={true} />
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            {/* {!!oldNode?.name && (
              <Button
                variant="outlined"
                onClick={() => {
                  props.undoChanges(oldNode?.name || "", oldNode?.value).then(() => {
                    props.closeDialog();
                  });
                }}
              >
                Undo changes
              </Button>
            )} */}
            <Button onClick={props.closeDialog}>Close</Button>
          </DialogActions>
        </Dialog>
      </>
    )
  );
}

export default AttributeHistoryForm;
