import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { ValidationResult } from "../../holder/typesparsers/parserIfc";
import { OtherTypesIDS, PrimitiveTypesIDS } from "common";
import { DocType } from "../../../db/docType";
import { format, endOfDay } from "date-fns";
import TextValue from "./value/text";
import NumberValue from "./value/number";
import DefaultValue from "./value/defaultValue";
import BooleanValue from "./value/boolean";
import ImageValue from "./value/image";
import PhoneValue from "./value/phone";
import DateValue from "./value/date";
import DateAndTimeValue from "./value/dateAndTime";
import ColorValue from "./value/color";
import HtmlValue from "./value/html";
import DocHolder from "../../holder/docHolder";
import { setDocValue, validateValue } from "./attribute";
import URLValue from "./value/url";
import { JsonNode } from "../../../db/jsonNode";

export type Props = {
  holder: DocHolder;
  path: string;
  style: any;
  isSelected: boolean;
  preview?: boolean;
  disabled?: boolean;
  isOldValue?: boolean;
};

const TIME_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";
const DATE_FORMAT = "yyyy-MM-dd";
function Value(props: Props) {
  const [type, setType] = useState(null as DocType | null);
  const [valState, setValState] = useState("");
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [showHTML, setShowHTML] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [tempColor, setTempColor] = useState("#FFFFFF");
  const [dateValue, setDateValue] = useState(format(new Date(), DATE_FORMAT));
  const [dateAndTimeValue, setDateAndTimeValue] = useState(format(endOfDay(new Date()), TIME_DATE_FORMAT));

  useEffect(() => {
    if (props.isOldValue) {
      props.holder.getUnmodifiedDoc(props.path).then((currOldDoc) => {
        if (!currOldDoc?.type) return;
        if (currOldDoc.type !== type) {
          setType(currOldDoc.type);
        }
        const correctVal = getCorrectValueFromInput(currOldDoc?.value);
        setValState(correctVal);
        switch (currOldDoc.type.id) {
          case OtherTypesIDS.DATEANDTIME: {
            const date = correctVal ? new Date(correctVal) : new Date();
            setDateAndTimeValue(date ? format(date, TIME_DATE_FORMAT) : "");
            break;
          }
          case OtherTypesIDS.DATE: {
            setDateValue(correctVal);
            break;
          }
        }
      });
    } else {
      props.holder.getDoc(props.path).then((doc) => {
        console.info("useEffect value doc=", doc);
        if (!doc?.type) return;
        if (doc.type !== type) {
          setType(doc.type);
        }
        const correctVal = getCorrectValueFromInput(doc.value);
        if (correctVal !== valState) {
          setValState(correctVal);
          switch (doc.type.id) {
            case OtherTypesIDS.DATEANDTIME: {
              const date = correctVal ? new Date(correctVal) : new Date();
              setDateAndTimeValue(date ? format(date, TIME_DATE_FORMAT) : "");
              break;
            }
            case OtherTypesIDS.DATE: {
              setDateValue(correctVal);
              break;
            }
          }
          const validationResult: ValidationResult = validateValue(props.holder, doc.type, correctVal);
          setValidationError(validationResult.validationError || "");
        }
      });
    }
  }, [props.path]);

  function getCorrectValueFromInput(input: any) {
    if (input === undefined) return "";
    if (input === null) return "null";
    if (input === 0) return "0";
    if (input === false) return "false";
    return "" + input;
  }

  const setValInHolder = async (newValue: any): Promise<string> => {
    if (!type) return "";
    console.info("props.path=", props.path, " type=", type, " newValue=", newValue);
    return setDocValue(props.holder, type, props.path, newValue);
  };

  async function updateValue(newValue: any) {
    setValState(newValue);
    setValInHolder(newValue).then((res: string) => setValidationError(res));
  }
  const handleChangeDateAndTime = (event: any) => {
    const time = event?.target?.value;
    setDateAndTimeValue(time);
    const date = new Date(time);
    updateValue(date ? format(date, TIME_DATE_FORMAT) : "");
  };
  const handleChangeDate = (event: any) => {
    const time = event?.target?.value;
    setDateValue(time);
    const date = new Date(time);
    updateValue(date ? format(date, DATE_FORMAT) : "");
  };

  function getInputFormBasedOnType(type: DocType | null) {
    if (!type) return null;
    if (type.id === PrimitiveTypesIDS.BOOL) {
      return <BooleanValue value={valState} updateValue={updateValue} disabled={props.disabled} />;
    } else if (type.isArray) {
      return null;
    } else if (type.isObject) {
      return null;
    } else if (type.id === OtherTypesIDS.IMAGE) {
      return <ImageValue value={valState} updateValue={updateValue} style={props.style} validationError={validationError} disabled={props.disabled} />;
    } else if (type.id === OtherTypesIDS.COLOR) {
      return (
        <ColorValue
          value={valState}
          updateValue={handleChangeColor}
          toggleShowColorPicker={toggleShowColorPicker}
          displayColorPicker={displayColorPicker}
          tempColor={tempColor}
          closeColorPicker={closeColorPicker}
          disabled={props.disabled}
        />
      );
    } else if (type.id === OtherTypesIDS.HTML) {
      return (
        <HtmlValue
          value={valState}
          style={props.style}
          validationError={validationError}
          handleShowHTML={handleShowHTML}
          handleCloseHTML={handleCloseHTML}
          handleSaveHTML={handleSaveHTML}
          showHTML={showHTML}
          disabled={props.disabled}
        />
      );
    } else if (type.id === OtherTypesIDS.PHONENUMBER) {
      return <PhoneValue value={valState} updateValue={updateValue} style={props.style} validationError={validationError} disabled={props.disabled} />;
    } else if (type.id === OtherTypesIDS.DATEANDTIME) {
      return <DateAndTimeValue style={props.style} value={dateAndTimeValue} updateValue={handleChangeDateAndTime} disabled={props.disabled} />;
    } else if (type.id === OtherTypesIDS.DATE) {
      return <DateValue style={props.style} value={dateValue} updateValue={handleChangeDate} disabled={props.disabled} />;
    } else if (type.id === PrimitiveTypesIDS.TEXT) {
      return <TextValue value={valState} style={props.style} updateValue={updateValue} disabled={props.disabled} isSelected={props.isSelected} />;
    } else if (type.id === PrimitiveTypesIDS.NUMBER || type.parent === PrimitiveTypesIDS.NUMBER) {
      return <NumberValue value={valState} updateValue={updateValue} style={props.style} validationError={validationError} disabled={props.disabled} />;
    } else if (type.id === OtherTypesIDS.URL) {
      return <URLValue value={valState} updateValue={updateValue} style={props.style} validationError={validationError} disabled={props.disabled} />;
    }
    return <DefaultValue value={valState} updateValue={updateValue} style={props.style} validationError={validationError} disabled={props.disabled} />;
  }

  const toggleShowColorPicker = () => {
    setDisplayColorPicker(!displayColorPicker);
  };
  const closeColorPicker = () => {
    setValState(tempColor);
    updateValue(tempColor);
    setDisplayColorPicker(false);
  };
  const handleChangeColor = (color: any) => {
    setTempColor(color.hex?.toUpperCase());
  };

  const handleCloseHTML = () => {
    setShowHTML(false);
  };
  const handleSaveHTML = (newHtml: string) => {
    setShowHTML(false);
    updateValue(newHtml).then(() => setValState(newHtml));
    setValidationError("");
  };
  const handleShowHTML = () => setShowHTML(true);

  const getMaxPreviewSize = (): number => {
    return 100; // TODO: change number based on size or how inner it is
  };

  const getValueStr = (node: JsonNode | null): string => {
    if (!node?.value && node?.value !== false) {
      return "empty";
    }
    if (node?.type?.isArray) {
      return "[...]";
    }
    if (node?.type?.isObject) {
      return "{...}";
    }
    if (node) {
      return `${node.value}`;
    }
    return "nonode";
  };

  const getPreviewValue = async (): Promise<string> => {
    if (!type) return "empty";
    let previewStr = "";
    if (type?.isArray || type?.isObject) {
      const children = await props.holder?.getCachedChildren(props.path);
      if (!children?.length) return "no preview";
      for (let i = 0; i < children.length; i++) {
        if (i !== 0) {
          previewStr += ", ";
        }

        const child = children[i];
        const node = await props.holder?.getDoc(child);
        const valueStr = getValueStr(node);
        previewStr += (node?.nodeName || `${i}`) + ": " + valueStr;
      }
    } else {
      previewStr += valState;
    }

    if (!previewStr.trim()?.length) return "empty";
    return previewStr.substr(0, Math.min(previewStr.length, getMaxPreviewSize())) + "...";
  };

  if (props.preview) {
    return (
      <TextValue
        value=""
        style={props.style}
        updateValue={async () => {}}
        disabled={props.disabled}
        isSelected={props.isSelected}
        asyncValue={getPreviewValue()}
      />
    );
  }

  return type?.isArray ? null : getInputFormBasedOnType(type);
}

export default Value;
