import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import "./mainPage.css";
import Header from "../header/";
import Footer from "../footer/footer";

export type Props = {};

function MainPage(props: Props) {
  return (
    <>
      <Header></Header>
      <Container className="main-page">
        <Row className="main-section">
          <Col>
            <h2>It shouldn't be hard, it's a JSON.</h2>
            <h4>Time to create one with style.</h4>
            <a href="/filesexplorer">
              <Button variant="light">Create a JSON</Button>
            </a>
          </Col>
        </Row>
        <Row className="main-section white-background">
          <Col sm={0} md={0} lg={1} xl={1}></Col>
          <Col sm={12} md={12} lg={5} xl={5}>
            <div className="main-section-text">
              <h2>All the basic types are simple!</h2>
              <h4>Don't be confused, a number is just a number.</h4>
            </div>
          </Col>
          <Col sm={12} md={12} lg={6} xl={6}>
            <div className="main-section-img">
              <img src={process.env.PUBLIC_URL + "/mainPage/simple_types.png"} />
            </div>
          </Col>
        </Row>
        <Row className="main-section" style={{ backgroundColor: "seashell" }}>
          <Col sm={0} md={0} lg={1} xl={1}></Col>
          <Col sm={12} md={12} lg={5} xl={5}>
            <div className="main-section-text">
              <h2>Complex types are not so complex.</h2>
              <h4>
                {"Create {objects}, [collections], questions?, "}
                <u>links</u>
                {", images and more with simple clicks."}
              </h4>
            </div>
          </Col>
          <Col sm={12} md={12} lg={6} xl={6}>
            <div className="main-section-img">
              <img src={process.env.PUBLIC_URL + "/mainPage/complex_types.png"} />
            </div>
          </Col>
        </Row>
        <Row className="main-section">
          <Col sm={0} md={0} lg={3} xl={3}></Col>
          <Col sm={12} md={12} lg={6} xl={6}>
            <div className="main-section-text">
              <h2>Should only developers create JSONs?</h2>
              <h4>Not anymore! It's time to take control of how the application looks like!</h4>
              <a href="/filesexplorer">
                <Button variant="light">Create a JSON</Button>
              </a>
            </div>
          </Col>
          <Col sm={0} md={0} lg={3} xl={3}></Col>
        </Row>
      </Container>
      <Footer></Footer>
    </>
  );
}

export default MainPage;
