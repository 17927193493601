import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
  Grid,
  Drawer,
  styled,
  IconButton,
  Button,
  CircularProgress,
  SwipeableDrawer,
  Box,
  Typography,
  ListItemIcon,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import FilesList, { isFileProcessing } from "./fileslist";
import FilesHeader from "./header";
import AddFileForm from "./addFileForm";
import { authenticationClient } from "../../App";
import AddFileButton from "./addFileButtonMenu";
import { FileDescription, FileInfo, FilePattern, FileTypes, JWTHelper, ProcessingLog } from "common";
import AddFolderForm from "./addFolderForm";
import DescriptionIcon from "@mui/icons-material/Description";
import FolderIcon from "@mui/icons-material/Folder";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import FolderDeleteIcon from "@mui/icons-material/FolderDelete";
import UserRecord from "../../db/userRecord";
import AddButtonFolderAndFile from "./AddButtonFolderAndFile";
import UploadFileForm from "./uploadFileForm";
import "./filesexplorer.css";
import { Storage } from "aws-amplify";
export type FilesExplorerProps = { history?: any; user?: UserRecord };

function FilesExplorer(props: FilesExplorerProps) {
  const drawerWidth = 240;
  const [loadingFiles, setLoadingFiles] = useState(true);
  const [loadingIssue, setLoadingIssue] = useState(false);
  const [currSelectedFolderId, setCurrSelectedFolderId] = useState("");
  const [currSelectedFolder, setCurrSelectedFolder] = useState({} as FileDescription);
  const [files, setFiles] = useState([] as FileDescription[]);
  const [sortedFiles, setSortedFiles] = useState(files);
  const [openMenu, setOpenMenu] = useState(false);
  const [showAddNewFile, setShowAddNewFile] = useState(false);
  const [showAddNewFolder, setShowAddNewFolder] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [isSharedFiles, setIsSharedFiles] = useState(false);
  const [isTrashFiles, setIsTrashFiles] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  useEffect(() => {
    console.info("useeffect props.user?.rootFolder=", props.user?.rootFolder);
    if (props.user?.rootFolder) {
      setCurrSelectedFolderId(props.user.rootFolder);
    }
  }, [props.user?.rootFolder]);

  useEffect(() => {
    setSortedFiles(files);
  }, [files]);

  const sortFilesByName = (order: boolean) => {
    const sorted = order
      ? [...files].sort((a, b) => a.name?.localeCompare(b.name || "") || 0)
      : [...files].sort((a, b) => b.name?.localeCompare(a.name || "") || 0);

    setSortedFiles(sorted);
  };
  const sortFilesByCreated = (order: boolean) => {
    const sorted = order ? [...files].sort((a, b) => (a.created || 0) - (b.created || 0)) : [...files].sort((a, b) => (b.created || 0) - (a.created || 0));
    setSortedFiles(sorted);
  };
  const reloadFolder = (currFolderId: string | undefined) => {
    console.info("reloadFolder " + currFolderId);
    setSelectedFile("");
    if (currFolderId?.length) {
      setLoadingFiles(true);
      authenticationClient
        .getFolder(currFolderId)
        .then((resFiles) => {
          setCurrSelectedFolder(resFiles.fileInfo);
          setFiles(resFiles.childFiles || []);
          setLoadingFiles(false);
          setLoadingIssue(false);
        })
        .catch((err) => {
          setFiles([]);
          setLoadingFiles(false);
          setLoadingIssue(true);
        });
    } else {
      setFiles([]);
      setLoadingFiles(false);
      setLoadingIssue(false);
    }
  };

  useEffect(() => {
    setSelectedFile("");
    reloadFolder(currSelectedFolderId);
  }, [currSelectedFolderId]);

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  }));

  const navigateToFolder = (newSelectedFolder: string) => {
    setCurrSelectedFolderId(newSelectedFolder);
  };

  const openFileInfo = (fileId: string) => {
    setSelectedFile(fileId);
  };

  const getMenu = () => {
    return (
      <>
        <Stack paddingTop={1} spacing={2} direction="row" display="flex" flex="1" height={"100%"}>
          <Stack
            spacing={0}
            direction="column"
            sx={{
              width: drawerWidth,
              // bgcolor: "background.default",
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
          >
            <List>
              <Divider />
              <ListItem
                button
                key="All files"
                onClick={() => {
                  setIsSharedFiles(false);
                  setIsTrashFiles(false);
                  setCurrSelectedFolderId(props.user!.rootFolder);
                  reloadFolder(props.user!.rootFolder);
                }}
              >
                <ListItemIcon>
                  <FolderIcon />
                </ListItemIcon>
                <ListItemText primary="All files" />
              </ListItem>
              <ListItem button key="Shared with me" onClick={handleShowSharedWithMe}>
                <ListItemIcon>
                  <FolderSharedIcon />
                </ListItemIcon>
                <ListItemText primary="Shared with me" />
              </ListItem>
              <Divider />
              {/* <ListItem button key="Trash" onClick={handleShowTrashFiles}>
                <ListItemIcon>
                  <FolderDeleteIcon />
                </ListItemIcon>
                <ListItemText primary="Trash" />
              </ListItem> */}
              <Divider />
            </List>
          </Stack>
        </Stack>
      </>
    );
  };

  function isInvalidNewFileName(newFileName: string) {
    if (newFileName.length === 0) return true;

    return !!files.find((file) => newFileName === file?.name); // TODO: add path
  }
  function isInvalidNewFolderName(newFileName: string) {
    if (newFileName.length === 0) return true;
    return !!files.find((file) => newFileName === file?.name); // TODO: add path
  }
  const handleSaveNewFile = (newFileName: string, filePattern: FilePattern) => {
    return authenticationClient
      .addFileToUser(newFileName, currSelectedFolder.id!, filePattern, FileTypes.FILE) // TODO: add path
      .then((res) => {
        if (res) {
          reloadFolder(currSelectedFolder.id);
        }
      })
      .finally(() => {
        setShowAddNewFile(false);
      });
  };
  // ROZROZ
  const handleUploadFile = (file: File) => {
    return uploadFile(file, currSelectedFolderId)
      .then((res) => {
        if (res) {
          reloadFolder(currSelectedFolder.id);
        }
        return res;
      })
      .finally(() => {
        setShowAddNewFile(false);
      });
  };
  const handleShowSharedWithMe = () => {
    return authenticationClient.getSharedWithMe().then((res) => {
      if (res) {
        setFiles(res.files);
        setIsSharedFiles(true);
        setIsTrashFiles(false);
      }
    });
  };
  const handleShowTrashFiles = () => {
    setFiles([]);
    setIsSharedFiles(false);
    setIsTrashFiles(true);
  };
  const handleBack = () => {
    setCurrSelectedFolderId(currSelectedFolder.parent!);
  };
  const handleSaveNewFolder = (newFileName: string) => {
    return authenticationClient
      .addFileToUser(newFileName, currSelectedFolder.id!, FilePattern.BLANK, FileTypes.FOLDER) // TODO: add path
      .then((res) => {
        if (res) {
          reloadFolder(currSelectedFolder.id);
        }
      })
      .finally(() => {
        setShowAddNewFolder(false);
      });
  };
  const handleDeleteFile = (fileId: string) => {
    console.info("before deleting the fileId=", fileId);
    return authenticationClient
      .deleteFile(fileId)
      .then((res) => {
        if (res) {
          reloadFolder(currSelectedFolder.id);
        }
      })
      .finally(() => {
        setShowAddNewFile(false);
      });
  };
  const isHomePage = () => {
    return !currSelectedFolder.parent?.length;
  };
  const typeOfFolder = () => {
    if (isSharedFiles) return "1";
    if (isTrashFiles) return "4";
    return isHomePage() ? "2" : "3";
  };
  const handleRenameFile = (fileId: string, newFileName: string) => {
    console.info("before renaming the file the fileId=", fileId, " with the name=", newFileName);
    return authenticationClient
      .renameFile(fileId, newFileName)
      .then((res) => {
        if (res) {
          reloadFolder(currSelectedFolder.id);
        }
      })
      .finally(() => {
        setShowAddNewFile(false);
      });
  };
  const handleDuplicateFile = (fileId: string) => {
    console.info("duplication on the file with the id=", fileId, " will be implemented");
  };
  const questionBoxStyle = {};
  const dateOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hourCycle: "h23",
  };

  return (
    <>
      <Grid container>
        <AddFileForm
          show={showAddNewFile}
          onHide={() => setShowAddNewFile(false)}
          handleClose={() => setShowAddNewFile(false)}
          handleSubmit={handleSaveNewFile}
          isInvalid={isInvalidNewFileName}
        ></AddFileForm>
        <AddFolderForm
          show={showAddNewFolder}
          onHide={() => setShowAddNewFolder(false)}
          handleClose={() => setShowAddNewFolder(false)}
          handleSubmit={handleSaveNewFolder}
          isInvalid={isInvalidNewFolderName}
        ></AddFolderForm>
        <Grid item xs={12} key="filesheader">
          <FilesHeader
            handleBack={handleBack}
            currentFolderName={currSelectedFolder.name || ""}
            sortByCreated={sortFilesByCreated}
            sortByName={sortFilesByName}
            typeOfFolder={typeOfFolder()}
          />
        </Grid>
        <Grid item xs={12} key="uploadfileform">
          <UploadFileForm
            show={showUpload}
            onHide={() => setShowUpload(false)}
            handleClose={() => setShowUpload(false)}
            handleSubmit={handleUploadFile}
            isInvalid={isInvalidNewFileName}
          ></UploadFileForm>
        </Grid>
        <Grid item md={3} lg={2} sx={{ display: { xs: "none", md: "block" } }} key="filesmenu">
          {getMenu()}
        </Grid>
        <Divider />
        {openMenu && (
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
              display: { xs: "block", md: "none" },
            }}
            anchor="left"
            open={openMenu}
            onClose={() => setOpenMenu(false)}
          >
            <DrawerHeader>
              <IconButton onClick={() => setOpenMenu(false)}>
                <ChevronLeftIcon />
              </IconButton>
            </DrawerHeader>
            {getMenu()}
          </Drawer>
        )}
        <Grid item xs={12} md={9} lg={10} pr={1} pl={1} key="filesdisplay">
          {loadingFiles ? (
            <Grid item xs={12} justifyContent="center" sx={{ padding: "2rem", display: "flex", alignItems: "center" }} key="progress">
              <CircularProgress />
            </Grid>
          ) : null}
          {loadingIssue && (
            <Grid item xs={12} justifyContent="center" sx={{ padding: "2rem", display: "flex", alignItems: "center" }} key="loadingissues">
              <Typography>There was an error loading your files, try to login again.</Typography>
            </Grid>
          )}
          {!loadingFiles && !loadingIssue && (
            <>
              <FilesList
                files={sortedFiles}
                renameFile={handleRenameFile}
                deleteFile={handleDeleteFile}
                navigateToFolder={navigateToFolder}
                openFileInfo={openFileInfo}
                isInvalid={isInvalidNewFileName}
                duplicateFile={handleDuplicateFile}
                getApiKeys={(fileId) => authenticationClient.getFileAccessTokens(fileId)}
                createApiKey={(fileId, expiryHours) => authenticationClient.postGenerateFileAccessToken(fileId, expiryHours)}
                key="fileslist"
              ></FilesList>
            </>
          )}
          <AddButtonFolderAndFile
            handleFile={() => setShowAddNewFile(true)}
            handleFolder={() => setShowAddNewFolder(true)}
            handleUpload={() => setShowUpload(true)}
          />
          <>
            <SwipeableDrawer
              sx={{
                width: {
                  xs: "80%",
                  sm: "60%",
                  md: "40%",
                  lg: "35%",
                  xl: "35%",
                },
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  width: {
                    xs: "80%",
                    sm: "60%",
                    md: "40%",
                    lg: "35%",
                    xl: "35%",
                  },
                  boxSizing: "border-box",
                },
              }}
              anchor="right"
              open={!!selectedFile?.length}
              onOpen={() => {}}
              onClose={() => setSelectedFile("")}
              key="descriptiondrawer"
            >
              <Grid
                container
                flex="1"
                flexDirection="row"
                sx={{ paddingTop: "18px", paddingLeft: "24px", paddingRight: "24px", paddingBottom: "10px" }}
                key="descriptiondrawergrid"
              >
                {files
                  .filter((file) => file.id === selectedFile)
                  .map((file: FileDescription) => {
                    const created = new Date(file.created || 0);
                    const fileInfo = file as FileInfo;
                    return (
                      <>
                        <Box sx={questionBoxStyle} key={`descriptionbox_${file.id}`}>
                          <Stack spacing={2} direction="column" key="columnofdescription">
                            <Stack spacing={1} direction="row" key="name">
                              <Typography>
                                {file.fileType === FileTypes.FOLDER ? <FolderIcon fontSize="medium" /> : <DescriptionIcon fontSize="medium" />}
                              </Typography>
                              <Typography>{file.name}</Typography>
                            </Stack>
                            {/* TODO {file.permissions} */}
                            {created && (
                              <Stack spacing={1} direction="row" key="created">
                                Created: {`${created.toLocaleDateString("en-US", dateOptions)}`}
                              </Stack>
                            )}
                            {isFileProcessing(fileInfo) && (
                              <Stack spacing={1} direction="row" key="state">
                                This file is in state:&nbsp;<strong>{fileInfo?.processingState}</strong>
                              </Stack>
                            )}
                            {isFileProcessing(fileInfo) && fileInfo?.processingLog?.length && (
                              <Stack spacing={1} direction="row" key="logtitle">
                                Relevant processing error log:
                              </Stack>
                            )}
                            {isFileProcessing(fileInfo) &&
                              fileInfo?.processingLog?.length &&
                              processingLogStr(fileInfo?.processingLog).map((log, index) => (
                                <Stack spacing={1} direction="row" key={`logline${index}`}>
                                  {log}
                                </Stack>
                              ))}
                            {/* {file.fileType === FileTypes.FILE && (
                              <Stack spacing={2} direction="row">
                                <Button>Translate</Button>
                              </Stack>
                            )} */}
                          </Stack>
                        </Box>
                      </>
                    );
                  })}
              </Grid>
            </SwipeableDrawer>
          </>
        </Grid>
      </Grid>
    </>
  );
}

const uploadFile = async (file: File, currFolder: string): Promise<boolean> => {
  console.info(`uploadFile currFolder=${currFolder} file=${file}`);

  if (!file) return false;
  if (file.type !== "application/json" || !file.name) {
    // TODO: make it a jumping error message
    console.error("you can only upload JSONs");
    return false;
  }
  if (file.size > 10000000) {
    // TODO: make it a jumping error message
    console.error("file is too large");
    return false;
  }
  console.info(`uploading file=${file.name} size=${file.size}`);
  try {
    const fileText = await readFile(file);
    const parsed = JSON.parse(fileText.trim());
    if (parsed && typeof parsed === "object" && !Array.isArray(parsed)) {
      console.info(`parsed a good json`);
    } else {
      console.error("we don't support this json format, it should be an object");
      return false;
    }
  } catch (err) {
    // TODO: make it a jumping error message
    console.error(`failed parsing file=${file.name}, can't upload a file that is not a valid json`, err);
    return false;
  }

  try {
    const fileNameForUpload = await authenticationClient.addUploadFileToUser(file!.name, currFolder, file!.size);
    if (!fileNameForUpload) {
      console.error("can't upload file!"); // TODO: handle better
      return false;
    }
    const res = await Storage.put(fileNameForUpload, file, {
      cacheControl: "no-cache", // (String) Specifies caching behavior along the request/reply chain
      contentDisposition: "attachment", // (String) Specifies presentational information for the object
      contentType: "application/json",
      resumable: false, // (Boolean) Allows uploads to be paused and resumed
      progressCallback: (progress) => {
        console.info(`progress=${JSON.stringify(progress)}`);
      },
    });
    console.info(`finished uploading, res=${JSON.stringify(res)}`);
    return true;
  } catch (err) {
    console.error(`failed uploading file ${file!.name} error=`, err);
    return false;
  }
};

const readFile = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async (fileLoadedEvent) => {
      try {
        // Resolve the promise with the response value
        resolve((fileLoadedEvent?.target?.result || "") as string);
      } catch (err) {
        reject(err);
      }
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsText(file, "UTF-8");
  });
};

const processingLogStr = (processingLog: ProcessingLog[] | undefined): string[] => {
  let log: string[] = [];
  if (!processingLog?.length) {
    return ["nothing to show"];
  }
  let currLog = processingLog[0];
  log.push(`Start date: ${currLog.logTime}, error: ${currLog.processingError}`);

  if (processingLog.length > 1) {
    currLog = processingLog[1];
    log.push(`Start date: ${currLog.logTime}, error: ${currLog.processingError}`);
  }

  return log;
};

export default FilesExplorer;
