import { Types } from "../actionTypes";

export type OpenNodeActionsType = {
  type: Types.SET_OPEN_NODE | Types.UNSET_OPEN_NODE | Types.ADD_OPEN_NODES;
};

export type SetOpenNodeAction = OpenNodeActionsType & {
  type: Types.SET_OPEN_NODE;
  path: string;
};

export type UnsetOpenNodeAction = OpenNodeActionsType & {
  type: Types.UNSET_OPEN_NODE;
  path: string;
};

export type AddOpenNodesAction = OpenNodeActionsType & {
  type: Types.ADD_OPEN_NODES;
  indexes: string[];
};

export function setOpenNode(path: string): SetOpenNodeAction {
  return { type: Types.SET_OPEN_NODE, path };
}

export function unsetOpenNode(path: string): UnsetOpenNodeAction {
  return { type: Types.UNSET_OPEN_NODE, path };
}

export function addOpenNodes(indexes: string[]): AddOpenNodesAction {
  return { type: Types.ADD_OPEN_NODES, indexes };
}
