import { Types } from "../actionTypes";

export interface SetHighlightAction {
  type: typeof Types.SET_HIGHLIGHT;
  selectedHighlight: string;
}

export function setHighlight(selectedHighlight: string): SetHighlightAction {
  return { type: Types.SET_HIGHLIGHT, selectedHighlight };
}
