import ParserIfc from "./typesparsers/parserIfc";
import { DocType } from "../../db/docType";
import { getRelevantParser } from "./typesparsers/parserIds";
import TYPES_WITH_SUBTYPES from "../../db/typesMap";
import { PrimitiveTypesIDS } from "common";

class DocTypesHolder {
  private _typeParserByName: Map<DocType, ParserIfc> = this.loadTypesParsers();

  public getParserForType(type: DocType) {
    const result = Array.from(this._typeParserByName.entries()).find(([key, value]) => key.id === type.id);
    if (!result) return undefined;
    const [, parser] = result;
    return parser;
  }

  private loadTypesParsers(): Map<DocType, ParserIfc> {
    const newTypes = new Map<DocType, ParserIfc>();
    // TODO: load from backend - remember you get params that have to be passed to the parser
    const types = TYPES_WITH_SUBTYPES;
    types.forEach((type) => {
      let parser = getRelevantParser(type.parser, type.params, new Set<string>(type.subTypes));
      if (parser) {
        newTypes.set(type, parser);
      }
    });
    return newTypes;
  }

  public getAllTypeParsers(): Map<DocType, ParserIfc> {
    return new Map(this._typeParserByName);
  }

  public getDocTypePerId(typeId: string): DocType | null {
    let type = Array.from(this.getAllTypeParsers().entries()).find(([docType, parser]) => docType.id === typeId);
    if (type) {
      const [docType] = type;
      return docType;
    }
    return null;
  }

  public get objType() {
    return this.getDocTypePerId(PrimitiveTypesIDS.OBJECT) as DocType;
  }

  public get defaultTxtType() {
    return this.getDocTypePerId(PrimitiveTypesIDS.TEXT) as DocType;
  }

  public get arrType() {
    return this.getDocTypePerId(PrimitiveTypesIDS.ARRAY) as DocType;
  }
}

export default DocTypesHolder;
