import React from "react";
import aboutUsInfo, { Info } from "./aboutUsInfo";
import OneCard from "./oneCard";
import "./aboutus.css";
import { Col, Container, Row, Stack } from "react-bootstrap";
import { ImageList, ImageListItem, Typography } from "@mui/material";

const AboutUs: React.FC = (props: any) => {
  function createEntry(infoTerm: Info) {
    return (
      <Container key={infoTerm.id}>
        <OneCard info={infoTerm} />
      </Container>
    );
  }
  return (
    <Container className="aboutus-page">
      <Container className="aboutus-container">
        <Stack>
          <Typography variant="h3" className="aboutus-title aboutus-row" paddingBottom={1}>
            ItsJson provides a better experience for innovators and software developers.
          </Typography>
          <Stack className="aboutus-description aboutus-row">
            <Typography variant="h5">Creating a JSON was never easier and more interactive before!</Typography>
            <Typography variant="h5">By using ItsJson editor, it becomes fun and simple to create JSONs.</Typography>
            <Typography variant="h5">
              ItsJson is founded by an entreprenuer counselor and software developers, so creating a better collaboration is our mission.
            </Typography>
          </Stack>
          <Row className="aboutus-row">
            <Col style={{ padding: 0, paddingTop: "2rem", paddingBottom: "2rem" }} md={12} className="d-none d-md-block">
              <img src={process.env.PUBLIC_URL + "/aboutus.jpeg"} alt="about" className="aboutus-img" />
            </Col>
          </Row>
          <Stack className="aboutus-row">
            <Typography variant="h5">
              Our leadership team consists of an entreprenuers including a woman project manager with Master's degree and a Staff engineer with over 13 years of
              experience with various technologies.
            </Typography>
            <Typography variant="h5">
              This special composition helps us recognize the struggle of non developers and solve their issues with JSONs in the best possible way.
            </Typography>
            <Typography variant="h5" paddingBottom={5}>
              For more information you can contact us at info@itsjson.com
            </Typography>
          </Stack>
        </Stack>
      </Container>
    </Container>
  );
};

export default AboutUs;
