import ParserIfc, { ValidationResult } from "./parserIfc";

class AnyParser implements ParserIfc {
  constructor(params: any, private _subTypes: Set<string>) {}
  parse(value: any): any {
    if ("null" === "" + value) return null;
    const numberValue = parseFloat(value);
    if (!isNaN(numberValue)) return numberValue;
    return value;
  }
  validateAndParse(value: any): ValidationResult {
    return { parsedValue: this.parse(value), validationError: "" };
  }
  getSubTypes(): Set<string> {
    return this._subTypes;
  }
}

export default AnyParser;
