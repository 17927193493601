import { Button, CircularProgress, FormControl, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { fileTypesInfo, getNameById } from "./fileTypeData";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FilePattern } from "common";
export type AddFileFormProps = {
  show: boolean;
  onHide: any;
  handleClose: any;
  handleSubmit: (newFileName: string, filePattern: FilePattern) => any;
  isInvalid: any;
};

function AddFileForm(props: AddFileFormProps) {
  const [newFileName, setNewFileName] = useState("");
  const [validated, setValidated] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [selectedFilePattern, setSelectedFilePattern] = useState(FilePattern.BLANK);

  function updateFilePattern(event: any) {
    const newValue = event?.target?.value;
    console.info(`updateFilePattern ${newValue}`);
    setSelectedFilePattern(newValue);
  }

  function updateNewFileName(event: any) {
    const newValue = event?.target?.value;
    setNewFileName(newValue);
  }

  function getSelectedPatternInfo() {
    return Array.from(fileTypesInfo).filter((info) => info.id === selectedFilePattern)[0];
  }

  function handleSubmit() {
    setValidated(true);
    if (!props.isInvalid(newFileName)) {
      setIsUpdating(true);
      props.handleSubmit(newFileName, selectedFilePattern).then(() => {
        setNewFileName("");
        setValidated(false);
        setIsUpdating(false);
      });
    }
  }

  return (
    <Dialog open={props.show} onClose={props.handleClose} maxWidth="md" fullWidth>
      <DialogTitle variant="h4" paddingTop={4} marginTop={2} paddingBottom={2}>
        Create a new file
      </DialogTitle>
      <DialogContent>
        {isUpdating ? (
          <Grid container flex="1" flexDirection="row" spacing={2} sx={{ paddingTop: "18px" }}>
            <Grid item xs={12} justifyContent="center" sx={{ padding: "2rem", display: "flex", alignItems: "center" }}>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <>
            <Typography id="name-title" variant="h6" component="h6">
              Enter a file name
            </Typography>
            <TextField
              fullWidth
              label="File name:"
              id="filename"
              size="small"
              placeholder="filename.json"
              value={newFileName}
              onChange={updateNewFileName}
              error={validated && props.isInvalid(newFileName)}
              helperText="required field"
              variant="standard"
              required
            />
            <Typography id="modal-modal-title" variant="h6" component="h2" paddingTop={2}>
              Select the file pattern
            </Typography>
            <FormControl variant="standard" sx={{ minWidth: 180 }}>
              <Select labelId="select-pattern" id="select-pattern" value={selectedFilePattern} onChange={(event) => updateFilePattern(event)}>
                {Array.from(fileTypesInfo).map((currInfo) => (
                  <MenuItem key={currInfo.id} value={currInfo.id}>
                    {currInfo.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            props.handleClose();
            setNewFileName("");
            setValidated(false);
          }}
          style={{ marginTop: "15px" }}
        >
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit} style={{ marginTop: "15px" }} disabled={isUpdating}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddFileForm;
