import AnyParser from "./anyParser";
import ArrayParser from "./arrayParser";
import BooleanParser from "./booleanParser";
import ColorParser from "./colorParser";
import DateParser from "./dateParser";
import NumberParser from "./numberParser";
import ObjectParser from "./objectParser";
import ParserIfc from "./parserIfc";
import PhoneParser from "./phoneParser";
import StringParser from "./stringParser";
import UrlParser from "./urlParser";

export enum ParserIds {
  ANY = "any",
  ARRAY = "arr",
  BOOLEAN = "bool",
  COLOR = "clr",
  NUMBER = "num",
  OBJECT = "obj",
  STRING = "str",
  URL = "url",
  PHONE = "phone",
  DATE = "date",
}

export function getRelevantParser(parserId: string, params: any, subTypes: Set<string>): ParserIfc | undefined {
  switch (parserId) {
    case ParserIds.ANY:
      return new AnyParser(params, subTypes);
    case ParserIds.ARRAY:
      return new ArrayParser(params, subTypes);
    case ParserIds.BOOLEAN:
      return new BooleanParser(params, subTypes);
    case ParserIds.COLOR:
      return new ColorParser(params, subTypes);
    case ParserIds.NUMBER:
      return new NumberParser(params, subTypes);
    case ParserIds.OBJECT:
      return new ObjectParser(params, subTypes);
    case ParserIds.STRING:
      return new StringParser(params, subTypes);
    case ParserIds.URL:
      return new UrlParser(params, subTypes);
    case ParserIds.PHONE:
      return new PhoneParser(params, subTypes);
    case ParserIds.DATE:
      return new DateParser(params, subTypes);
    default:
      return undefined;
  }
}
