import { LOCAL_JWT, LoginApiClient } from "../aws/loginApiClient";
import { decode, Jwt, JwtPayload } from "jsonwebtoken";

export class LoginJWTHolder {
  private loginClient: LoginApiClient;
  private accessKeyId: string = "";
  private secretAccessKey: string = "";
  private sessionToken: string = "";
  private currentJWT: string = "";

  constructor(isLocalMock: boolean, region: string) {
    this.loginClient = new LoginApiClient(isLocalMock, region);
  }

  setKeysAndToken(accessKeyId: string, secretAccessKey: string, sessionToken: string) {
    this.accessKeyId = accessKeyId;
    this.secretAccessKey = secretAccessKey;
    this.sessionToken = sessionToken;
  }

  async getJWT(): Promise<string> {
    if (!this.currentJWT?.length) {
      this.currentJWT = await this.loginClient.refreshJwt(this.accessKeyId, this.secretAccessKey, this.sessionToken);
      return this.currentJWT;
    }

    if (this.currentJWT?.length && LOCAL_JWT !== this.currentJWT) {
      const headerToDecode = this.currentJWT.substring(7).trim();
      const decoded: Jwt | null = decode(headerToDecode, { complete: true });
      if (!decoded) {
        return this.currentJWT;
      }
      const payload = decoded.payload as JwtPayload;
      const now = Math.floor(Date.now() / 1000); // current time in seconds
      const expiry = payload.exp || now;
      const isTokenExpired = expiry && expiry < now;
      if (isTokenExpired) {
        this.currentJWT = await this.loginClient.refreshJwt(this.accessKeyId, this.secretAccessKey, this.sessionToken);
      }
    }

    return this.currentJWT;
  }
}
