import { Box, Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FilePattern } from "common";
import { FileUpload, FileUploadProps } from "./fileUpload/fileUpload";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

export type AddFileFormProps = {
  show: boolean;
  onHide: any;
  handleClose: any;
  handleSubmit: (files: File) => Promise<boolean>;
  isInvalid: (newFileName: string) => boolean;
};
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.primary,
}));

function calculateSize(size: number) {
  let calculateSize = "";
  if (size < 1000) {
    calculateSize = `${size} bytes`;
  } else if (size < 1000000) {
    calculateSize = `${Math.ceil(size / 1000)} KB`;
  } else {
    calculateSize = `${Math.round(size / 1000000)} MB`;
  }
  return calculateSize;
}

function UploadFileForm(props: AddFileFormProps) {
  const [file, setFile] = useState(undefined as File | undefined);
  const [errorText, setErrorText] = useState("");
  const [newFileName, setNewFileName] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (props.show) {
      setFile(undefined as File | undefined);
      setNewFileName("");
      setErrorText("");
    }
  }, [props.show]);

  const handleFileChange = async (files: FileList | null | undefined) => {
    const file = files?.[0];
    if (!file) return;
    if (file.type !== "application/json" || !file.name) {
      setErrorText("you can only upload JSONs");
      setFile(undefined as File | undefined);
      setNewFileName("");
      return;
    }
    if (file.size > 10000000) {
      setErrorText("file is too large");
      setFile(undefined as File | undefined);
      setNewFileName("");
      return;
    }
    console.info(`uploading file=${file.name} size=${file.size}`);
    try {
      const fileText = await readFile(file);
      const jsonParse = JSON.parse(fileText.trim());
    } catch (err) {
      setErrorText(`can't upload a file that is not a valid json`);
      setFile(undefined as File | undefined);
      setNewFileName("");
      return;
    }
    setFile(file);
    setNewFileName(file?.name || "");
    setErrorText("");
  };

  const readFile = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (fileLoadedEvent) => {
        try {
          // Resolve the promise with the response value
          resolve((fileLoadedEvent?.target?.result || "") as string);
        } catch (err) {
          reject(err);
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsText(file, "UTF-8");
    });
  };

  const fileUploadProp: FileUploadProps = {
    accept: "Json/*",
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files !== null && event.target?.files?.length > 0) {
        handleFileChange(event.target.files);
        console.log(`Saving ${event.target.value}`);
      }
    },
    onDrop: (event: React.DragEvent<HTMLElement>) => {
      handleFileChange(event.dataTransfer.files);
      console.log(`Drop ${event.dataTransfer.files[0].name}`);
    },
  };

  function handleSubmit() {
    setIsUpdating(true);
    if (file && !props.isInvalid(file!.name) && !errorText) {
      props.handleSubmit(file).then(() => {
        setNewFileName("");
        setFile(undefined as File | undefined);
        setIsUpdating(false);
      });
    }
    props.handleClose();
  }

  return (
    <Dialog open={props.show} onClose={props.handleClose} maxWidth="md">
      <DialogTitle>Uploading new file</DialogTitle>
      <DialogContent>
        {!file && !errorText && (
          <Alert severity="info">
            <AlertTitle>Info</AlertTitle>
            you can only upload JSONs - files that end with <strong> ".json"</strong>
          </Alert>
        )}
        <Grid sx={{ margin: "auto" }}>
          <FileUpload {...fileUploadProp} />
          {file && (
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography>Selected File:</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Item>{newFileName}</Item>
                </Grid>
                <Grid item xs={4}>
                  <Typography>File size:</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Item>{calculateSize(file.size)}</Item>
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>
        {errorText && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            File validation failed: <strong>{errorText}</strong>
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            props.handleClose();
            setNewFileName("");
            setErrorText("");
          }}
          style={{ marginTop: "15px" }}
          disabled={isUpdating}
        >
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit} style={{ marginTop: "15px" }} disabled={isUpdating}>
          {isUpdating ? "Uploading" : "Upload"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UploadFileForm;
