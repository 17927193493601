import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import DocHolder from "../../holder/docHolder";
import { setDocName } from "./attribute";
import "./attribute.css";

export type Props = {
  holder: DocHolder;
  path: string;
  style: any;
  isSelected: boolean;
  disabled?: boolean;
  isOldName?: boolean;
  overrideName?: string;
};

function Name(props: Props) {
  const [nameState, setNameState] = useState("");
  const [validationError, setValidationError] = useState("");

  useEffect(() => {
    if (props.overrideName) {
      setNameState(props.overrideName);
    } else if (props.isOldName) {
      props.holder.getUnmodifiedDoc(props.path).then((currOldDoc) => {
        setNameState(currOldDoc!.nodeName!);
      });
    } else {
      props.holder.getDoc(props.path).then((doc) => {
        setNameState(doc?.nodeName || "");
      });
    }
  }, [props.path]);

  const setNameInHolder = async (newName: string): Promise<string> => {
    return setDocName(props.holder, props.path, newName);
  };

  function updateNameState(event: any) {
    if (!props.disabled) {
      const newValue = event?.target?.value;
      setNameState(newValue);
      const result: Promise<string> = setNameInHolder(newValue);
      result.then((res) => setValidationError(res || ""));
    }
  }

  function getPlaceHolder(): string {
    return nameState ? nameState : "Property name";
  }

  return (
    <TextField
      id={"name_input_" + props.path}
      variant="standard"
      value={nameState}
      onChange={updateNameState}
      error={!!validationError.length}
      helperText={validationError}
      style={{ ...props.style, paddingRight: "10px" }}
      placeholder={getPlaceHolder()}
      size="small"
      fullWidth
      InputProps={{
        readOnly: props.disabled,
        disableUnderline: !props.isSelected,
      }}
    />
  );
}

export default Name;
