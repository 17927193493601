import { OpenNodeActionsType, SetOpenNodeAction, AddOpenNodesAction, UnsetOpenNodeAction } from "../actions/openNodesActions";
import { Types } from "../actionTypes";

export enum NodeStateEnum {
  OPEN,
  CLOSED,
}

export interface NodeState {
  id: string;
  state: NodeStateEnum;
}

export interface State {
  openNodes: NodeState[];
}

const initialState: State = {
  openNodes: [] as NodeState[],
};

export default function reduce(state: State = initialState, action: OpenNodeActionsType): State {
  switch (action.type) {
    case Types.SET_OPEN_NODE: {
      const newOpenNodePath = (action as SetOpenNodeAction).path;
      const newOpenNodes = state.openNodes.find((node) => node.id === newOpenNodePath)
        ? state.openNodes.map((node) => {
            if (node.id === newOpenNodePath) {
              return { id: newOpenNodePath, state: NodeStateEnum.OPEN };
            }
            return node;
          })
        : [...state.openNodes, { id: newOpenNodePath, state: NodeStateEnum.OPEN }];
      return {
        ...state,
        openNodes: newOpenNodes,
      };
    }
    case Types.UNSET_OPEN_NODE: {
      const newOpenNodePath = (action as UnsetOpenNodeAction).path;
      const newOpenNodes = state.openNodes.find((node) => node.id === newOpenNodePath)
        ? state.openNodes.map((node) => {
            if (node.id === newOpenNodePath) {
              return { id: newOpenNodePath, state: NodeStateEnum.CLOSED };
            }
            return node;
          })
        : [...state.openNodes, { id: newOpenNodePath, state: NodeStateEnum.CLOSED }];
      return {
        ...state,
        openNodes: newOpenNodes,
      };
    }
    case Types.ADD_OPEN_NODES:
      const indexes = (action as AddOpenNodesAction).indexes || [];
      let newOpenNodes = state.openNodes;
      for (const index in indexes) {
        if (newOpenNodes.find((node) => node.id === index)) {
          newOpenNodes.map((node) => {
            if (node.id === index) {
              return { id: index, state: NodeStateEnum.OPEN };
            }
            return node;
          });
        } else {
          newOpenNodes = [...newOpenNodes, { id: index, state: NodeStateEnum.OPEN }];
        }
      }
      return {
        ...state,
        openNodes: newOpenNodes,
      };
    default:
      return state || initialState;
  }
}
