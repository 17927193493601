import * as React from "react";
import passwordValidator from "password-validator";
import { Alert, Button, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography } from "@mui/material";
import VisibilityOff from "@mui/icons-material/HiveOutlined";
import Visibility from "@mui/icons-material/Hive";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { changePassword } from "../../auth/authenticatedComponent";

type Props = { history?: any };

// create a password schema
const schema = new passwordValidator();

schema.is().min(12).has().uppercase().has().lowercase().has().digits().has().symbols();
interface State {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  showOldPassword: boolean;
  showNewPassword: boolean;
  showConfirmPassword: boolean;
  validOldPassword: boolean;
  validNewPassword: boolean;
  validConfirmPassword: boolean;
}
export default function ChangePasswordForm(props: Props) {
  const [open, setOpen] = React.useState(false);
  const [okPassword, setOkPassword] = React.useState(false);
  const [values, setValues] = React.useState<State>({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    showOldPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
    validOldPassword: false,
    validNewPassword: false,
    validConfirmPassword: false,
  });
  const passwordPolicyContent = (
    <React.Fragment>
      <h5>Your password should contain: </h5>
      <ul>
        <li>Minimum length of 12 characters</li>
        <li>Numerical characters (0-9)</li>
        <li>Special characters</li>
        <li>Uppercase letter</li>
        <li>Lowercase letter</li>
      </ul>
    </React.Fragment>
  );
  const boxStyle = {
    top: "5%",
    left: "10%",
    bgcolor: "background.paper",
    border: "2px #000",
    borderRadius: "6px",
    boxShadow: 24,
    minWidth: "80%",
    p: 3,
  };

  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value, validNewPassword: false, validConfirmPassword: false });
  };

  const handleClickShowNewPassword = () => {
    setValues({
      ...values,
      showNewPassword: !values.showNewPassword,
    });
  };
  const handleClickShowOldPassword = () => {
    setValues({
      ...values,
      showOldPassword: !values.showOldPassword,
    });
  };
  const handleClickShowConfirmPassword = () => {
    setValues({
      ...values,
      showConfirmPassword: !values.showConfirmPassword,
    });
  };
  const handleValidBothPassword = (prop: boolean) => {
    setValues({
      ...values,
      validOldPassword: prop,
      validNewPassword: prop,
      validConfirmPassword: prop,
    });
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const isValidOldPassword = (): boolean => {
    if (!values.oldPassword?.length) return false;
    return !!schema.validate(values.oldPassword);
  };

  const isValidNewPassword = (): boolean => {
    if (!values.newPassword?.length) return false;
    return !!schema.validate(values.newPassword);
  };
  const isValidConfirmPassword = (): boolean => {
    if (values.confirmPassword?.length && values.newPassword?.length && values.confirmPassword === values.newPassword) return true;
    return false;
  };

  const handleSubmit = async (event: any) => {
    event?.preventDefault();
    handleValidBothPassword(true);
    if (!isValidNewPassword()) {
      return;
    }
    if (!isValidConfirmPassword()) {
      return;
    }
    changePassword(values.oldPassword, values.newPassword).then((result) => {
      setOpen(true);

      if (result) {
        setOkPassword(true);
        clearValues();
      } else {
        setOkPassword(false);
      }
    });
  };
  const clearValues = () => {
    setValues({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      showOldPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      validOldPassword: false,
      validNewPassword: false,
      validConfirmPassword: false,
    });
  };
  const handleBack = async (event: any) => {
    clearValues();
    setOpen(false);
    return props.history?.push("/profile");
  };
  return (
    <Grid container sx={boxStyle}>
      <Grid item xs={0} md={1} />
      <Grid item xs={12} md={6}>
        <Typography id="modal-modal-title" variant="h2" component="h2">
          <strong>Change password</strong>
        </Typography>
        <FormControl sx={{ m: 1, width: "50ch" }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-newPassword">Old password</InputLabel>
          <OutlinedInput
            required
            id="outlined-adornment-oldPassword"
            type={values.showOldPassword ? "text" : "password"}
            value={values.oldPassword}
            onChange={handleChange("oldPassword")}
            error={values.validOldPassword && !isValidOldPassword()}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={handleClickShowOldPassword} onMouseDown={handleMouseDownPassword} edge="end">
                  {values.showOldPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="oldPassword"
          />
          {values.validOldPassword && !isValidOldPassword() ? (
            <FormHelperText id="outlined-newPassword-helper-text" sx={{ color: "red" }}>
              Not a valid password
            </FormHelperText>
          ) : null}
        </FormControl>
        <Typography id="modal-modal-title" variant="h6" component="h6" sx={{ ml: 1 }}>
          Create a new, strong password that you don't use for other websites.
        </Typography>
        <Typography id="modal-modal-title" variant="h6" component="h6" sx={{ ml: 1 }}>
          We highly recommend you to create a unique password - one that you don't use for any other websites.
        </Typography>

        <FormControl sx={{ m: 1, width: "50ch" }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-newPassword">New password</InputLabel>
          <OutlinedInput
            required
            id="outlined-adornment-newPassword"
            type={values.showNewPassword ? "text" : "password"}
            value={values.newPassword}
            onChange={handleChange("newPassword")}
            error={values.validNewPassword && !isValidNewPassword()}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={handleClickShowNewPassword} onMouseDown={handleMouseDownPassword} edge="end">
                  {values.showNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="newPassword"
          />
          {values.validNewPassword && !isValidNewPassword() ? (
            <FormHelperText id="outlined-newPassword-helper-text" sx={{ color: "red" }}>
              Not a valid password
            </FormHelperText>
          ) : null}
        </FormControl>

        <FormControl sx={{ m: 1, width: "50ch" }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-confirmPassword">Confirm new password</InputLabel>
          <OutlinedInput
            required
            id="outlined-adornment-confirmPassword"
            type={values.showConfirmPassword ? "text" : "password"}
            value={values.confirmPassword}
            onChange={handleChange("confirmPassword")}
            error={values.validConfirmPassword && !isValidConfirmPassword()}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={handleClickShowConfirmPassword} onMouseDown={handleMouseDownPassword} edge="end">
                  {values.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="confirmPassword"
          />
          {values.validNewPassword && !isValidNewPassword() ? (
            <FormHelperText id="outlined-newPassword-helper-text" sx={{ color: "red" }}>
              Passwords have to match
            </FormHelperText>
          ) : null}
        </FormControl>
        <div style={{ marginLeft: 10, fontSize: "16px" }}>{passwordPolicyContent}</div>

        <Grid xs={12}>
          <Button size="large" variant="contained" onClick={handleSubmit} style={{ marginRight: "20px" }}>
            CHANGE PASSWORD
          </Button>
          <Button size="large" variant="outlined" onClick={handleBack}>
            Back
          </Button>
          <Collapse in={open}>
            {okPassword ? (
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                Your password update successfully
              </Alert>
            ) : (
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                There is something wrong with the password please try again
              </Alert>
            )}
          </Collapse>
        </Grid>
      </Grid>
      <Grid item md={4} sx={{ display: { xs: "none", md: "block" } }}>
        <img src={process.env.PUBLIC_URL + "/mainPage/simple_types.png"} loading="lazy" />
      </Grid>
    </Grid>
  );
}
