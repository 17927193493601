import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";

type Props = { isSuccessfulRegistration: boolean; submitFunction: () => void; isOpen: boolean };

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function VerifyEmail(props: Props) {
  const actions = [
    {
      icon: <CheckIcon className="succes succes-animation iconStyle" />,
      titleText: "Verify your email",
      bodyText: "To start your journey with us, please check your email inbox & click the link to activate your account",
      buttonOk: (
        <Button variant="outlined" color="success" sx={{ fontSize: "25px", px: "50px" }} onClick={props.submitFunction}>
          OK
        </Button>
      ),
    },
    {
      icon: <CloseSharpIcon className="danger danger-animation iconStyle" />,
      titleText: "A Problem Occurred",
      bodyText: "There was a problem with the registration please try again",
      buttonOk: (
        <Button variant="outlined" color="error" sx={{ fontSize: "25px", px: "50px" }} onClick={props.submitFunction}>
          OK
        </Button>
      ),
    },
  ];

  const getReplyContext = (): number => {
    return props.isSuccessfulRegistration ? 0 : 1;
  };

  return (
    <Dialog open={props.isOpen} TransitionComponent={Transition} aria-describedby="alert-dialog-slide-description">
      <DialogTitle sx={{ m: "auto", fontSize: "40px" }}>
        {actions[getReplyContext()].icon}&nbsp;&nbsp;{actions[getReplyContext()].titleText}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description" sx={{ color: "black", fontSize: "18px" }}>
          {actions[getReplyContext()].bodyText}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ m: "auto" }}>{actions[getReplyContext()].buttonOk}</DialogActions>
    </Dialog>
  );
}

export default VerifyEmail;
