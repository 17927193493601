import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import { DocType } from "../../../db/docType";

export type TypeProps = {
  currentType?: DocType;
};
function Type(props: TypeProps) {
  return !props?.currentType ? null : (
    <>
      <Tooltip title={props.currentType.display} placement="top-start" arrow>
        <IconButton>
          <img src={process.env.PUBLIC_URL + "/typeIcons/" + props.currentType.icon + ".png"} alt="about" height="20px" />
        </IconButton>
      </Tooltip>
    </>
  );
}

export default Type;
