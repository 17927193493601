export enum ModificationType {
  CREATE,
  UPDATE,
  DELETE,
}

export class ApiModifications {
  constructor(private mods = new Map<string, ModificationType>()) {}

  create(id: string) {
    if (this.mods.has(id)) return;
    this.mods.set(id, ModificationType.CREATE);
  }

  update(id: string) {
    if (this.mods.has(id)) return;
    this.mods.set(id, ModificationType.UPDATE);
  }

  delete(id: string) {
    this.mods.set(id, ModificationType.DELETE);
  }

  removeModification(id: string) {
    if (this.mods.has(id)) {
      this.mods.delete(id);
    }
  }

  get createModifications(): string[] {
    return Array.from(this.mods.entries())
      .filter(([key, val]) => val === ModificationType.CREATE)
      .map(([key, val]) => key);
  }

  get updateModifications(): string[] {
    return Array.from(this.mods.entries())
      .filter(([key, val]) => val === ModificationType.UPDATE)
      .map(([key, val]) => key);
  }

  get deleteModifications(): string[] {
    return Array.from(this.mods.entries())
      .filter(([key, val]) => val === ModificationType.DELETE)
      .map(([key, val]) => key);
  }
}
