import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { StringAvatar } from "../user/profile/avatarGenerator";
import { useEffect, useState } from "react";
import { getAuthenticatedUser } from "../auth/authenticatedComponent";
import UserRecord from "../../db/userRecord";
import { useHistory } from "react-router-dom";
import Login from "@mui/icons-material/Login";
import Logout from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import { Button } from "@mui/material";
import Amplify, { Auth } from "aws-amplify";
import { setLastLogin } from "../../redux/actions/";
import { ConnectedProps, connect } from "react-redux";
import { RootState } from "../../redux/reducers";
type Props = PropsFromRedux & { history?: any };

const Header = (props: Props) => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [user, setUser] = useState(undefined as UserRecord | undefined);
  const history = useHistory();
  useEffect(() => {
    getAuthenticatedUser().then((newUser) => {
      setUser(newUser);
    });
  }, [props.lastLogin]);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleAboutUs = () => {
    return history?.push("/aboutus");
  };
  const handleLogo = () => {
    return history?.push("/");
  };
  const handleContact = () => {
    return history?.push("/contactus");
  };
  const handleAPI = () => {
    return history?.push("/swagger");
  };
  const handleCreate = () => {
    return history?.push("/filesexplorer");
  };
  const handleProfile = () => {
    return history?.push("/profile");
  };
  const handleLogOut = () => {
    Auth.signOut()
      .then(() => history?.push("/login"))
      .then(() => setUser(undefined));
    return;
  };
  return (
    <AppBar position="static" style={{ background: "#DFDFDF", color: "black" }}>
      <Toolbar disableGutters>
        <Button color="warning" sx={{ mr: 2, display: { xs: "none", md: "flex" } }}>
          <img onClick={handleLogo} src={process.env.PUBLIC_URL + "/logo.png"} alt="ItsJson" height="80px" width="182px" />
        </Button>
        <Box sx={{ display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>

          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: "block", md: "none" },
            }}
          >
            <MenuItem key="AboutUs" onClick={handleAboutUs}>
              <Typography textAlign="center" color="secondary" style={{ color: "black" }}>
                About Us
              </Typography>
            </MenuItem>
            <MenuItem key="Contact" onClick={handleContact}>
              <Typography textAlign="center">Contact</Typography>
            </MenuItem>
            <MenuItem key="API" onClick={handleAPI}>
              <Typography textAlign="center">API</Typography>
            </MenuItem>
            <MenuItem key="Create" onClick={handleCreate}>
              <Typography textAlign="center">Create</Typography>
            </MenuItem>
          </Menu>
        </Box>
        <Button color="warning" sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
          <img onClick={handleLogo} src={process.env.PUBLIC_URL + "/logo.png"} alt="ItsJson" height="80px" width="182px" />
        </Button>
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          <MenuItem key="AboutUs" onClick={handleAboutUs}>
            <Typography textAlign="center" sx={{ my: 2, color: "black", display: "block" }}>
              About Us
            </Typography>
          </MenuItem>
          <MenuItem key="Contact" onClick={handleContact}>
            <Typography textAlign="center" sx={{ my: 2, color: "black", display: "block" }}>
              Contact
            </Typography>
          </MenuItem>
          <MenuItem key="API" onClick={handleAPI}>
            <Typography textAlign="center" sx={{ my: 2, color: "black", display: "block" }}>
              API
            </Typography>
          </MenuItem>
          <MenuItem key="Create" onClick={handleCreate}>
            <Typography textAlign="center" sx={{ my: 2, color: "black", display: "block", paddingX: 5, p: 2, boxShadow: 6, borderRadius: "25%" }}>
              Create
            </Typography>
          </MenuItem>
        </Box>

        <Box sx={{ flexGrow: 0, marginRight: "10px" }}>
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              {user ? <Avatar {...StringAvatar(`${user?.firstName} ${user?.lastName}`)} /> : <Avatar />}
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {user && (
              <MenuItem key="Profile" onClick={handleProfile}>
                <>
                  <PersonIcon fontSize="small" />
                  <Typography textAlign="center">Profile</Typography>
                </>
              </MenuItem>
            )}
            <MenuItem key="Logout" onClick={handleLogOut}>
              {user ? (
                <>
                  <Logout fontSize="small" />
                  <Typography textAlign="center">Logout</Typography>
                </>
              ) : (
                <>
                  <Login fontSize="small" />
                  <Typography textAlign="center">Login</Typography>
                </>
              )}
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

const mapState = (state: RootState) => ({
  lastLogin: state.user.lastLogin,
});

const mapDispatch = { setLastLogin };

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedHeader = connector(Header);
export default ConnectedHeader;
