import { Types } from "../actionTypes";
import { SetHighlightAction } from "../actions/highlightActions";

export interface State {
  selectedHighlight: string;
}

const initialState: State = {
  selectedHighlight: "1.",
};

export default function reduce(state: State = initialState, action: SetHighlightAction): State {
  switch (action.type) {
    case Types.SET_HIGHLIGHT:
      return { ...state, selectedHighlight: action.selectedHighlight };
    default:
      return state || initialState;
  }
}
