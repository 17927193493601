import React from "react";
import { JsonNode } from "../../../db/jsonNode";
import DocHolder from "../../holder/docHolder";
import { calculatePresentationTypes2 } from "./typesCalculator";
import { SwipeableDrawer, Box, List, ListItem, ListItemIcon, ListItemText, Divider } from "@mui/material";

export type Props = {
  rootPath: string;
  parentNode: JsonNode;
  docHolder: DocHolder;
  show: boolean;
  onHide: any;
  handleClose: any;
  handleSubmit: (typeId: string, path: string) => Promise<void>;
};

function AddAttributeForm(props: Props) {
  const idsOrderAndIdToDisplayName = calculatePresentationTypes2(props.docHolder.getDocTypes().getAllTypeParsers());
  return (
    <React.Fragment key="bottom">
      <SwipeableDrawer anchor={"bottom"} open={props.show} onClose={props.handleClose} onOpen={() => {}}>
        <Box sx={{ width: "auto" }} role="presentation" onClick={() => {}} onKeyDown={() => {}}>
          {idsOrderAndIdToDisplayName.map((group, index) => {
            return (
              <React.Fragment key={"fragment_" + index}>
                <List key={"listKey_" + index}>
                  {group.map((typeInfo) => {
                    return (
                      <ListItem button key={typeInfo.typeId} onClick={() => props.handleSubmit(typeInfo.typeId, props.rootPath)}>
                        <ListItemIcon>
                          <img src={process.env.PUBLIC_URL + "/typeIcons/" + typeInfo.icon + ".png"} alt="about" height="30px" />
                        </ListItemIcon>
                        <ListItemText primary={typeInfo.displayName} />
                      </ListItem>
                    );
                  })}
                </List>
                <Divider key={"divider_" + index} />
              </React.Fragment>
            );
          })}
        </Box>
      </SwipeableDrawer>
    </React.Fragment>
  );
}

export default AddAttributeForm;
