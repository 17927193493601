import React from "react";
import "./privacyPolicy.css";

const PrivacyPolicy: React.FC = (props: any) => {
  return (
    <div className="privacypolicy-page">
      <div className="privacy-policy-container">
        <div style={{ textAlign: "center", paddingBottom: "2rem" }}>
          <h2>Privacy Policy</h2>
        </div>
        This privacy policy (“Privacy Policy”) governs how we, ItsJson Ltd. (together, “ItsJson” “we”, “our” or “us”) use, collect, and store Personal
        Data we collect or receive from or about you (“you”) such as in the following use cases:
        <ul>
          <li>When you browse or use our website, https://www.itsjson.com (“Website”)</li>
          <li>When you contact us (e.g. customer support, submit a request, request assistance)</li>
          <li>When we use the Personal Data of our customers (e.g. contact details)</li>
        </ul>
        Please read this Privacy Policy carefully, so you can understand our practices and your rights in relation to personal data. “Personal Data”
        or “Personal Information” means any information that can be used, alone or together with other data, to uniquely identify any living human
        being and any information deemed as Personally Identifiable Information by privacy laws. Please note that this is a master privacy policy and
        some of its provisions only apply to individuals in certain jurisdictions. For example, the legal basis in the table below is only relevant to
        GDPR-protected individuals.
        <br />
        Table of contents:
        <ol>
          <li>What information we collect, why we collect it, and how it is used.</li>
          <li>How we protect and retain your Personal Data.</li>
          <li>How we share your Personal Data.</li>
          <li>Additional information regarding transfers of Personal Data.</li>
          <li>Your privacy rights.</li>
          <li>Interaction with third party products.</li>
          <li>Log information.</li>
          <li>Use by children.</li>
          <li>Providers and Analytic tools.</li>
          <li>Contact us.</li>
        </ol>
        This Privacy Policy can be updated from time to time and, therefore, we ask you to check back periodically for the latest version of this
        Privacy Policy. If we implement significant changes to the use of your Personal Data in a manner different from that stated at the time of
        collection, we will notify you by posting a notice on our Website or by other means.
        <br />
        <ol>
          <li>
            <h3>What information we collect, why, and how we use it</h3>
            We generally collect the following Personal Data:
            <ul>
              <li>Full name</li>
              <li>Email address</li>
              <li>Company name</li>
              <li>Phone number</li>
              <li>Contact details</li>
              <li>Unique identifiers</li>
              <li>Any other Personal Data you decide to provide/supply us with</li>
            </ul>
            <br />
            We use your Personal Data for the following purposes:
            <ul>
              <li>To answer your questions</li>
              <li>To provide support and analyze your query</li>
              <li>To process your requests</li>
              <li>To customize your experience</li>
              <li>To perform services to our customers</li>
              <li>As permitted or required by applicable laws</li>
            </ul>
            <br />
            Please note that some of the abovementioned Personal Data will be used for detecting, taking steps to prevent, and prosecution of fraud or
            other illegal activity, to identify and repair errors, to conduct audits, and for security purposes. Personal Data may also be used to
            comply with applicable laws, with investigations performed by the relevant authorities, law enforcement purposes, and/or to exercise or
            defend legal claims. In certain cases, we may or will anonymize or de-identify your Personal Data and further use it for internal and
            external purposes, including, without limitation, to improve the services and for research purposes. “Anonymous Information” means
            information which does not enable identification of an individual user, such as aggregated information about the use of our services. We
            may use Anonymous Information and/or disclose it to third parties without restrictions (for example, in order to improve our services and
            enhance your experience with them).
          </li>
          <li>
            <h3>How we protect and retain your Personal Data</h3>
            <ul>
              <li>
                <strong>Security:</strong> We have implemented technical, organizational and security measures designed to protect your Personal Data.
                However, please note that we cannot guarantee that the information will not be compromised as a result of unauthorized penetration to
                our servers. As the security of information depends in part on the security of the computer, device or network you use to communicate
                with us and the security you use to protect your user IDs and passwords, please make sure to take appropriate measures to protect this
                information.
              </li>
              <li>
                <strong>Retention of Personal Data:</strong> We may retain your Personal Data where we are required to do so in accordance with legal,
                regulatory, tax or accounting requirements, or for us to have an accurate record of your dealings with us in the event of any
                complaints or challenges, or if we reasonably believe there is a prospect of litigation relating to your Personal Data or dealings.
              </li>
            </ul>
          </li>
          <li>
            <h3>How we share Personal Data</h3>
            We may share your Personal Data as follows:
            <ul>
              <li>
                With our business partners with whom we jointly offer products or services. We may also share Personal Data with our affiliated
                companies.
              </li>
              <li>
                To the extent necessary, with regulators, courts or competent authorities, to comply with applicable laws, regulations and rules
                (including, without limitation, federal, state or local laws), and requests of law enforcement, regulatory and other governmental
                agencies or if required to do so by court order;
              </li>
              <li>
                If, in the future, we sell or transfer, or we consider selling or transferring, some or all of our business, shares or assets to a
                third party, we will disclose your Personal Data to such third party (whether actual or potential) in connection with the foregoing
                events;
              </li>
            </ul>
          </li>
          <li>
            <h3>Additional information regarding transfers of Personal Data</h3>
            <ul>
              <li>
                In the event that we are acquired by, or merged with, a third party entity, or in the event of bankruptcy or a comparable event, we
                reserve the right to transfer, disclose or assign your Personal Data in connection with the foregoing events, including, in connection
                with, or during negotiations of, any merger, sale of company assets, consolidation or restructuring, financing, or acquisition of all
                or a portion of our business by or to another company; and/or
              </li>
              <li>
                Where you have provided your consent to us sharing or transferring your Personal Data (e.g., where you provide us with marketing
                consents or opt-in to optional additional services or functionality).
              </li>
            </ul>
          </li>
          <li>
            <h3>Your privacy rights</h3>
            <ul>
              You can exercise your privacy rights by contacting us at privacy@itsjson.com. You may use an authorized agent to submit a request on
              your behalf if you provide the authorized agent written permission signed by you. To protect your privacy, we may take steps to verify
              your identity before fulfilling your request. Subject to legal and other permissible considerations, we will make every reasonable
              effort to honor your request promptly in accordance with applicable law or inform you if we require further information in order to
              fulfil your request. When processing your request, we may ask you for additional information to confirm or verify your identity and for
              security purposes, before processing and/or honoring your request. We reserve the right to charge a fee where permitted by law, for
              instance if your request is manifestly unfounded or excessive. In the event that your request would adversely affect the rights and
              freedoms of others (for example, would impact the duty of confidentiality we owe to others) or if we are legally entitled to deal with
              your request in a different way than initial requested, we will address your request to the maximum extent possible, all in accordance
              with applicable law.
            </ul>
          </li>
          <li>
            <h3>Interactions with third party products</h3>
            We enable you to interact with third party websites, mobile software applications and products or services that are not owned or
            controlled by us (each a “Third Party Service”). We are not responsible for the privacy practices or the content of such Third Party
            Services. Please be aware that Third Party Services can collect Personal Data from you. Accordingly, we encourage you to read the terms
            and conditions and privacy policies of each Third Party Service.
          </li>
          <li>
            <h3>Log information</h3>
            We use log files. We use such information to analyze trends, administer the Website, track users’ movement around the Website, and gather
            demographic information.
          </li>
          <li>
            <h3>Provider and analytic tools</h3>
            <ul>
              <li>
                Amazon Web Services - we host our services on AWS, therefore any information is also subject to AWS privacy policy of the tools we
                use, for more information please refer to AWS privacy policies: https://aws.amazon.com/privacy/ .
              </li>
              <li>We reserve the right to remove or add new analytic tools and providers.</li>
            </ul>
          </li>
          <li>
            <h3>Use by children</h3>
            We do not offer our products or services for use by children and, therefore, we do not knowingly collect Personal Data from, and/or about
            children under the age of eighteen (18). If you are under the age of eighteen (18), do not provide any Personal Data to us without
            involvement of a parent or a guardian. For the purposes of the GDPR, we do not intend to offer information society services directly to
            children. In the event that we become aware that you provide Personal Data in violation of applicable privacy laws, we reserve the right
            to delete it. If you believe that we might have any such information, please contact us at privacy@itsjson.io.
          </li>
          <li>
            <h3>Contact us</h3>
            If you have any questions, concerns or complaints regarding our compliance with this notice and the data protection laws, or if you wish
            to exercise your rights, we encourage you to first contact us at privacy@itsjson.com.
          </li>
        </ol>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
