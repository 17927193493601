import { JsonNode } from "../../../db/jsonNode";
import AnyParser from "./anyParser";

class ObjectParser extends AnyParser {
  constructor(private params: any, private subTypes: Set<string>) {
    super(params, subTypes);
  }
  parse(value: any): JsonNode {
    return value;
  }
}

export default ObjectParser;
