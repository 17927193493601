import { Button, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { authenticationClient } from "../../App";
import { loadUserInfo } from "../../redux/actions";
import { RootState } from "../../redux/reducers";

export type AdminProps = { history?: any } & PropsFromRedux;

function AdminForm(props: AdminProps) {
  const [email, setEmail] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadUserInfo());
  }, []);

  if (props.isForbiddenFromAdmin) {
    return <></>;
  }

  function setEmailFromEvent(event: any) {
    const newValue = event?.target?.value || "";
    setEmail(newValue);
  }

  function handleSubmit() {
    if (!email?.length) return;
    authenticationClient.allowUserLoginByAdmin(email).then((res) => {
      alert(res);
    });
  }

  return (
    <>
      <Stack spacing={2} direction="column">
        <Typography id="modal-modal-title" variant="h6" component="h2">
          What email do you want to allow login for?
        </Typography>
        <TextField
          label="Email"
          id="outlined-size-small"
          size="small"
          placeholder="email"
          value={email}
          onChange={setEmailFromEvent}
          helperText="required field"
          required
        />
        <Button variant="contained" onClick={handleSubmit} style={{ marginTop: "15px" }}>
          Save
        </Button>
      </Stack>
    </>
  );
}

const mapState = (state: RootState) => ({
  isForbiddenFromAdmin: true,
});

const mapDispatch = {
  loadUserInfo,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

const Connected = connector(AdminForm);

export default Connected;
