import { Card, Col, Row, Button } from "react-bootstrap";
import CheckIcon from "@mui/icons-material/Check";
import EuroIcon from "@mui/icons-material/Euro";
import CloseIcon from "@mui/icons-material/Close";
import { planDescriptionInfo } from "./formDescriptionData";
import { Box, Typography } from "@mui/material";
type Props = { history?: any };
function SubscriptionForm(props: Props) {
  const boxStyle = {
    top: "5%",
    left: "10%",
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    borderRadius: "25px",
    minWidth: "80%",
    p: 10,
  };
  const buttonStyle = {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  };
  const hoverBoxStyle = {
    ":hover": {
      border: "2px #000",
      borderRadius: "25px",
      boxShadow: 2,
    },
  };
  const saleStyle = {
    textDecoration: "line-through",
    color: "#FF8E53",
    m: 1,
  };
  const handleSignup = async (event: any) => {
    console.log("Im with signUp");
    return props.history?.push("/filesexplorer");
  };
  const handlePremium = async (event: any) => {
    console.log("Im with Premium");
    return props.history?.push("/filesexplorer");
  };
  const handleBasic = async (event: any) => {
    console.log("Im with Basic");
    return props.history?.push("/filesexplorer");
  };
  return (
    <Box sx={boxStyle} component="form">
      <Row xs={1} md={3} className="g-4">
        {Array.from(planDescriptionInfo).map((currInfo) => (
          <Box sx={hoverBoxStyle}>
            <Col>
              <Card style={{ borderRadius: "25px" }} border={currInfo.border}>
                <Card.Img src="" />
                <Card.Header className="text-center">
                  <strong>{currInfo.title}</strong>
                </Card.Header>
                <Card.Body>
                  <Card.Title className="text-center">
                    {currInfo.id == 3 ? (
                      <></>
                    ) : (
                      <>
                        <EuroIcon fontSize="small" /> <strong>{currInfo.price} /month</strong>
                      </>
                    )}
                  </Card.Title>
                  <Card.Text>
                    {currInfo.descriptions.map((currDescription) => (
                      <Typography sx={{ color: currDescription.check ? "text.primary" : "text.secondary" }}>
                        {currDescription.check ? <CheckIcon color="success" fontSize="large" /> : <CloseIcon color="error" fontSize="large" />}
                        {currDescription.text}.
                      </Typography>
                    ))}
                  </Card.Text>
                </Card.Body>
                <Card.Footer className="text-center">
                  {currInfo.yearPayment == 0 ? (
                    <>
                      <Button style={buttonStyle} onClick={handleSignup}>
                        Sign up
                      </Button>
                    </>
                  ) : currInfo.id == 3 ? (
                    <>
                      <Button style={buttonStyle} onClick={handlePremium}>
                        Contact us
                      </Button>
                    </>
                  ) : (
                    <>
                      <Typography variant="h4" style={saleStyle}>
                        <EuroIcon fontSize="large" />
                        {currInfo.price} /month
                      </Typography>
                      <Button style={buttonStyle} onClick={handleBasic}>
                        Now for <EuroIcon />
                        <strong>{currInfo.yearPayment}</strong> /month
                      </Button>
                    </>
                  )}
                </Card.Footer>
              </Card>
            </Col>
          </Box>
        ))}
      </Row>
    </Box>
  );
}

export default SubscriptionForm;
