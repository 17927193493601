import DocHolder from "../../components/holder/docHolder";
import { Types } from "../actionTypes";

export type HolderActionsType = {
  type: Types.SET_JSON_HOLDER;
};

export type RefreshHolderAction = HolderActionsType & {};

export type SetHolderAction = HolderActionsType & {
  newHolder: DocHolder;
};

export function setJsonHolder(newHolder: DocHolder): SetHolderAction {
  return {
    type: Types.SET_JSON_HOLDER,
    newHolder,
  };
}
