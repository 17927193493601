import React from "react";
import "./copyright.css";

const Copyright: React.FC = (props: any) => {
  return (
    <div className="terms-page">
      <div className="terms-container">
        <div style={{ textAlign: "center", paddingBottom: "1rem" }}>
          <h2>ITSJSON</h2>
          <h2>Copyright Notice</h2>
          <h4>Last Modified: December 3rd, 2023</h4>
        </div>
        <ol>
          <li>
            The content, including text, images, graphics, and other materials on this website, unless otherwise indicated, is the intellectual property of
            itsjson.com and is protected under copyright laws.
            <br />
          </li>
          <li>
            No part of this website may be copied, reproduced, distributed, transmitted, displayed, or performed without the prior written permission of
            itsjson.com or as permitted by applicable copyright laws.
            <br />
          </li>
          <li>
            Limited exceptions to the above restrictions include the allowance to download or print a single copy of portions of the content solely for
            personal, non-commercial use, provided that all copyright and proprietary notices are retained and no modifications are made to the material.
            <br />
          </li>
          <li>
            Trademarks, service marks, and logos used and displayed on this website are registered and unregistered trademarks of itsjson.com and other
            respective owners. No license or right to use any trademark displayed herein is granted without the express written permission of itsjson.com or the
            rightful owner.
            <br />
          </li>
          <li>
            If you believe that your intellectual property rights have been infringed upon by content on this website, please contact us promptly for
            resolution.
            <br />
          </li>
          <li>
            For inquiries regarding the use of our content, permissions, or any other copyright-related matters, please direct your correspondence to
            help@itsjson.com.
            <br />
          </li>
        </ol>
      </div>
    </div>
  );
};

export default Copyright;
