import * as React from "react";
import passwordValidator from "password-validator";
import { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import * as EmailValidator from "email-validator";
import { SignUpUser, signUpUser } from "../../auth/authenticatedComponent";
import Autocomplete from "@mui/material/Autocomplete";
import { countries } from "./countries";
import VisibilityOff from "@mui/icons-material/HiveOutlined";
import Visibility from "@mui/icons-material/Hive";
import { registerClient } from "../../../App";
import VerifyEmail from "./verifyEmail";
type Props = { history?: any };

// create a password schema
const schema = new passwordValidator();

schema.is().min(12).has().uppercase().has().lowercase().has().digits().has().symbols();
interface State {
  email: string;
  name: string;
  familyName: string;
  country: string;
  newPassword: string;
  confirmPassword: string;
  showNewPassword: boolean;
  showConfirmPassword: boolean;
  validNewPassword: boolean;
  validConfirmPassword: boolean;
  validateAll: boolean;
  checkedBox: boolean;
}

function SignUp(props: Props) {
  const [isRegisterSuccessful, setIsRegisterSuccessful] = useState(false);
  const [showRegisterMessage, setShowRegisterMessage] = useState(false);
  const [values, setValues] = React.useState<State>({
    email: "",
    name: "",
    familyName: "",
    country: "",
    newPassword: "",
    confirmPassword: "",
    showNewPassword: false,
    showConfirmPassword: false,
    validNewPassword: false,
    validConfirmPassword: false,
    validateAll: false,
    checkedBox: false,
  });
  const passwordPolicyContent = (
    <React.Fragment>
      <h4>Your password should contain: </h4>
      <ul>
        <li>Minimum length of 12 characters</li>
        <li>Numerical characters (0-9)</li>
        <li>Special characters</li>
        <li>Uppercase letter</li>
        <li>Lowercase letter</li>
      </ul>
    </React.Fragment>
  );

  const boxStyle = {
    top: "5%",
    left: "10%",
    border: "2px #000",
    borderRadius: "6px",
    minWidth: "80%",
    p: 3,
  };
  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value, validNewPassword: false, validConfirmPassword: false, validateAll: false });
  };

  const isValidEmail = (): boolean => {
    return !!values.email?.length && EmailValidator.validate(values.email);
  };

  function updateCountry(event: any, value: any) {
    setValues({ ...values, country: value });
  }

  const isValidConfirmPassword = (): boolean => {
    if (values.confirmPassword?.length && values.newPassword?.length && values.confirmPassword === values.newPassword) return true;
    return false;
  };

  const isValidName = (): boolean => {
    if (!values.name?.length) return false;
    return values.name.length < 30;
  };
  const isValidCountry = (): boolean => {
    if (!values.country?.length) return false;
    return true;
  };
  const isValidFamilyName = (): boolean => {
    if (!values.familyName?.length) return false;
    return values.familyName.length < 30;
  };
  const isValidNewPassword = (): boolean => {
    if (!values.newPassword?.length) return false;
    return !!schema.validate(values.newPassword);
  };

  const handleClickShowNewPassword = () => {
    setValues({
      ...values,
      showNewPassword: !values.showNewPassword,
    });
  };

  const handleClickShowConfirmPassword = () => {
    setValues({
      ...values,
      showConfirmPassword: !values.showConfirmPassword,
    });
  };

  const handleValidBothPassword = (prop: boolean) => {
    setValues({
      ...values,
      validNewPassword: prop,
      validConfirmPassword: prop,
      validateAll: prop,
    });
  };

  const handleCheckBox = () => {
    setValues({ ...values, checkedBox: !values.checkedBox });
  };

  const isValidCheckBox = (): boolean => {
    return values.checkedBox;
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const handleSubmit = async (event: any) => {
    event?.preventDefault();
    handleValidBothPassword(true);

    if (!isValidEmail()) {
      return;
    }
    if (!isValidName()) {
      return;
    }
    if (!isValidFamilyName()) {
      return;
    }
    if (!isValidNewPassword()) {
      return;
    }
    if (!isValidConfirmPassword()) {
      return;
    }
    if (!isValidCountry()) {
      return;
    }
    if (!isValidCheckBox()) {
      return;
    }

    registerClient.register(values.email, values.newPassword, values.name, values.familyName, values.country).then((result) => {
      setIsRegisterSuccessful(!!result);
      setShowRegisterMessage(true);
    });
  };

  const submitVerifyEmail = () => {
    setShowRegisterMessage(false);
    if (isRegisterSuccessful) {
      props.history?.push("/login");
    }
  };

  return (
    <div>
      <VerifyEmail isSuccessfulRegistration={isRegisterSuccessful} submitFunction={submitVerifyEmail} isOpen={showRegisterMessage} />
      <Grid container sx={boxStyle}>
        <Grid item xs={0} md={1} />
        <Grid item xs={12} md={5}>
          <Typography id="modal-modal-title" variant="h4" component="h4" paddingBottom={1}>
            <strong>Sign up</strong>
          </Typography>
          <Typography id="modal-modal-title" variant="h5" paddingBottom={1}>
            Create your ItsJson Member profile and get first access to the best JSON modeling features
          </Typography>
          <FormControl sx={{ mt: 1, width: "50ch" }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-FName" sx={{ color: values.validateAll && !isValidName() ? "#d32f2f" : "#6F6F6F" }}>
              First name:*
            </InputLabel>
            <OutlinedInput
              required
              id="outlined-adornment-FName"
              type="text"
              value={values.name}
              onChange={handleChange("name")}
              error={values.validateAll && !isValidName()}
              label="FName"
            />
            {values.validateAll && !isValidName() ? (
              <FormHelperText id="outlined-FName-helper-text" sx={{ color: "#d32f2f" }}>
                First name is required!
              </FormHelperText>
            ) : null}
          </FormControl>
          <FormControl sx={{ mt: 1, width: "50ch" }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-LName" sx={{ color: values.validateAll && !isValidFamilyName() ? "#d32f2f" : "#6F6F6F" }}>
              Last name:*
            </InputLabel>
            <OutlinedInput
              required
              id="outlined-adornment-LName"
              type="text"
              value={values.familyName}
              onChange={handleChange("familyName")}
              error={values.validateAll && !isValidFamilyName()}
              label="LName"
            />
            {values.validateAll && !isValidFamilyName() ? (
              <FormHelperText id="outlined-surname-helper-text" sx={{ color: "#d32f2f" }}>
                Last name is required!
              </FormHelperText>
            ) : null}
          </FormControl>
          <FormControl sx={{ mt: 1, width: "50ch" }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-email" sx={{ color: values.validateAll && !isValidEmail() ? "#d32f2f" : "#6F6F6F" }}>
              Email:*
            </InputLabel>
            <OutlinedInput
              required
              id="outlined-adornment-email"
              type="text"
              value={values.email}
              onChange={handleChange("email")}
              error={values.validateAll && !isValidEmail()}
              label="email"
            />
            {values.validateAll && !isValidEmail() ? (
              <FormHelperText id="outlined-email-helper-text" sx={{ color: "#d32f2f" }}>
                Email is required!
              </FormHelperText>
            ) : null}
          </FormControl>
          <FormControl sx={{ mt: 1, width: "50ch" }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-newPassword" sx={{ color: values.validNewPassword && !isValidNewPassword() ? "#d32f2f" : "#6F6F6F" }}>
              Password:*
            </InputLabel>
            <OutlinedInput
              required
              id="outlined-adornment-newPassword"
              type={values.showNewPassword ? "text" : "password"}
              value={values.newPassword}
              onChange={handleChange("newPassword")}
              error={values.validNewPassword && !isValidNewPassword()}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" onClick={handleClickShowNewPassword} onMouseDown={handleMouseDownPassword} edge="end">
                    {values.showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="newPassword"
            />
            {values.validNewPassword && !isValidNewPassword() ? (
              <FormHelperText id="outlined-newPassword-helper-text" sx={{ color: "#d32f2f" }}>
                Not a valid password <br />
                {passwordPolicyContent}
              </FormHelperText>
            ) : null}
          </FormControl>
          <FormControl sx={{ mt: 1, width: "50ch" }} variant="outlined">
            <InputLabel
              htmlFor="outlined-adornment-confirmPassword"
              sx={{ color: values.validConfirmPassword && !isValidConfirmPassword() ? "#d32f2f" : "#6F6F6F" }}
            >
              Confirm password:*
            </InputLabel>
            <OutlinedInput
              required
              id="outlined-adornment-confirmPassword"
              type={values.showConfirmPassword ? "text" : "password"}
              value={values.confirmPassword}
              onChange={handleChange("confirmPassword")}
              error={values.validConfirmPassword && !isValidConfirmPassword()}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" onClick={handleClickShowConfirmPassword} onMouseDown={handleMouseDownPassword} edge="end">
                    {values.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="confirmPassword"
            />
            {values.validNewPassword && !isValidNewPassword() ? (
              <FormHelperText id="outlined-newPassword-helper-text" sx={{ color: "#d32f2f" }}>
                Passwords have to match
              </FormHelperText>
            ) : null}
          </FormControl>
          <Autocomplete
            sx={{ mt: 1, width: "50ch" }}
            id="country-select"
            onChange={(event, value) => updateCountry(event, value?.code)}
            options={countries}
            autoHighlight
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
              <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                <img
                  loading="lazy"
                  width="30"
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.label} ({option.code})
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                required
                {...params}
                label="Choose a country"
                error={values.validateAll && !isValidCountry()}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
                helperText={values.validateAll && !isValidCountry() ? "Country is required!" : ""}
              />
            )}
          />
          <Typography id="modal-modal-title" variant="h6" component="h4" paddingTop={1}>
            <Checkbox checked={values.checkedBox} onChange={handleCheckBox} sx={{ color: values.validateAll && !isValidCheckBox() ? "#d32f2f" : "#6F6F6F" }} />I
            agree with the{" "}
            <Link target="_blank" href="/termsOfUse">
              terms of use
            </Link>
          </Typography>

          <Grid container style={{ marginTop: "8px" }}>
            <Grid item>
              <Button size="medium" variant="contained" onClick={handleSubmit} style={{ marginRight: "20px" }}>
                Sign up
              </Button>
            </Grid>
            <Grid item>
              <Typography sx={{ lineHeight: 2 }} id="modal-modal-title" variant="h6" style={{ marginRight: "20px" }}>
                Already have an account?
              </Typography>
            </Grid>
            <Grid item>
              <Button size="medium" variant="outlined" href="/login">
                Log in
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item xs={0} md={1} />
        <Grid item xs={12} md={5} sx={{ display: { xs: "none", md: "block" } }}>
          <img src={process.env.PUBLIC_URL + "/mainPage/simple_types.png"} loading="lazy" />
        </Grid> */}
      </Grid>
    </div>
  );
}

export default SignUp;
