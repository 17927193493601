import { useState } from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";
import * as EmailValidator from "email-validator";
import EmojiNatureRoundedIcon from "@mui/icons-material/EmojiNatureRounded";
import { registerClient } from "../../../App";
type Props = { history?: any };

function ForgotPasswordForm(props: Props) {
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState("");

  const boxStyle = {
    top: "5%",
    left: "10%",
    border: "2px #000",
    borderRadius: "6px",
    minWidth: "80%",
    p: 3,
  };

  const isValidEmail = (): boolean => {
    return !!email?.length && EmailValidator.validate(email);
  };

  const handleEmailChange = (event: any) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event: any) => {
    event?.preventDefault();
    setValidated(true);
    if (!isValidEmail()) {
      return;
    }

    registerClient.resetPassword(email).then((result) => {
      console.info("ZIV please add indication the password change was successful and they should check email");
      // there is no case when they will receive a failure to prevent probing of "hackers" or "bots" for which emails we have
    });
  };

  const handleBack = async (event: any) => {
    return props.history?.push("/login");
  };
  return (
    <>
      <Grid container sx={boxStyle}>
        <Grid item xs={0} md={1} />
        <Grid item xs={12} md={6}>
          <Typography id="modal-modal-title" variant="h4" component="h4" paddingBottom={1}>
            <strong> Forgot your password? </strong>
          </Typography>
          <Typography variant="h6" gutterBottom component="div">
            Please enter your email address below, and we'll send you a link to set a new password.
          </Typography>
          <Grid container>
            <Grid item xs={10}>
              <TextField
                fullWidth
                size="medium"
                id="input-with-sx"
                label="Email address"
                variant="standard"
                onChange={handleEmailChange}
                error={validated && !isValidEmail()}
                helperText={validated && !isValidEmail() ? "Not valid Email" : ""}
              />
            </Grid>
            <Grid item xs={2}>
              <EmojiNatureRoundedIcon fontSize="large" sx={{ color: "action.active", mr: 1, my: 0.5 }} />
            </Grid>
            <Grid item xs={12} style={{ marginTop: "20px" }}>
              <Button size="large" variant="contained" onClick={handleSubmit} style={{ marginRight: "20px" }}>
                Reset
              </Button>
              <Button size="large" variant="outlined" onClick={handleBack}>
                Back
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default ForgotPasswordForm;
