import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import FolderIcon from "@mui/icons-material/Folder";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React, { useState } from "react";
import { FileDescription, FileInfo, FileState, FileTypes, GetFileAccessTokensResponse, PutFilePermissionEntry } from "common";
import DeleteForm from "./rightClickMenu/deleteForm";
import RenameForm from "./rightClickMenu/renameForm";
import { styled } from "@mui/material/styles";
import "./filesliststyle.css";
import ShareForm from "./rightClickMenu/shareFile";
import { authenticationClient } from "../../App";
import WebApiManagementForm from "./rightClickMenu/webApiManagementForm";
import { propTypes } from "react-bootstrap/esm/Image";

export type FilesListProps = {
  files: FileDescription[];
  navigateToFolder: (fileId: string) => void;
  openFileInfo: (fileId: string) => void;
  deleteFile: (fileId: string) => void;
  renameFile: (fileId: string, newFileName: string) => Promise<void>;
  isInvalid: (newfileName: string) => boolean;
  duplicateFile: (fileId: string) => void;
  getApiKeys: (fileId: string) => Promise<GetFileAccessTokensResponse | null>;
  createApiKey: (fileId: string, expiryInHours: number) => Promise<string>;
};

const openInNewTab = (url: string) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};
function FilesList(props: FilesListProps) {
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [showRenameForm, setShowRenameForm] = useState(false);
  const [showWebApiForm, setShowWebApiForm] = useState(false);
  const [showShareForm, setShowShareForm] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState("");
  const [selectedFileType, setSelectedFileType] = useState("");
  const [contextMenuFile, setRightClickMenuFile] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const [contextMenuFolder, setRightClickMenuFolder] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const closeDeleteForm = () => {
    setShowDeleteForm(false);
  };
  const closeRenameForm = () => {
    setShowRenameForm(false);
  };
  const closeWebApiForm = () => {
    setShowWebApiForm(false);
  };
  const closeShareForm = () => {
    setShowShareForm(false);
  };
  const toggleDeleteFormFile = () => {
    const newVal = !showDeleteForm;
    if (newVal) {
      handleCloseRightClickMenuFile();
    }
    setSelectedFileType("File");
    setShowDeleteForm(newVal);
  };
  const toggleDeleteFormFolder = () => {
    const newVal = !showDeleteForm;
    if (newVal) {
      handleCloseRightClickMenuFolder();
    }
    setSelectedFileType("Folder");
    setShowDeleteForm(newVal);
  };
  const toggleRenameFormFile = () => {
    const newVal = !showDeleteForm;
    if (newVal) {
      handleCloseRightClickMenuFile();
    }
    setSelectedFileType("File");
    setShowRenameForm(newVal);
  };
  const toggleWebApiFormFile = () => {
    const newVal = !showWebApiForm;
    if (newVal) {
      handleCloseRightClickMenuFile();
    }
    setSelectedFileType("File");
    setShowWebApiForm(newVal);
  };
  const toggleRenameFormFolder = () => {
    const newVal = !showDeleteForm;
    if (newVal) {
      handleCloseRightClickMenuFolder();
    }
    setSelectedFileType("Folder");
    setShowRenameForm(newVal);
  };
  const toggleShareFormFile = () => {
    const newVal = !showShareForm;
    if (newVal) {
      handleCloseRightClickMenuFile();
    }
    setSelectedFileType("File");
    setShowShareForm(newVal);
  };
  const toggleShareFormFolder = () => {
    const newVal = !showShareForm;
    if (newVal) {
      handleCloseRightClickMenuFolder();
    }
    setSelectedFileType("Folder");
    setShowShareForm(newVal);
  };

  const showRightClickMenuFile = (event: React.MouseEvent, fileId: string | undefined) => {
    event.preventDefault();
    setSelectedFileId(fileId || "");
    setRightClickMenuFile(
      contextMenuFile === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };
  const showRightClickMenuFolder = (event: React.MouseEvent, fileId: string | undefined) => {
    event.preventDefault();
    setSelectedFileId(fileId || "");
    setRightClickMenuFolder(
      contextMenuFolder === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };
  const handleCloseRightClickMenuFile = () => {
    setRightClickMenuFile(null);
  };
  const handleCloseRightClickMenuFolder = () => {
    setRightClickMenuFolder(null);
  };
  const shortFileName = (fileName: string) => {
    if (fileName.length < 15) return fileName || "";
    return fileName.substring(0, 12) + "..";
  };
  const handleDuplicateFile = () => {
    console.info("fileId is=", selectedFileId);
    // TO DO: duplication in the backend
    props.duplicateFile(selectedFileId || "");

    handleCloseRightClickMenuFile();
  };
  const handleDownloadFile = () => {
    console.info("fileId is=", selectedFileId);
    handleCloseRightClickMenuFile();
  };
  const handleDeleteFile = () => {
    console.info("fileId is=", selectedFileId);
    props.deleteFile(selectedFileId || "");
    toggleDeleteFormFile();
  };
  const handleShareFile = async (updateUsersPermission: PutFilePermissionEntry[]): Promise<void> => {
    console.info(`handleShareFile permissions=${updateUsersPermission.length}`);
    toggleShareFormFile();
    authenticationClient.changeFilePermissions(selectedFileId, updateUsersPermission);
    // TODO: add some loading indicator?
  };
  const handleInfoFile = () => {
    props.openFileInfo(selectedFileId || "");
    handleCloseRightClickMenuFile();
  };
  const handleInfoFolder = () => {
    props.openFileInfo(selectedFileId || "");
    handleCloseRightClickMenuFolder();
  };

  const Root = styled("div")(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "45%",
    },

    [theme.breakpoints.up("lg")]: {
      width: "30%",
    },
  }));

  const getFileName = () => {
    if (!selectedFileId || !props?.files?.length) return "";
    return props.files.find((file) => selectedFileId === file.id)?.name || "";
  };

  return (
    <>
      <div>
        {!!props.files?.filter((file) => file?.id?.length && file.fileType === FileTypes.FOLDER)?.length && (
          <>
            <Typography sx={{ paddingTop: "18px", paddingRight: "10px" }}>Folders</Typography>
            <List className="ListOfFileAndFolders" component={Grid} sx={{ justifyContent: "flex-start", gap: "5px" }} container>
              {props.files
                ?.filter((file) => file?.id?.length && file.fileType === FileTypes.FOLDER)
                .map((file) => {
                  return (
                    <Root key={"folderRoot" + file.id} style={{ cursor: "context-menu" }}>
                      <ListItem
                        className="ItemOfFileAndFolder"
                        component={Grid}
                        item
                        key={"Folder" + file.id}
                        button
                        onContextMenu={(curEvent: React.MouseEvent<Element, MouseEvent>) => showRightClickMenuFolder(curEvent, file.id)}
                        sx={{
                          border: "1px groove",
                          borderLeftColor: "rgba(0,0,0,.03)",
                          borderTopColor: "rgba(0,0,0,.03)",
                          borderRightColor: "rgba(0,0,0,.10)",
                          borderBottomColor: "rgba(0,0,0,.15)",
                          borderRadius: "18px",
                          color: "rgba(0,0,0,.75)",
                          fontSize: "1rem",
                          marginTop: "5px",
                          textTransform: "none",
                          paddingLeft: "10px",
                        }}
                        onClick={() => {
                          props.navigateToFolder(file.id!);
                        }}
                      >
                        <ListItemIcon>
                          <FolderIcon />
                        </ListItemIcon>
                        <ListItemText>{shortFileName(file.name || "")}</ListItemText>
                        <ListItemSecondaryAction>
                          <IconButton
                            sx={{
                              color: "rgba(0,0,0,.75)",
                              justifyContent: "flex-end",
                            }}
                            onClick={(event) => {
                              props.openFileInfo(file.id!);
                            }}
                          >
                            <InfoOutlinedIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </Root>
                  );
                })}
            </List>
          </>
        )}
        {!!contextMenuFolder && (
          <Menu
            key={"contextMenuFolder"}
            open={contextMenuFolder !== null}
            onClose={handleCloseRightClickMenuFolder}
            anchorReference="anchorPosition"
            anchorPosition={
              contextMenuFolder !== null
                ? {
                    top: contextMenuFolder.mouseY,
                    left: contextMenuFolder.mouseX,
                  }
                : undefined
            }
          >
            <MenuItem onClick={handleInfoFolder}>Show info</MenuItem>
            <MenuItem onClick={toggleShareFormFolder}>Share folder</MenuItem>
            <MenuItem onClick={toggleRenameFormFolder}>Rename folder</MenuItem>
            <MenuItem onClick={toggleDeleteFormFolder} sx={{ color: "red" }}>
              Delete
            </MenuItem>
          </Menu>
        )}
      </div>
      <div>
        {!!props.files?.filter((file) => file?.id?.length && file.fileType === FileTypes.FILE)?.length && (
          <>
            <Typography sx={{ paddingTop: "18px" }}>Files</Typography>
            <List className="ListOfFileAndFolders" component={Grid} sx={{ justifyContent: "flex-start", gap: "5px" }} container>
              {props.files
                ?.filter((file) => file.fileType === FileTypes.FILE)
                ?.map((file) => {
                  return (
                    <Root key={"fileRoot" + file.id}>
                      <ListItem
                        style={{ cursor: "context-menu" }}
                        className="ItemOfFileAndFolder"
                        component={Grid}
                        item
                        button
                        key={file.id}
                        onContextMenu={(event: React.MouseEvent<Element, MouseEvent>) => showRightClickMenuFile(event, file.id)}
                        sx={{
                          border: "1px groove",
                          borderLeftColor: "rgba(0,0,0,.03)",
                          borderTopColor: "rgba(0,0,0,.03)",
                          borderRightColor: "rgba(0,0,0,.10)",
                          borderBottomColor: "rgba(0,0,0,.15)",
                          borderRadius: "18px",
                          color: "rgba(0,0,0,.75)",
                          fontSize: "1rem",
                          marginTop: "5px",
                          textTransform: "none",
                          paddingLeft: "10px",
                        }}
                        onClick={() => {
                          openInNewTab(`/editor/${file.id}`);
                        }}
                        disabled={isFileProcessing(file)}
                      >
                        <ListItemIcon>
                          <DescriptionIcon />
                        </ListItemIcon>
                        <ListItemText>{shortFileName(file.name || "")}</ListItemText>
                        <ListItemSecondaryAction>
                          <IconButton
                            sx={{
                              color: "rgba(0,0,0,.75)",
                              justifyContent: "flex-end",
                            }}
                            onClick={(event) => {
                              props.openFileInfo(file.id!);
                            }}
                          >
                            <InfoOutlinedIcon color={isFileProcessing(file) ? "error" : "inherit"} />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </Root>
                  );
                })}
            </List>
          </>
        )}
        {contextMenuFile !== null && (
          <Menu
            key={"contextMenuFile"}
            open={contextMenuFile !== null}
            onClose={handleCloseRightClickMenuFile}
            anchorReference="anchorPosition"
            anchorPosition={contextMenuFile !== null ? { top: contextMenuFile.mouseY, left: contextMenuFile.mouseX } : undefined}
          >
            <MenuItem onClick={handleInfoFile}>Show info</MenuItem>
            <MenuItem onClick={toggleShareFormFile}>Share file</MenuItem>
            {/* <MenuItem onClick={handleDuplicateFile}>Duplicate</MenuItem> */}
            <MenuItem onClick={handleDownloadFile}>Download</MenuItem>
            <MenuItem onClick={toggleRenameFormFile}>Rename file</MenuItem>
            <MenuItem onClick={toggleWebApiFormFile}>Manage Web API</MenuItem>
            <MenuItem onClick={toggleDeleteFormFile} sx={{ color: "red" }}>
              Delete
            </MenuItem>
          </Menu>
        )}
      </div>

      {showDeleteForm && <DeleteForm show={showDeleteForm} handleClose={closeDeleteForm} handleSubmit={handleDeleteFile} type={selectedFileType} />}
      {showRenameForm && (
        <RenameForm
          show={showRenameForm}
          handleClose={closeRenameForm}
          handleSubmit={props.renameFile}
          isInvalid={props.isInvalid}
          fileId={selectedFileId}
          type={selectedFileType}
        />
      )}
      {showWebApiForm && (
        <WebApiManagementForm
          show={showWebApiForm}
          handleClose={closeWebApiForm}
          handleGetApiKeys={props.getApiKeys}
          handleCreateApiKey={props.createApiKey}
          fileId={selectedFileId}
          fileName={getFileName()}
        />
      )}
      {showShareForm && (
        <ShareForm
          show={showShareForm}
          handleClose={closeShareForm}
          handleSubmit={handleShareFile}
          isInvalid={""}
          type={selectedFileType}
          fileId={selectedFileId}
        />
      )}
    </>
  );
}

export function isFileProcessing(file: FileDescription): boolean {
  if (file.fileType !== FileTypes.FILE) return false;
  const currFile = file as FileInfo;
  return !!currFile.processingState && currFile.processingState !== FileState.PROCESSED;
}

export default FilesList;
