import DocHolder from "../../components/holder/docHolder";
import DocTypesHolder from "../../components/holder/docTypesHolder";
import { FilesApiClient, TEMPORARY_NODE_PREFIX } from "../../aws/filesApiClient";
import { JsonNode } from "../../db/jsonNode";
import NodesCache from "../../db/nodeCache";
import { HolderActionsType, SetHolderAction } from "../actions/jsonHolderAction";
import { Types } from "../actionTypes";
import { v4 as uuid } from "uuid";
import { authenticationClient } from "../../App";

export interface State {
  holder: DocHolder | null;
}

const initialState: State = {
  holder: null,
};

export default function reduce(state: State = initialState, action: HolderActionsType): State {
  switch (action.type) {
    case Types.SET_JSON_HOLDER:
      // TODO: remove all old uuids from DB
      const currSetRequest = action as SetHolderAction;
      const newHolder = currSetRequest.newHolder;
      console.info("SET_JSON_HOLDER state newHolder=", newHolder);
      return { ...state, holder: newHolder };
    default:
      return state || initialState;
  }
}

function getInitRootDoc(typesHolder: DocTypesHolder): JsonNode {
  return {
    id: "",
    type: typesHolder.objType,
    value: "",
    parent: "",
  };
}

export function createInitDocHolder(fileId: string, rootPath: string = TEMPORARY_NODE_PREFIX + uuid(), authenticated: boolean = false) {
  const typesHolder = new DocTypesHolder();
  const nodes = new Map<string, JsonNode>();
  const nodeIds = new Map<string, string>();
  const cache = new NodesCache(fileId, typesHolder, nodes, nodeIds, new FilesApiClient(authenticated, false));
  const newRootNode = getInitRootDoc(typesHolder);
  newRootNode.id = rootPath;

  const childId = TEMPORARY_NODE_PREFIX + uuid();
  const childDoc: JsonNode = {
    id: childId,
    type: typesHolder.defaultTxtType,
    nodeName: "Untitled",
    value: "",
    parent: rootPath,
  };

  nodes.set(rootPath, newRootNode);
  nodes.set(childId, childDoc);
  nodeIds.set(childId, rootPath);
  console.info("created docHolder");
  return new DocHolder(typesHolder, cache, rootPath);
}

export async function getRemoteDocHolder(fileId: string, rootPath: string = TEMPORARY_NODE_PREFIX + uuid()): Promise<DocHolder> {
  console.info("getting remote doc holder for rootPath=", rootPath);
  const typesHolder = new DocTypesHolder();
  const nodes = new Map<string, JsonNode>();
  const nodeIds = new Map<string, string>();
  const cache = new NodesCache(fileId, typesHolder, nodes, nodeIds, authenticationClient);
  const rootNode = await cache.get(rootPath);
  console.info("got from cache rootNode=", rootNode);
  if (rootNode) {
    console.info("creating DocHolder with rootPath=", rootPath);
    return new DocHolder(typesHolder, cache, rootPath);
  }
  throw new Error("Connection error, couldn't load nodes");
}
