import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./contactus.css";

const ContactUs: React.FC = (props: any) => {
  return (
    <div className="contactus-page">
      <Container className="contactus-container">
        <Row className="contactus-row">
          <Col>
            <h1 className="contactus-title">
              <strong>Contact us</strong>
            </h1>
          </Col>
        </Row>
        <Row className="contactus-row">
          <Col>
            <h4 className="contactus-description" style={{ height: "60vh" }}>
              Do you have any questions or want to contact us for a business opportunity?
              <br />
              Feel free to send us an email: info@itsjson.com
            </h4>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactUs;
