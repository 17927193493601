import crypto from "crypto";
import { publicKey } from "./publicKey";

export function encrypt(toEncrypt: string, publicKey: string) {
  const buffer = Buffer.from(toEncrypt, "utf8");
  const encrypted = crypto.publicEncrypt(
    {
      key: publicKey,
      passphrase: "itsjson",
    },
    buffer
  );
  return encrypted.toString("base64");
}

export function encryptPassword(toEncrypt: string) {
  return encrypt(toEncrypt, publicKey);
}
