import react, { useEffect, useState } from "react";

import "@aws-amplify/ui-react/styles.css";
import { Auth, Hub } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import LoginForm from "../login/login/loginForm";
import { RouteComponentProps, StaticContext } from "react-router";
import { CircularProgress, Grid } from "@mui/material";
import React from "react";
import { authenticationClient, loginJwtHolder, registerClient } from "../../App";
import UserRecord from "../../db/userRecord";

export type AuthenticatedComponentProps = {
  childComponent: any | undefined;
  extraProps?: any;
};

function AuthenticatedComponent(props: AuthenticatedComponentProps) {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(undefined as UserRecord | undefined);

  useEffect(() => {
    console.info("AuthenticatedComponent useEffect");
    getAuthenticatedUser().then((user) => {
      setUser(user);
      setLoading(false);
      if (!authenticationClient.mock)
        addAuthCallbacks(
          () => getAuthenticatedUser().then((usr) => setUser(usr)),
          () => setUser(undefined)
        );
    });
  }, []);

  return (
    <>
      {loading && (
        <Grid item xs={12} justifyContent="center" sx={{ padding: "2rem", display: "flex", alignItems: "center" }}>
          <CircularProgress />
        </Grid>
      )}
      {!loading && !user && <LoginForm history={props?.extraProps?.history}></LoginForm>}
      {!loading && user && props.childComponent && React.createElement(props.childComponent, { ...props, user })}
    </>
  );
}

export async function getAuthenticatedUser(): Promise<UserRecord | undefined> {
  console.info("getAuthenticatedUser");
  let firstName = "unknown";
  let lastName = "";
  if (!authenticationClient.mock) {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      if (!cognitoUser) return undefined;
      //getUserAttributes
      firstName = cognitoUser?.attributes?.name || "unknown";
      lastName = cognitoUser?.attributes?.family_name || "";

      const currentCredentials = await Auth.currentCredentials();
      loginJwtHolder.setKeysAndToken(currentCredentials.accessKeyId, currentCredentials.secretAccessKey, currentCredentials.sessionToken);
    } catch (err: any) {
      return undefined;
    }
  }
  try {
    const userInfo = await authenticationClient.loadUserInfo();
    console.info("getAuthenticatedUser userInfo=", userInfo);

    if (!userInfo?.user) return undefined;

    const record: UserRecord = { ...userInfo.user, firstName, lastName };
    return record;
  } catch (err: any) {
    console.error("getAuthenticatedUser didn't get current user");
    return undefined;
  }
}

export type SignUpUser = {
  email: string;
  password: string;
  name: string;
  familyName: string;
  countryCode: string;
};

export function signUpUser(newUser: SignUpUser) {
  return registerClient
    .register(newUser.email, newUser.password, newUser.name, newUser.familyName, newUser.countryCode)
    .then((data) => {
      console.log("signUpUser result=", data);
      return data;
    })
    .catch((err) => console.log("signUpUser err=", err));
}

export function signIn(username: string, password: string) {
  return Auth.signIn(username, password)
    .then((data) => {
      console.log("signIn result=", data);
      return data;
    })
    .catch((err) => console.log("signIn err=", err));
}

export function signOut() {
  return Auth.signOut()
    .then((data) => {
      console.log("signOut result=", data);
      return data;
    })
    .catch((err) => console.log("signOut err=", err));
}

export function googleSignIn() {
  const options = { provider: CognitoHostedUIIdentityProvider.Google };
  return Auth.federatedSignIn(options)
    .then((data) => {
      console.log("googleSignIn result=", data);
      return data;
    })
    .catch((err) => console.log("googleSignIn err=", err));
}

export async function changePassword(oldPassword: string, newPassword: string): Promise<boolean> {
  if (authenticationClient.mock) {
    return false;
  }
  const currUser = await Auth.currentAuthenticatedUser();
  try {
    await Auth.changePassword(currUser, oldPassword, newPassword);
    return true;
  } catch (err: any) {
    return false;
  }
}

export function addAuthCallbacks(signInCallback: () => Promise<void> | void, signOutCallback: () => Promise<void> | void) {
  Hub.listen("auth", (data) => {
    const { payload } = data;
    console.log("A new auth event has happened: ", data);
    if (payload.event === "signIn") {
      console.log("a user has signed in!");
      signInCallback();
    }
    if (payload.event === "signOut") {
      console.log("a user has signed out!");
      signOutCallback();
    }
  });
}

export function withAuthenticator(component: React.ComponentType<RouteComponentProps<any, StaticContext, unknown>> | React.ComponentType<any> | undefined) {
  return (props: any) => AuthenticatedComponent({ childComponent: component, extraProps: props });
}

export default AuthenticatedComponent;
